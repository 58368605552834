import { Directive, ElementRef, Input } from '@angular/core'

const hewHeaderHeight = 60
@Directive({
  selector: '[subMainContent]',
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class SubMainContentDirective {
  @Input() adjustHeight = false
  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.adjustPosition()
  }

  private onResize(event) {
    this.adjustPosition()
  }

  private adjustPosition() {
    let headerEl = document.querySelector('[subMainHeader]')
    let footerEl = document.querySelector('[subMainFooter]')
    let marginTop = headerEl ? headerEl.clientHeight : 0
    let marginBottom = footerEl ? footerEl.clientHeight : 0
    
    this.elRef.nativeElement.style.marginTop = `${marginTop}px`
    this.elRef.nativeElement.style.marginBottom = `${marginBottom}px`

    if (this.adjustHeight) {
      let height = window.innerHeight - marginTop - marginBottom - hewHeaderHeight
      this.elRef.nativeElement.style.height = `${height}px`
      this.elRef.nativeElement.style.overflow = 'hidden'
      this.elRef.nativeElement.style.display = "flex"
    }
  }
}