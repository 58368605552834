import { Component } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { map } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { OrganizationService } from 'models'
import { FormOption, FormOptionsBuilder } from 'core/forms'
import { AuthenticationService } from './authentication.service'
import { Router } from '@angular/router'
import { Constants } from 'config'


@Component({
  templateUrl: './select-organization-modal.component.html',
})

export class SelectOrganizationModalComponent {
  organizationOptions: FormOption[] = []
  organizationName: string
  loading: boolean = true

  constructor(
    private authentication: AuthenticationService,
    public bsModalRef: BsModalRef,
    protected translateService: TranslateService,
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.organizationService.currentShortIndex().pipe(
      map(resp => FormOptionsBuilder.organizationOptions(resp["organizations"]))
    ).subscribe(options => {
      this.organizationOptions = options
      this.loading = false
    })
  }

  onChange(e) {
    const { value } = e
    this.loading = true
    this.authentication.switchOrganization(value).subscribe(() => {
      this.bsModalRef.hide()
    })
  }

  signOut() {
    this.authentication.signOut().subscribe({
      next: () => {
        this.bsModalRef.hide()
        this.router.navigateByUrl(Constants.PAGE_PATHS.SIGN_IN)
      }
    })
  }
}
