import { environment } from "../../environments/environment"

export class Constants {
  static API = {
    PATH: 'web_api',
    HOST: environment.apiHost
  }

  static APP = {
    NAME: 'Панель управления'
  }

  static ENV = {
    env: environment.env,
    isDevelopment: environment.env == "development",
    isStaging: environment.env == "staging",
    isPreProduction: environment.env == "preprod",
    isProduction: environment.env == "production"
  }

  static PAGE_PATHS = {
    SIGN_IN: '/sign_in'
  }

  static EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i
  static PHONE_REGEXP = /^\d{7,12}$/

  static GESTURES = [
    {
      value: 'click',
      label: 'Нажатие'
    },
    {
      value: 'long_click',
      label: 'Долгое нажатие'
    },
    {
      value: 'double_click',
      label: 'Двойное нажатие'
    }
  ]

  static QUESTIONS = {
    TYPES: ['select', 'multiselect']
  }

  static SESSIONS = {
    MAX_ACCESSOR_COMMENT_LENGTH: 1000,
    STATES: ['started', 'education_completed', 'completed', 'canceled']
  }

  static USERS = {
    MIN_PASSWORD_LENGTH: 5,
    ROLES: ['admin', 'content_editor'],
  }

  static VIDEOS = {
    MAX_FILE_SIZE: 200 * 1024 * 1024 // 200mb
  }

  static SPEECHES = {
    MAX_FILE_SIZE: 50 * 1024 * 1024 // 50mb
  }

  static CKEDITOR_ASSETS_VERSION = 3

  static INSTRUCTOR = {
    HOST: environment.instructorHost
  }

  static CATALOGUES = {
    TYPES: ['city']
  }
}
