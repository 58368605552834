import * as moment from 'moment'
import { Moment } from 'moment'

export class TimeSerializer {
  static serialize(time: Moment): number {
    if(time) {
      return time.unix()
    } else {
      return null
    }
  }

  static deserialize(timestamp: number): Moment {
    if(timestamp >= 0) {
      return moment.unix(timestamp)
    } else {
      return null
    }
  }
}
