import { BaseModel } from './base.model'
import { InteractiveContentItemTransition } from './interactive-content-item-transition.model'
import { uniq } from 'lodash-es'

export class InteractiveContentItem extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number
  url: string
  thumbUrl: string
  posterUrl: string
  kind: string
  sortOrder: number
  name: string
  comment: string
  _destroy: boolean
  interactiveId: number
  width: number
  height: number
  duration: number
  endTransition: InteractiveContentItemTransition
  buttonTransitions: InteractiveContentItemTransition[]
  repeatsCount: number
  customPosterUrl: string
  hasCustomPoster: boolean
  warnings: string[]

  filename(): string {
    if (this.name) {
      return this.name
    } else if(!this.url) {
      return null
    } else {
      return this.url.split('/').pop().split('?')[0]
    }
  }

  image(): boolean {
    return this.kind == 'image'
  }

  video(): boolean {
    return this.kind == 'video'
  }

  get isLast(): boolean {
    return !this.endTransition && this.buttonTransitions.length == 0
  }

  get connectedItemIds(): number[] {
    var ids = [
      this?.endTransition?.target?.targetInteractiveContentItemId
    ]
    .concat(this.buttonTransitions.map(t => t.target.targetInteractiveContentItemId))
    .filter(id => !!id)

    return uniq(ids)
  }
}
