import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

// import { APP_INJECTOR } from 'app.module'
// import * as Rollbar from 'rollbar'
// import { RollbarService } from "./app.module"

let countersLocales = require('./locales/counters.ru.json')
let pagesLocales = require('./locales/pages.ru.json')
let sharedLocales = require('./locales/shared.ru.json')
let locales = Object.assign({}, countersLocales, pagesLocales, sharedLocales)

@Component({
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  selector: 'app'
})

export class AppComponent {
  // private rollbar: Rollbar

  constructor(
    translateService: TranslateService
  ) {
    // this.rollbar = APP_INJECTOR.get(RollbarService)
    /**
     * На некоторых девайсах в вебвью нет доступа к local/session Storage. В этом случае приложение не
     * загружается и UI падает с ошибкой "localStorage is null"
     * Эта ошибка происходит потому что Angular2TokenService пытается дастать данные аутентификации 
     * из хранилища, которого нет.
     * 
     * TODO: придумать что нибудь по-лучше
     */
    // try {
    //   tokenService.init({
    //     signInPath:        '/web_api/auth/sign_in',
    //     signOutPath:       '/web_api/auth/sign_out',
    //     validateTokenPath: '/web_api/auth/validate_token'
    //   })
    // } catch (e) {
    //   console.warn("Can not init Angular2TokenService", e)
    //   // this.rollbar.warn("Can not init Angular2TokenService", e)
    // }

    translateService.setTranslation('ru', locales)
    translateService.setDefaultLang('ru')
    translateService.use('ru')
  }
}
