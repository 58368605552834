import { UserDecorator } from './user.decorator'
import { CurrentUser } from 'models'

export class CurrentUserDecorator extends UserDecorator {

  constructor(protected object: CurrentUser) {
    super(object)
  }

  fullname(): string {
    return this.object.lastname + ' ' + this.object.firstname
  }

  role(): string {
    return this.object.currentRole ? this.translateService.instant('users.roles.' + this.object.currentRole) : null
  }

  roleDecorator(role:string): string {
    return this.translateService.instant('users.roles.' + role)
  }

  organizationName(): string {
    return this.object.organization.name 
  }
}