import { BaseSerializer } from './base.serializer'
import { Exam } from 'models'
import { ExamSectionSerializer } from './exam-section.serializer'

export class ExamSerializer extends BaseSerializer<Exam> {
  static SERIALIZABLE_ATTRIBUTES = [
    'comment', 
    'name', 
    'folderId', 
    'examSections', 
    'timeLimit', 
    'sectionsGrades', 
    'questionsGrades', 
    'onlySectionsGrade', 
    'onlyQuestionsGrade',
    'minCorrectAnswers', 
    'minCorrectSections',
    'showResults',
    'displayResults'
  ]
  static SERIALIZERS = {
    examSections: ExamSectionSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    examSections: 'examSectionsAttributes'
  }

  static DESERIALIZERS = {
    examSections: ExamSectionSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<Exam>): Exam {
    return new Exam(attributes)
  }
}
