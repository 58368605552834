import { Component } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'


@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
})

export class ConfirmComponent {
  title: string
  confirmBtnText: string
  cancelBtnText: string
  text: string
  html: string

  private resolve = new Subject<boolean>()
  readonly resolve$ = this.resolve.asObservable()
  
  constructor(
    public bsModalRef: BsModalRef
  ) {}

  ngOnDestroy() {
    this.resolve.complete()
  }

  confirm() {
    this.resolve.next(true)
    this.bsModalRef.hide()
  }

  cancel() {
    this.bsModalRef.hide()
  }
}
