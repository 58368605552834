import { Title } from '@angular/platform-browser'
import { BaseComponent } from './base.component'
import { APP_INJECTOR } from 'app.module'
import { Constants } from 'config'
import { Component } from '@angular/core'

@Component({
  template: ''
})
export abstract class BasePageComponent extends BaseComponent {
  titleService: Title
  baseTitle: string = Constants.APP.NAME

  loading: boolean
  scrollTop: number = 0

  constructor() {
    super()
    this.titleService = APP_INJECTOR.get(Title)
  }

  ngOnInit() {
    super.ngOnInit()
  }

  ngOnDestroy() {
    super.ngOnDestroy()
  }

  setTitle(title: string) {
    this.titleService.setTitle(title ? `${ title } - ${ this.baseTitle }` : this.baseTitle)
  }
}
