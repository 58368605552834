import { Component, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core'
import { DropComponent } from 'shared/components/drop.component'
import { isEqual, find, isNumber, isString } from 'lodash-es'
import { Moment } from 'moment'
import * as moment from 'moment'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

export interface Preset {
  label: string
  fromValue: Moment
  untilValue: Moment
}

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styles: [
    `
      :host ::ng-deep .drop.drop__search {
        width: 100%;
      }
      /* бля */
      :host ::ng-deep .drop.drop__search .drop__toggle {
        display: block !important;
        padding: 0 !important;
        font-size: 16px !important;
      }

      :host ::ng-deep .drop.drop__search .drop__content {
        left: -8px;
        top: 26px;
      }

      :host ::ng-deep .drop.drop__search .drop__toggle .drop__toggle-caret {
        top: -2px;
        right: -3px;
        width: 24px;
        text-align: center;
      }
    `
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    }
  ]
})
export class DateRangeComponent implements ControlValueAccessor {
  @ViewChild('drop', { static: true }) dropElRef: DropComponent
  @Input('disabled') disabled: boolean = false
  @Input('defaultCaption') private defaultCaption: string = 'Все'
  @Input('presets') public presets: Preset[]
  @Output('onChange') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()


  private selectedFromValue: Moment
  private selectedUntilValue: Moment
  private submittedFromValue: Moment
  private submittedUntilValue: Moment

  defaultPresets: Preset[] = [
    { label: this.defaultCaption, fromValue: null, untilValue: null },
    { label: 'Сегодня', fromValue: moment().startOf('day'), untilValue: moment().endOf('day') }
  ]

  public caption: string
  public isFromPickerOpen: boolean = false
  public isUntilPickerOpen: boolean = false

  // ControlValueAccessor methods

  writeValue(values: any[]) {
    let from = this.parseInputValue(values[0])
    let until = this.parseInputValue(values[1])
    if (!isEqual([from, until], [this.submittedFromValue, this.submittedUntilValue])) {
      this.submittedFromValue = this.selectedFromValue = from
      this.submittedUntilValue = this.selectedUntilValue = until
      this.updateCaption()
      this.onChangeCallback([this.selectedFromValue, this.selectedUntilValue])
      this.onChangeEmitter.emit([this.selectedFromValue, this.selectedUntilValue])
    }
  }

  registerOnChange(fn: any) { this.onChangeCallback = fn }

  registerOnTouched(fn: any) { this.onTouchedCallback = fn }

  private onChangeCallback(_) {}

  private onTouchedCallback() {}

  // END ControlValueAccessor methods

  @Input('value')

  get value(): any[] {
    return [this.submittedFromValue, this.submittedUntilValue]
  }

  set value(values) {
    let from = this.parseInputValue(values[0])
    let until = this.parseInputValue(values[1])
    if (!isEqual([from, until], [this.submittedFromValue, this.submittedUntilValue])) {
      this.submittedFromValue = this.selectedFromValue = from
      this.submittedUntilValue = this.selectedUntilValue = until
      this.updateCaption()
      this.onChangeCallback([this.selectedFromValue, this.selectedUntilValue])
      this.onChangeEmitter.emit([this.selectedFromValue, this.selectedUntilValue])
    }
  }

  selectPreset(preset: Object) {
    this.selectedFromValue = preset['fromValue']
    this.selectedUntilValue = preset['untilValue']
    this.submit()
  }

  onPickerClick(event: Event) {
    event.stopPropagation()
  }

  onFromPickerSelect(date) {
    this.selectedFromValue = date ? moment(date).startOf('day') : null
    this.closeFromPicker()
  }

  onUntilPickerSelect(date) {
    this.selectedUntilValue = date ? moment(date).endOf('day') : null
    this.closeUntilPicker()
  }

  toggleFromPicker() {
    this.isFromPickerOpen ? this.closeFromPicker() : this.openFromPicker()
    this.closeUntilPicker()
  }

  private openFromPicker() {
    this.isFromPickerOpen = true
  }

  private closeFromPicker() {
    this.isFromPickerOpen = false
  }

  toggleUntilPicker() {
    this.isUntilPickerOpen ? this.closeUntilPicker() : this.openUntilPicker()
    this.closeFromPicker()
  }

  private openUntilPicker() {
    this.isUntilPickerOpen = true
  }

  private closeUntilPicker() {
    this.isUntilPickerOpen = false
  }

  private updateCaption() {
    let fromValue  = this.submittedFromValue
    let untilValue = this.submittedUntilValue
    let preset = find(this.presets, (p) => {
      return (fromValue == p.fromValue || (fromValue && p.fromValue && fromValue.isSame(p.fromValue, 'day')))
             &&
             (untilValue == p.untilValue || (untilValue && p.untilValue && untilValue.isSame(p.untilValue, 'day')))
    })
    if(preset) {
      this.caption = preset['label']
    }
    else if(fromValue && untilValue) {
      this.caption = `${fromValue.format('DD.MM.YY')} – ${untilValue.format('DD.MM.YY')}`
    }
    else if(fromValue) {
      this.caption = `С ${fromValue.format('DD.MM.YY')}`
    }
    else if(untilValue) {
      this.caption = `До ${untilValue.format('DD.MM.YY')}`
    } else {
      this.caption = this.defaultCaption
    }
  }

  private updateSelectedValues() {
    this.selectedFromValue = this.submittedFromValue
    this.selectedUntilValue = this.submittedUntilValue
  }

  public submit(): void {
    this.submittedFromValue = this.selectedFromValue
    this.submittedUntilValue = this.selectedUntilValue
    this.onChangeEmitter.emit([this.selectedFromValue, this.selectedUntilValue])
    this.onChangeCallback([this.selectedFromValue, this.selectedUntilValue])
    this.dropElRef.closeDropdown()
  }

  public cancel(): void {
    this.dropElRef.closeDropdown()
  }

  public onCloseDropDown(e: Event): void {
    this.closeFromPicker()
    this.closeUntilPicker()
    this.updateSelectedValues()
    this.updateCaption()
  }

  private parseInputValue(value: any): Moment {
    if(!value) {
      return null
    }
    else if(value instanceof moment) {
      return value as Moment
    }
    else if(value instanceof Date) {
      return moment(value)
    }
    else if(isNumber(value)) {
      return moment.unix(value)
    }
    else if(isString(value)) {
      return moment(value, 'DD-MM-YYYY')
    } else {
      return null
    }
  }

  pickerFromValue(): Date {
    return this.selectedFromValue ? this.selectedFromValue.toDate() : null
  }

  pickerUntilValue(): Date {
    return this.selectedUntilValue ? this.selectedUntilValue.toDate() : null
  }

  textFieldFromValue(): string {
    return this.selectedFromValue ? this.selectedFromValue.format('DD.MM.YY') : null
  }

  textFieldUntilValue(): string {
    return this.selectedUntilValue ? this.selectedUntilValue.format('DD.MM.YY') : null
  }
}
