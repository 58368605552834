import { Component, Injector } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { BaseFormComponent, FormOption, FormOptionsBuilder } from "core";
import { Folder, FolderService } from "models";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StaticScreenTreeService } from "pages/admin/static-screens/static-screen-tree.service";
import { Subject } from "rxjs";
import { BaseFolderTreeService } from "../base-folder-tree.service";

export const treeServiceMap = {
  staticScreen: StaticScreenTreeService
}

@Component({
  templateUrl: "./modal-form-folder.component.html"
})
export class ModalFormFolderComponent extends BaseFormComponent {
  folder: Folder
  folderOptions: FormOption[] = []
  rootFolder: Folder
  treeService: BaseFolderTreeService
  rootType: string

  private afterCreate = new Subject<Folder>()
  private afterUpdate = new Subject<{ folder: Folder, folderWas: Folder }>()
  private afterDestroy = new Subject<Folder>()

  readonly afterCreate$ = this.afterCreate.asObservable()
  readonly afterUpdate$ = this.afterUpdate.asObservable()
  readonly afterDestroy$ = this.afterDestroy.asObservable()
  

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private folderService: FolderService,
    private injector: Injector
  ) {
    super()
  }

  ngOnInit() {
    this.treeService = this.injector.get<BaseFolderTreeService>(treeServiceMap[this.rootType])
    this.rootFolder = this.treeService.getRootFolder()
    this.folderOptions = [
      {
        value: null,
        label: "Корневая папка"
      },
      ...FormOptionsBuilder.folderAncestryOptions(this.rootFolder.subFolders)
    ]
    super.ngOnInit()
    this.buildForm()
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.afterCreate.complete()
    this.afterUpdate.complete()
    this.afterDestroy.complete()
  }

  get ancestryControl(): FormControl {
    return this.form && (this.form.get("ancestry") as FormControl)
  }

  buildForm() {
    this.form = this.fb.group({
      name: [this.folder.name, this.validators.required()]
    })

    if (!this.folder.ancestry) {
      this.form.addControl("ancestry", this.fb.control(""))
    }
  }

  submit() {
    if (!this.folder.ancestry) {
      this.folder.ancestry = this.rootFolder.childrenAncestry()
    }
    super.submit()
  }

  sendForm() {
    this.loading = true
    
    this.folderService.save(this.folder)
      .subscribe(data => {
        const folder = data["folder"]
        this.afterCreate.next(folder)
        this.alertService.success('Папка сохранена')
        this.bsModalRef.hide()
      })
  }

  close() {
    this.bsModalRef.hide()
  }

  cancel() {
    this.bsModalRef.hide()
  }
}