import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core'
import { DropComponent } from 'shared/components/drop.component'
import { find, isNumber, isString } from 'lodash-es'
import { Moment } from 'moment'
import * as moment from 'moment'

export interface Preset {
  label: string
  fromValue: Moment
  untilValue: Moment
}

@Component({
  selector: 'date-search',
  templateUrl: './date-search.component.html'
})
export class DateSearchComponent {
  @ViewChild('drop', { static: true }) dropElRef: DropComponent
  @Input('disabled') private disabled: boolean = false
  @Input('defaultCaption') private defaultCaption: string = 'Все'
  @Input('presets') public presets: Preset[]
  @Output('onChange') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()

  constructor() { }

  private selectedFromValue: Moment
  private selectedUntilValue: Moment
  private submittedFromValue: Moment
  private submittedUntilValue: Moment
  pickerFrom: Date = null
  pickerUntil: Date = null

  private defaultPresets: Preset[] = [
    { label: this.defaultCaption, fromValue: null, untilValue: null },
    { label: 'Сегодня', fromValue: moment().startOf('day'), untilValue: moment().endOf('day') }
  ]

  public caption: string
  public isFromPickerOpen: boolean = false
  public isUntilPickerOpen: boolean = false

  ngOnInit() {
    if(!this.presets) {
      this.presets = this.defaultPresets
    }
    if (this.presets[0] && !this.submittedFromValue && !this.submittedUntilValue){
      this.submittedFromValue = this.presets[0]['fromValue']
      this.submittedUntilValue = this.presets[0]['untilValue']
      this.updateSelectedValues()
     this.updateCaption()
     this.submit()
    }
    this.updateSelectedValues()
    this.updateCaption()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['fromValue'] || changes['untilValue']) {
      this.updateSelectedValues()
      this.updateCaption()
    }
  }

  @Input('fromValue')
  set fromValue(_value) {
    let value = this.parseInputValue(_value)
    this.submittedFromValue = this.selectedFromValue = value
    this.updateCaption()
  }

  @Input('untilValue')
  set untilValue(_value) {
    let value = this.parseInputValue(_value)
    this.submittedUntilValue = this.selectedUntilValue = value
    this.updateCaption()
  }

  selectPreset(preset: Object) {
    this.selectedFromValue = preset['fromValue']
    this.selectedUntilValue = preset['untilValue']
    this.pickerFrom = this.pickerFromValue()
    this.pickerUntil = this.pickerUntilValue()
    this.submit()
  }

  onPickerClick(event: Event) {
    event.stopPropagation()
  }

  onFromPickerSelect(date) {
    this.selectedFromValue = date ? moment(date).startOf('day') : null
    this.pickerFrom = this.pickerFromValue()
    this.closeFromPicker()
  }

  onUntilPickerSelect(date) {
    this.selectedUntilValue = date ? moment(date).endOf('day') : null
    this.pickerUntil = this.pickerUntilValue()
    this.closeUntilPicker()
  }

  toggleFromPicker() {
    this.isFromPickerOpen ? this.closeFromPicker() : this.openFromPicker()
    this.closeUntilPicker()
  }

  private openFromPicker() {
    this.isFromPickerOpen = true
  }

  private closeFromPicker() {
    this.isFromPickerOpen = false
  }

  toggleUntilPicker() {
    this.isUntilPickerOpen ? this.closeUntilPicker() : this.openUntilPicker()
    this.closeFromPicker()
  }

  private openUntilPicker() {
    this.isUntilPickerOpen = true
  }

  private closeUntilPicker() {
    this.isUntilPickerOpen = false
  }

  private updateCaption() {
    let fromValue  = this.submittedFromValue
    let untilValue = this.submittedUntilValue
    let preset = find(this.presets, (p) => {
      return (fromValue == p.fromValue || (fromValue && p.fromValue && fromValue.isSame(p.fromValue, 'day')))
             &&
             (untilValue == p.untilValue || (untilValue && p.untilValue && untilValue.isSame(p.untilValue, 'day')))
    })
    if(preset) {
      this.caption = preset['label']
    }
    else if(fromValue && untilValue) {
      this.caption = `${fromValue.format('DD.MM.YY')}–${untilValue.format('DD.MM.YY')}`
    }
    else if(fromValue) {
      this.caption = `С ${fromValue.format('DD.MM.YY')}`
    }
    else if(untilValue) {
      this.caption = `До ${untilValue.format('DD.MM.YY')}`
    } else {
      this.caption = this.defaultCaption
    }
  }

  private updateSelectedValues() {
    this.selectedFromValue = this.submittedFromValue
    this.selectedUntilValue = this.submittedUntilValue
    this.pickerFrom = this.pickerFromValue()
    this.pickerUntil = this.pickerUntilValue()
  }

  public submit(): void {
    this.onChangeEmitter.emit({ fromValue: this.selectedFromValue, untilValue: this.selectedUntilValue })
    this.dropElRef.closeDropdown()
  }

  public cancel(): void {
    this.dropElRef.closeDropdown()
  }

  public onCloseDropDown(e: Event): void {
    this.closeFromPicker()
    this.closeUntilPicker()
    this.updateSelectedValues()
    this.updateCaption()
  }

  private parseInputValue(value: any): Moment {
    if(!value) {
      return null
    }
    else if(value instanceof moment) {
      return value as Moment
    }
    else if(value instanceof Date) {
      return moment(value)
    }
    else if(isNumber(value)) {
      return moment.unix(value)
    }
    else if(isString(value)) {
      return moment(value, 'DD-MM-YYYY')
    } else {
      return null
    }
  }

  private pickerFromValue(): Date {
    return this.selectedFromValue ? this.selectedFromValue.toDate() : null
  }

  private pickerUntilValue(): Date {
    return this.selectedUntilValue ? this.selectedUntilValue.toDate() : null
  }

  textFieldFromValue(): string {
    return this.selectedFromValue ? this.selectedFromValue.format('DD.MM.YY') : null
  }

  textFieldUntilValue(): string {
    return this.selectedUntilValue ? this.selectedUntilValue.format('DD.MM.YY') : null
  }
}
