import { BaseSerializer } from './base.serializer'
import { InteractiveSkillGradeSerializer } from './interactive-skill-grade.serializer'
import { InteractiveSkill } from 'models/interactive-skill.model'

export class InteractiveSkillSerializer extends BaseSerializer<InteractiveSkill> {
  static SERIALIZABLE_ATTRIBUTES = ['id', 'grades', 'weight']

  static SERIALIZERS = {
    grades: InteractiveSkillGradeSerializer
  }

  static SERIALIZE_RENAME_KEYS = {
    grades: 'gradesAttributes'
  }

  static DESERIALIZERS = {
    grades: InteractiveSkillGradeSerializer
  }

  static DESERIALIZE_RENAME_KEYS = {
  }

  static initializeObject(attributes: Object): InteractiveSkill {
    return new InteractiveSkill(attributes)
  }
}
