import { FormArray, ValidationErrors } from '@angular/forms'

export class ProgramLastSectionValidator {

  constructor() {
    this.validate = this.validate.bind(this)
  }

  validate(control: FormArray): ValidationErrors {
    const controls = control.controls
    const lastSection = controls[controls.length - 1]

    const isValid = lastSection && (
      lastSection.value['type'] === 'static_screen' ||
      lastSection.value['type'] === 'program_result_screen'
    )

    return isValid ? null : { 'lastSection': true }
  }
}
