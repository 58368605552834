import { Moment } from 'moment'
import { BaseModel } from './base.model'
import { SessionExam } from './session-exam.model'
import { Program } from './program.model'
import { SessionKioskForm } from './session-kiosk-form.component'
import { WebHookResult } from './web-hook-result.model'
import { VariableType } from './dictionaries'

export interface RegInfo {
  name: string
  type: VariableType 
  label: string
  variable: number|string|boolean
  stringValue: string
}

export class Session extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    regInfo: {}
  }

  id: number
  uuid: string
  state: string
  email: string
  phone: string
  telegram: string
  externalId: string
  passed: boolean
  grade: number
  program: Program
  regInfo: { [key: string]: RegInfo }
  comments: Comment[]
  sessionExams: SessionExam[]
  submittedForms: SessionKioskForm[]
  webHookResults: WebHookResult[]
  organizationId: number
  createdAt: Moment
  updatedAt: Moment
  completedAt: Moment
  canceledAt: Moment
  educationCompletedAt: Moment

  get isStarted(): boolean {
    return this.state == 'started'
  }

  get isCompleted(): boolean {
    return this.state == 'completed'
  }

  get isCanceled() {
    return this.state == "canceled"
  }

  get isEducationCompleted() {
    return this.state == "education_completed"
  }

  getInfo(fieldName: string): string {
    return this.regInfo && this.regInfo[fieldName] && this.regInfo[fieldName].stringValue
  }
}
