<drop #drop [caption]="caption" (close)="onCloseDropDown($event)" modificator="drop__search">
  <div class="drop__section drop-section-links">
    <div
      class="drop-section-links__item selectable"
      *ngFor="let preset of presets"
      (click)="selectPreset(preset)"
    >
      <div class="drop-section-links__label">
        {{ preset.label }}
      </div>
    </div>
  </div>

  <div class="drop__section drop-section-date">
    <div
      class="drop-section-date"
      #inputWrapper
    >
      <input
        type="text"
        class="form-control drop-section-date__input"
        readonly
        [value]="textFieldValue()"
        (click)="togglePicker()"
      >
      <div
        class="drop-section-date__picker-wrapper"
        (click)="onPickerClick($event)"
      >
        <datepicker
          *ngIf="isPickerOpen"
          [startingDay] = "1"
          [initDate]="pickerValue()"
          [showWeeks]="false"
          (selectionDone)="onPickerSelect($event)"
        ></datepicker>
      </div>
    </div>
  </div>

  <div class="drop__section drop-section-buttons">
    <div class="drop-section-buttons__row">
      <div class="drop-section-buttons__col">
        <button
          type="button"
          class="btn btn-primary btn_width_108"
          (click)="submit()"
        >Применить</button>
      </div><!--
    --><div class="drop-section-buttons__col">
        <button
          type="button"
          class="btn btn-default-fr btn_width_108"
          (click)="cancel()"
        >Отменить</button>
      </div>
    </div>
  </div>
</drop>
