import { BaseSerializer } from './base.serializer'
import { Question } from 'models/question.model'
import { AnswerOptionSerializer } from './answer-option.serializer'

export class QuestionSerializer extends BaseSerializer<Question> {
  static SERIALIZABLE_ATTRIBUTES = [
    'answerOptions', 'comment', 'correctAnswerValue', 'folderId',
    'hint', 'name', 'shuffleAnswerOptions', 'type',
    'control', 'sectionId', 'examAudioId', 'videoId'
  ]
  static SERIALIZERS = {
    answerOptions: AnswerOptionSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    answerOptions: 'answerOptionsAttributes',
    sectionId: 'exams_section_id'
  }

  static DESERIALIZERS = {
    answerOptions: AnswerOptionSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Question {
    return new Question(attributes)
  }
}
