import { Component, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms"

@Component({
  selector: "color-picker",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    }
  ],
  styles: [`
    input {
      height: 32px;
      padding: 5px 7px 5px 7px;
      border-color: #d9d9d9;
      color: #000;
      box-shadow: none;
      display: block;
      width: 100%;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    input:active, input:focus-visible {
      outline: none;
    }
  `],
  template: `
    <input 
    [value]="color"
    [cpOutputFormat]="'hex'"
    [cpAlphaChannel]="'disabled'"
    [style.background]="color" 
    [(colorPicker)]="color" 
    (colorPickerChange)="onColorPickerChange($event)"
    />
  `
})
export class ColorPickerComponent implements ControlValueAccessor {
  color: string
  writeValue(color: string) {
    this.color = color
    this.onChanged(color)
  }

  private onChanged: any = () => {}
  registerOnChange(fn: any) {
    this.onChanged = fn
  }
  
  private onTouched: any = () => {}
  registerOnTouched(fn: any) {
    this.onTouched = fn
  }

  onColorPickerChange(color: string) {
    this.color = color
    this.onChanged(color)
    this.onTouched()
  }
}