import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Quiz } from './quiz.model'
import { QuizSerializer } from 'serializers'

@Injectable()
export class QuizService extends BaseModelService<Quiz> {
  constructor() {
    super()

    this.configure({
      basePath:  'lesson_quizzes',
      key:       'lessonQuiz',
      pluralKey: 'lessonQuizzes',
      serializer: QuizSerializer
    })
  }
}
