<div class="select__opt"
  [style.padding-left.px]="level * 20 + 7"
  [ngClass]="{ selectable: selectable(), disabled: option.disabled, active: selected() }"
  (click)="select()"
>
  <div class="select__opt-label">{{ option.label }}</div>
  <div class="select__opt-sub-label" *ngIf="option.subLabel">{{ option.subLabel }}</div>
</div>

<div class="select__sub-opts" *ngIf="option.subOptions && option.subOptions.length">
  <single-select-option
    *ngFor="let subOption of option.subOptions; trackBy: trackByFn"
    [option]="subOption"
    [level]="level + 1"
    [selectedValue]="selectedValue"
    [selectOptionFn]="selectOptionFn"
  ></single-select-option>
</div>
