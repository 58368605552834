import { Component, Input } from '@angular/core'

@Component({
  selector: 'loader',
  template: `
    <div [ngClass]="['loader', wrapperClass]" [hidden]="!visible" [ngStyle]="{'z-index':zIndex}">
      <div class="loader__overlay"></div>
      <div class="loader__spinner">
        <div class="loader__spinner-i"></div>
      </div>

      <div class="loader__text">
        <ng-content></ng-content>
      </div>
    </div>
  `
})

export class LoaderComponent {
  @Input() visible: boolean = false
  @Input() wrapperClass: string = '' 
  @Input() text: boolean|string = false
  @Input() zIndex: number
}
