import { BaseModel } from './base.model'
import { Moment } from "moment"
import { User } from './user.model'

export class Comment extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number
  text: string
  createdAt: Moment
  commenter: User
}
