
import { BaseSerializer } from './base.serializer'
import { ExamSectionResult } from 'models'
import { ExamSectionSerializer } from './exam-section.serializer'


export class ExamSectionResultSerializer extends BaseSerializer<ExamSectionResult> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}

  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    examSection: ExamSectionSerializer
  }
  
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<ExamSectionResult>): ExamSectionResult {
    return new ExamSectionResult(attributes)
  }
}
