import { BaseSerializer } from './base.serializer'
import { ExamSection } from 'models'


export class ExamSectionSerializer extends BaseSerializer<ExamSection> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'name',
    'amountForShow',
    'position',
    'shuffleQuestions',
    'blocking',
    'questionIds',
    'minCorrectAnswers',
    'state'
  ]
  static SERIALIZERS = {}

  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): ExamSection {
    return new ExamSection(attributes)
  }
}
