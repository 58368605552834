import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Skill } from './skill.model'
import { SkillSerializer } from 'serializers'

@Injectable()
export class SkillService extends BaseModelService<Skill> {
  constructor() {
    super()

    this.configure({
      basePath:  'skills',
      key:       'skill',
      pluralKey: 'skills',
      serializer: SkillSerializer
    })
  }
}
