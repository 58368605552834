import { BaseSerializer } from './base.serializer'
import { Folder } from 'models/folder.model'

export class FolderSerializer extends BaseSerializer<Folder> {
  static SERIALIZABLE_ATTRIBUTES = ['name', 'parentId']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    subFolders: FolderSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Folder {
    return new Folder(attributes)
  }
}
