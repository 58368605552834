<div class="modal-header">
  <h4 class="modal-title pull-left">Новая папка</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <loader [visible]="loading"></loader>

  <ng-container *ngIf="!!form">
    <form [formGroup]="form" (submit)="submit($event)">
      <div class="form-group" 
      *ngIf="ancestryControl"
      [ngClass]="{
        'has-error': invalidControl(ancestryControl) 
      }">
        <label class="control-label">Родительская папка</label>
        <single-select
        formControlName="ancestry"
        [(ngModel)]="folder.ancestry"
        [options]="folderOptions"
        ></single-select>
        <show-errors [control]="ancestryControl"></show-errors>
      </div>

      <div class="form-group" [ngClass]="{
        'has-error': invalidControl(form.controls.name) 
      }">
        <label class="control-label">Название</label>
        <input type="text" class="form-control"
        formControlName="name" 
        [(ngModel)]="folder.name"
        >
        <show-errors [control]="form.controls.name"></show-errors>
      </div>
    </form>
  </ng-container>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary btn_width_108" (click)="submit()">Сохранить</button>
  <button type="button" class="btn btn-default btn_width_108" (click)="cancel()">Отмена</button>
</div>