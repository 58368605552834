import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core'
import { cloneDeep, isEqual } from 'lodash-es'
import { DropComponent } from 'shared/components/drop.component'
import { PluralizeService } from 'core/i18n'
import { FormOption } from 'core'

@Component({
  selector: 'multi-select-search',
  templateUrl: './multi-select-search.component.html',
  styles: [`
    .drop-section-options-search {
      padding: 0 4px;
    }
  `]
})

export class MultiSelectSearchComponent {
  @ViewChild('drop', { static: true }) private dropElRef: DropComponent
  @Input('options') options: FormOption[] = []
  @Input('disabled') private disabled: boolean = false
  @Input('defaultCaption') private defaultCaption: string = 'Все'
  /**
   * Обычно "все" выбранные опции означают, что по ним фильтровать не надо и в запрос этот параметр не
   * уходит. reverseSelect обозначает обратное поведение. Если ни одна опция не выбрана - значит фильтровать
   * не надо и если отмечены все опции в запрос они и уходят
   */
  @Input('reverseDefaultSelect') private _reverseDefaultSelect: boolean = false

  @Output('onChange') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()

  selectedValues: any[] = []
  private submittedValues: any[] = null
  caption: string
  optionsSearchTerm: string = ""
  filteredOptions: Object[]
  showSearch: boolean = false

  constructor(
    private pluralizeService: PluralizeService
  ) {
    this.selectOption = this.selectOption.bind(this)
    this.deselectOption = this.deselectOption.bind(this)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.filteredOptions =  this.filterOptions(this.options, "")
      this.updateSelectedValues()
      this.updateCaption()
      this.showSearch = this.getFlatOptions().length >= 10
    }
  }

  onKeyup(term: string): void {
    this.filteredOptions = this.filterOptions(this.options, term)
  }
  
  filterOptions(options: Object[], term: string): Object[] {
    let _options = cloneDeep(options)
    let _term = term.toLowerCase()

    return _options.filter(o => {
      if (o["group"]) {
        o["subOptions"] = this.filterOptions(o["subOptions"], _term)
        return o["subOptions"].length > 0
      } else {
        return o["label"].toLowerCase().indexOf(_term) !== -1
      }
    })
  }

  resetSearch(): void {
    this.filteredOptions = this.filterOptions(this.options, "")
    this.optionsSearchTerm = ""
  }

  @Input('value')
  set value(_value) {
    this.submittedValues = _value
    if(!isEqual(_value, this.selectedValues)) {
      this.updateSelectedValues()
      this.updateCaption()
    }
  }

  selectOption(option: FormOption) {
    let flatOptions = this.getSubtreeOptions(option)
    let changed = false
    for(let opt of flatOptions) {
      if(this.selectedValues.indexOf(opt['value']) == -1) {
        this.selectedValues.push(opt['value'])
        changed = true
      }
    }
    if(changed) {
      // Trigger change detection
      this.selectedValues = this.selectedValues.slice(0, this.selectedValues.length)
    }
    // this.resetSearch()
  }

  deselectOption(option: FormOption) {
    let flatOptions = this.getSubtreeOptions(option)
    let changed = false
    for(let opt of flatOptions) {
      let index = this.selectedValues.indexOf(opt['value'])
      if(index > -1) {
        this.selectedValues.splice(index, 1)
        changed = true
      }
    }
    if (changed) {
      // Trigger change detection
      this.selectedValues = this.selectedValues.slice(0, this.selectedValues.length)
    }
  }

  selectAll() {
    this.selectedValues = this.getFlatOptions().map(option => { return option['value'] })
  }

  deselectAll() {
    this.selectedValues = []
  }

  private updateCaption() {
    let flatOptions = this.getFlatOptions()
    let selectedOptions = flatOptions.filter(option => {
      return this.selectedValues.indexOf(option['value']) > -1
    })

    if (!this._reverseDefaultSelect && selectedOptions.length == flatOptions.length) {
      /**
       * defaultCaption применяется, когда выбраны все опции
       */
      this.caption = this.defaultCaption
    } else if (this._reverseDefaultSelect && selectedOptions.length == 0) {
      /**
       * defaultCaption применяется, не выбрана ни одна опция
       */
      this.caption = this.defaultCaption
    } else {
      this.caption = `${selectedOptions.length} из ${flatOptions.length}`
    } 
  }

  private updateSelectedValues() {
    if (!this._reverseDefaultSelect && !this.submittedValues) {
      /**
       * по умолчанию выбраны все опции
       */
      this.selectedValues = this.getFlatOptions().map(option => { return option['value'] })
    } 
    else if (this._reverseDefaultSelect && !this.submittedValues) {
      /**
       * нет выбранных опций по умолчанию
       */
      this.selectedValues = []
    } 
    else {
      this.selectedValues = this.submittedValues.slice(0, this.submittedValues.length)
    }
  }

  submit() {
    let flatOptions = this.getFlatOptions()
    let selectedOptions = flatOptions.filter(option => {
      return this.selectedValues.indexOf(option['value']) > -1
    })
    let value = null
    if (this._reverseDefaultSelect) {
      /**
       * Не отправлять в параметры запроса, если опции не выбраны
       */
      value = selectedOptions.length == 0 ? null : this.selectedValues
    } 
    else {
      /**
       * Не отправлять в параметры запроса, если все опции выбраны
       */
      value = flatOptions.length == selectedOptions.length ? null : this.selectedValues
    }

    this.onChangeEmitter.emit({ value: value })
    this.dropElRef.closeDropdown()
  }

  public cancel(): void {
    this.dropElRef.closeDropdown()
  }

  private getFlatOptions(): Object[] {
    return this.getSubtreesOptions(this.options)
  }

  private getSubtreesOptions(options: Object[]): Object[] {
    let result = []
    for(let option of options) {
      result = result.concat(this.getSubtreeOptions(option))
    }
    return result
  }

  private getSubtreeOptions(option: Object, result: Object[] = []): Object[] {
    if(option['value'] !== null && option['value'] !== undefined) {
      result.push(option)
    }
    if(option['subOptions']) {
      for(let subOption of option['subOptions']) {
        this.getSubtreeOptions(subOption, result)
      }
    }
    return result
  }

  public onCloseDropDown(e: Event): void {
    this.updateSelectedValues()
    this.updateCaption()
  }

  public isOpen(): boolean {
    return this.dropElRef.open
  }

  trackByFn(index, item) {
    return item["group"] ? 
      item["label"] : item["value"]
  }
}
