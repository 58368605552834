import { BaseModel } from './base.model'

export class AppTranslation extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number
  localeId: number
  key: string
  value: string
  pularValue: Object
  forWeb: boolean
  forTablets: boolean

  get pularKeys(): string[] {
    return this.pularValue ? Object.keys(this.pularValue) : []
  }

  get hasPularValue(): boolean {
    return this.pularKeys.length > 0
  }

  getPularValue(key: string): string {
    return this.pularValue ? this.pularValue[key] : null
  }

}
