import { Component } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { FormOption } from 'core'
import { AuthenticationService } from './authentication.service'
import { TranslateService } from '@ngx-translate/core'


@Component({
  templateUrl: './select-role-modal.component.html',
})

export class SelectRoleModalComponent {
  role: string
  roles: string[] = []
  roleOptions: FormOption[] = []

  get roleName(): string {
    return this.role && this.translateService.instant('users.roles.' + this.role)
  }

  constructor(
    private authenticationService: AuthenticationService,
    public bsModalRef: BsModalRef,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.roleOptions = this.roles.map(role => ({
      label: this.translateService.instant('users.roles.' + role),
      value: role
    }))
  }

  onChange(e) {
    const { value } = e
    this.authenticationService.switchRole(value).subscribe(() => {
      this.bsModalRef.hide()
    })
  }
}
