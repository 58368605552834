<div
  class="toggle-switch"
  [ngClass]="cssClass()"
>
  <div class="toggle-switch__positive" (click)="selectPositive()">
    {{ positiveLabel }}
  </div>

  <div class="toggle-switch__negative" (click)="selectNegative()">
    {{ negativeLabel }}
  </div>
</div>