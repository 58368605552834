import { BaseModel } from './base.model'
import { StaticScreen } from './static-screen.model'
import { SystemScreen } from './system-screen.model'
import { Video } from './video.model'
import { ProgramSectionTransition } from './program-section-transition.model'
import { Quiz } from './quiz.model'
import { Interactive } from './interactive.model'

export type SectionType = "static_screen"|"lesson_quiz"|"interactive"|"exam"|"video"|"system_screen"

export class ProgramSection extends BaseModel {
  typeNameMap = {
    "system_screen": "Системный экран",
    "static_screen": "Статичный экран",
    "video": "Видео",
    "exam": "Экзамен",
    "lesson_quiz": "Тест",
    "interactive": "Интерактив"
  }

  static DEFAULT_ATTRIBUTES = {
    transitions: []
  }

  id: number
  uuid: string
  contentId: number
  content: StaticScreen|SystemScreen|Video|Quiz|Interactive
  position: {
    top: number,
    left: number
  }
  type: SectionType
  transitions: ProgramSectionTransition[]
  completeEducation: boolean
  _destroy: boolean

  get typeLabel(): string {
    return this.typeNameMap[this.type]
  }

  get name(): string {
    return this.content ? this.content.name : ""
  }
}
