import { BaseModel } from './base.model'
import { Question } from 'models/question.model'

export class ExamSection extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    questionIds: [],
    questions: [],
    state: "active",
    blocking: false,
    shuffleQuestions: false
  }
  examId: number
  name: string
  amountForShow: number
  position: number
  shuffleQuestions: boolean
  blocking: boolean
  state: string
  questionIds: number[]
  questions: Question[]
  minCorrectAnswers: number

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  active(): boolean {
    return this.state == "active"
  }
}
