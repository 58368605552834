import { BaseSerializer } from './base.serializer'
import { Session } from 'models/session.model'
import { TimeSerializer } from './attributes'
import { ExamSerializer } from './exam.serializer'
import { ProgramSerializer } from './program.serializer'
import { SessionExamSerializer } from './session-exam.serializer'
import { SessionKioskFormSerializer } from './session-kiosk-form.serilizer'
import { WebHookResultSerializer } from './web-hook-result.serializer'


export class SessionSerializer extends BaseSerializer<Session> {
  static SERIALIZABLE_ATTRIBUTES = []

  static SERIALIZERS = {}

  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    educationCompletedAt: TimeSerializer,
    completedAt: TimeSerializer,
    canceledAt: TimeSerializer,
    createdAt: TimeSerializer,
    updatedAt: TimeSerializer,
    exam: ExamSerializer,
    program: ProgramSerializer,
    sessionExams: SessionExamSerializer,
    submittedForms: SessionKioskFormSerializer,
    webHookResults: WebHookResultSerializer
  }

  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<Session>): Session {
    return new Session(attributes)
  }
}
