import { BaseModel } from './base.model'
import { VariableType } from './dictionaries'

export type ConditionType = "equal"|"notEqual"|"more"|"less"|"moreOrEqual"|"lessOrEqual"|"between"|"notBetween"|"includedIn"|"notIncludedIn"

export type ConditionValue = number|[number, number]|number[]

export class ProgramSectionTransitionCondition extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number
  variableName: string
  variableType: VariableType
  conditionType: ConditionType
  conditionValue: ConditionValue

  _destroy?: boolean
}
