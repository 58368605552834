import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
const FontFaceObserver = require('fontfaceobserver')

@Injectable()
export class FontFaceObserverService {

  load(fontFamilies: string[]): Observable<boolean> {
    return new Observable(observer => {
      Promise.all(fontFamilies.map(font => {
        return new FontFaceObserver(font).load()
      }))
      .then(data => {
        console.log('fonts', fontFamilies, 'loaded', data)
        observer.next(true)
        observer.complete()
      })
      .catch(err => {
        console.debug(err)
        observer.next(false)
        observer.complete()
      })
    })
  }

}
