import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { cloneDeep } from 'lodash-es'
import { BaseModalFormComponent } from 'core/forms'
import { Folder, FolderService } from 'models'
import { tap, filter, switchMap } from "rxjs/operators"

@Component({
  templateUrl: './folder-form.component.html',
  selector: 'folder-form',
  styleUrls: ['./folder-form.component.sass']
})

export class FolderFormComponent extends BaseModalFormComponent {
  folder: Folder
  private folderWas: Folder

  @ViewChild('nameInput') private nameInputElRef: ElementRef

  @Output('afterCreate') afterCreateEmitter = new EventEmitter<Folder>()
  @Output('afterUpdate') afterUpdateEmitter = new EventEmitter<{ folder: Folder, folderWas: Folder }>()
  @Output('afterDestroy') afterDestroyEmitter = new EventEmitter<Folder>()

  constructor(protected folderService: FolderService) {
    super()
  }

  showNew(attributes: Object = {}) {
    if(!attributes['ancestry']) {
      throw '`ancestry` must be passed'
    }
    this.modal.show()
    this.folder = new Folder(attributes)
    this.buildForm()
  }

  showEdit(id: number) {
    this.modal.show()
    this.loading = true
    this.folderService.find(id).subscribe(
      data => {
        this.folder = data['folder']
        this.folderWas = cloneDeep(this.folder)
        this.buildForm()
        this.loading = false
      },
      error => {
        this.alertService.error(error)
      }
    )
  }

  onModalShown() {
    super.onModalShown()

    if(this.nameInputElRef && !this.folder.persisted()) {
      this.nameInputElRef.nativeElement.focus()
    }
  }

  onModalHidden() {
    super.onModalHidden()
    this.folder = null
    this.folderWas = null
  }

  sendForm() {
    this.loading = true
    this.folderService.save(this.folder).subscribe(
      data => {
        const folder = data["folder"] as Folder
        const event = { folder, folderWas: this.folderWas }
        this.folder.persisted() ? this.afterUpdateEmitter.emit(event) : this.afterCreateEmitter.emit(folder)
        this.alertService.success('Папка сохранена')
        this.hide()
      },
      error => {
        this.loading = false
        this.alertService.error(error)
      }
    )
  }

  buildForm() {
    this.form = new FormGroup({
      name: new FormControl(this.folder.name, this.validators.required())
    })
  }

  canDestroyWithoutContents(): boolean {
    return this.folder.depth() > 1
  }

  destroy() {
    this.confirmService.show({
      title: 'Удалить папку?',
      confirmBtnText: 'Удалить',
      cancelBtnText: 'Отмена'
    }).pipe(
      filter(confirm => confirm),
      tap(() => setTimeout(() => this.loading = true, 0)),
      switchMap(_ => this.folderService.destroy(this.folder))
    )
    .subscribe(data => {
      const folder = data['folder'] as Folder
      this.afterDestroyEmitter.emit(folder)
      this.alertService.success('Папка удалена')
      this.hide()
    },
    error => {
      this.loading = false
      this.alertService.error(error)
    })
  }

  destroyWithContents() {
    this.confirmService.show({
      title: 'Удалить папку вместе с содержимым?',
      confirmBtnText: 'Удалить',
      cancelBtnText: 'Отмена'
    }).pipe(
      filter(confirm => confirm),
      tap(() => setTimeout(() => this.loading = true, 0)),
      switchMap(_ => this.folderService.destroy(this.folder))
    ).subscribe(data => {
      const folder = data['folder'] as Folder
      this.afterDestroyEmitter.emit(folder)
      this.alertService.success('Папка удалена')
      this.hide()
    },
    error => {
      this.loading = false
      this.alertService.error(error)
    })
  }
}
