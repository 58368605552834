import { FormControl } from '@angular/forms'

export class MinLengthValidator {
  constructor(private minLength: number) {
    this.validate = this.validate.bind(this)
  }

  validate(control: FormControl): Object {
    if(!control.value) { return null }
    let length = control.value.trim().length
    if(length == 0 || length >= this.minLength) {
      return null
    } else {
      return { minlength: { requiredLength: this.minLength, actualLength: length } }
    }
  }
}
