import { Directive, ElementRef, Input, DoCheck, OnDestroy, AfterViewInit } from '@angular/core'
import PerfectScrollbar from 'perfect-scrollbar'

@Directive({
  selector: '[perfect-scrollbar]'
})

export class PerfectScrollbarDirective implements DoCheck, OnDestroy, AfterViewInit {
  @Input() private perfectScrollbarOptions: Object = {}
  private ps: PerfectScrollbar | null = null;

  private width: number
  private height: number
  private contentWidth: number
  private contentHeight: number

  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
    this.ps = new PerfectScrollbar(this.element.nativeElement, this.perfectScrollbarOptions)
  }

  ngDoCheck() {
    if (this.element.nativeElement.children && this.element.nativeElement.children.length) {
      let width = this.element.nativeElement.offsetWidth
      let height = this.element.nativeElement.offsetHeight
      let contentWidth = this.element.nativeElement.children[0].offsetWidth
      let contentHeight = this.element.nativeElement.children[0].offsetHeight

      if(width !== this.width || height !== this.height || contentWidth !== this.contentWidth || contentHeight !== this.contentHeight) {
        this.width = width
        this.height = height
        this.contentWidth = contentWidth
        this.contentHeight = contentHeight

        this.ps.update()
      }
    }
  }

  ngOnDestroy() {
    this.ps.destroy()
  }
}
