import { BaseSerializer } from 'serializers'
import { KioskFormControlOption } from './kiosk-form-control-option.model'

export class KioskFormControlOptionSerializer extends BaseSerializer<KioskFormControlOption> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'label',
    'comment',
    'state',
    'position'
  ]
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<KioskFormControlOption>): KioskFormControlOption {
    return new KioskFormControlOption(attributes)
  }
}
