import { BaseModel } from "./base.model"
import { KioskFormControl, KioskFormControlType } from "models/kiosk-forms/kiosk-form-control.model"


export interface KioskFormFile {
  contentType: string
  originalFilename: string
  size: number
  url: string
}

export type AnswerRawValue = string|KioskFormFile[]

export interface KioskFormAnswerMeta {
  controlType: KioskFormControlType
  rawValue: AnswerRawValue
}

export class KioskFormControlAnswer extends BaseModel {
  text: string
  meta: KioskFormAnswerMeta
  kioskFormControl: KioskFormControl
}