<div class="select__opt"
  *ngIf="!option.group"
  [style.padding-left.px]="level * 20 + 31"
  [ngClass]="{ selectable: selectable(), disabled: option.disabled }"
  (click)="toggleSelect()"
>
  <input type="checkbox" class="select__opt-checkbox"
    [checked]="selected()"
    [disabled]="option.disabled"
  >
  <div class="select__opt-label">{{ option.label }}</div>
  <div class="select__opt-sub-label" *ngIf="option.subLabel">{{ option.subLabel }}</div>
</div>

<div class="select__sub-opts"
*ngIf="option.subOptions && option.subOptions.length"
>
  <multi-select-group
    *ngIf="option.group"
    [group]="option"
    [level]="level"
    [selectedValues]="selectedValues"
    (selectOption)="selectGroup($event)"
    (deselectOption)="deselectGroup($event)"
  ></multi-select-group>
  <multi-select-option
    *ngFor="let subOption of option.subOptions; trackBy: trackByFn"
    [option]="subOption"
    [level]="level + 1"
    [selectedValues]="selectedValues"
    (selectOption)="selectSubOption($event)"
    (deselectOption)="deselectSubOption($event)"
  ></multi-select-option>
</div>
