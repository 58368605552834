import { BaseModel } from './base.model'
import { InteractiveSkillGrade } from './interactive-skill-grade.model'
import { forEach } from 'lodash-es'

export class InteractiveSkill extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
  }

  id: number
  grades: InteractiveSkillGrade[]
  skillId: number
  interactiveId: number
  name: string
  weight: number

  recalcGradesThreshold():void {
   let prevGrade = null
   forEach(this.grades, function(grade, i){
      if(prevGrade){
        grade.minPointsCountTreshold = prevGrade.pointsCountTreshold + 1
      }else{
        grade.minPointsCountTreshold = 0
      }
      prevGrade = grade
   }) 
  }
}
