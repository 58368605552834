import { BaseModel } from './base.model'

export class InteractiveContentItemTransitionTarget extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    type: 'InteractiveContentItemTransitionTarget::ContentItem'
  }

  _destroy: boolean
  id: number
  type: string
  interactiveContentItemTransitionId: number
  targetInteractiveContentItemId: number
}
