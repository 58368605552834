import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { SystemScreen } from './system-screen.model'
import { SystemScreenSerializer } from 'serializers'

@Injectable()
export class SystemScreenService extends BaseModelService<SystemScreen> {
  constructor() {
    super()

    this.configure({
      basePath:  'system_screens',
      key:       'systemScreen',
      pluralKey: 'systemScreens',
      serializer: SystemScreenSerializer
    })
  }
}
