import { BaseSerializer } from './base.serializer'
import { KioskFormControlAnswer } from 'models/kiosk-form-control-answer.model'
import { KioskFormControlSerializer } from 'models/kiosk-forms/kiosk-form-control.serializer'


export class KioskFormControlAnswerSerializer extends BaseSerializer<KioskFormControlAnswer> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    kioskFormControl: KioskFormControlSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<KioskFormControlAnswer>): KioskFormControlAnswer {
    return new KioskFormControlAnswer(attributes)
  }
}
