import { range, isArray, groupBy } from 'lodash-es'
import {
  City,
  Country,
  Folder,
  User,
  Program,
  SmsKind,
  Organization,
  Exam,
  WebHook
} from 'models'

export interface FormOption {
  label: string
  value: any
  disabled?: boolean
  group?: boolean
  subLabel?: string
  subOptions?: FormOption[]
}

export class FormOptionsBuilder {
  static folderOptions(folders: Folder[]): FormOption[] {
    return folders.map((folder: Folder) => {
      let option = { value: folder.id, label: folder.name }
      if(folder.subFolders.length) {
        option['subOptions'] = this.folderOptions(folder.subFolders)
      }
      return option
    })
  }

  static folderAncestryOptions(folders: Folder[]): FormOption[] {
    return folders.map(folder => {
      let option = { value: folder.childrenAncestry(), label: folder.name }
      if (folder.subFolders.length) {
        option['subOptions'] = this.folderAncestryOptions(folder.subFolders)
      }
      return option
    })
  }

  static folderItemOptions(
    folders: Folder[],
    items: { [key: number]: any[] }|any[],
    valueAttr: string = 'id',
    labelAttr: string = 'name',
    subLabelAttr: string = 'comment',
    folderIdAttr: string = 'folderId',
    includeEmpty: boolean = false
  ): FormOption[] {
    let itemsIndex = isArray(items) ? groupBy(items, folderIdAttr) : items
    let result = []
    for (let folder of folders) {
      if (!this.folderSubtreeHasItems(folder, itemsIndex)) {
        continue
      }
      let group = { label: folder.name, group: true }
      if(folder.subFolders.length || (itemsIndex[folder.id] && itemsIndex[folder.id].length)) {
        group['subOptions'] = []
        if (folder.subFolders.length) {
          for (let subGroup of this.folderItemOptions(folder.subFolders, itemsIndex)) {
            group['subOptions'].push(subGroup)
          }
        }
        if(itemsIndex[folder.id] && itemsIndex[folder.id].length) {
          for(let item of itemsIndex[folder.id]) {
            group['subOptions'].push({
              value:    item[valueAttr],
              label:    item[labelAttr],
              subLabel: item[subLabelAttr]
            })
          }
        }
      }
      result.push(group)
    }

    if (includeEmpty) {
      result.unshift({
        value: null,
        label: "Не выбрано"
      })
    }
    return result
  }

  static cityOptions(countries: Country[], cities: City[]): FormOption[] {
    let result = []
    for (let country of countries) {
      let cityOptions = cities
        .filter((city: City) => city.countryId == country.id)
        .map((city: City) => ({ 
          value: city.id, 
          label: city.name 
        }))

      if (cityOptions.length > 0) {
        result.push({
          label: country.name,
          group: true,
          subOptions: cityOptions
        })
      }
    }
    return result
  }

  static organizationOptions(organizations: Organization[]): FormOption[] {
    return organizations.map(o => ({ value: o.id, label: o.name }))
  }

  static userOptions(users: User[]): FormOption[] {
    return users.map(u => ({ value: u.id, label: u.decorate().fullname(), subLabel: u.email }))
  }

  static contactPersonOptions(persons: User[]): FormOption[] {
    let result = []
    let contactPersonOptions = persons.map((person: User) => ({ 
      value: person.id, 
      label: person.firstname + " " +  person.lastname,
      subLabel: person.email
    }))
    if (contactPersonOptions.length > 0) {
      result = contactPersonOptions
    }
    return result
  }

  static folderSubtreeHasItems(folder: Folder, itemsIndex: { [key: number]: any[] }): boolean {
    if (itemsIndex[folder.id] && itemsIndex[folder.id].length) {
      return true
    }
    for (let subFolder of folder.subFolders) {
      if (this.folderSubtreeHasItems(subFolder, itemsIndex)) {
        return true
      }
    }
    return false
  }

  static programOptions(programs: Program[]): FormOption[] {
    return programs.map(p => ({
      value: p.id,
      label: p.name,
      subLabel: p.comment
    }))
  }

  static examOptions(exams: Exam[]): FormOption[] {
    return exams.map(e => ({
      value: e.id,
      label: e.name,
      subLabel: e.comment
    }))
  }

  static utcOffsetOptions(): FormOption[]{
    let options = range(-12, 15).map((offset: number) => {
      return { 
        value: offset, 
        label: offset == 0 ? 'GMT' : `GMT${offset > 0 ? '+' : ''}${offset}` 
      }
    })
    options.unshift({ value: null, label: '' })

    return options
  }

  static interviewTypeOptions(): FormOption[] {
    return [{
      value: "interview",
      label: "Онлайн интервью"
    }, {
      value: "hub_moderation",
      label: "Модерация записей в хаб"
    }, {
      value: "unlock_base",
      label: "Разблокировка эконома"
    }]
  }

  static smsTemplateKindOptions(kinds: SmsKind[]): FormOption[] {
    return kinds.map(k => ({
      value: k.kind,
      label: k.name,
      subLabel: k.comment
    }))
  }

  static webHookOptions(webHooks: WebHook[]): FormOption[] {
    return webHooks.map(wh => ({
      value: wh.id,
      label: wh.name,
      subLabel: wh.comment
    }))
  }
}
