import { isFunction, isObject, isArray } from 'lodash-es'

import { KeyTransformer } from 'util/key-transformer'

export abstract class BaseSerializer<T> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static serialize<T>(obj: T|Object): Object {
    let result = {}
    for(let key of this.SERIALIZABLE_ATTRIBUTES) {
      let externalKey = this.serializeKey(key)
      if(isFunction(obj[key])) {
        result[externalKey] = this.serializeAttribute(key, obj[key]())
      }
      else if(obj.hasOwnProperty(key) || Object.getOwnPropertyDescriptor(obj.constructor.prototype, key)) {
        result[externalKey] = this.serializeAttribute(key, obj[key])
      }
    }
    return KeyTransformer.underscore(result)
  }

  static serializeAttribute(key: string, value: any): any {
    if(value === null || value === undefined || value === '') { return null }

    let serializer = this.SERIALIZERS[key]
    if(isObject(serializer)) {
      let serializeFn = serializer['serialize'] || serializer
      serializeFn = serializeFn.bind(serializer)
      if(isArray(value)) {
        let result = []
        for(let item of value) {
          result.push(serializeFn(item))
        }
        return result
      } else {
        return serializeFn(value)
      }
    } else {
      return value
    } 
  }

  static serializeKey(key: string): string {
    return this.renameKey(key, this.SERIALIZE_RENAME_KEYS)
  }

  static deserialize<T>(externalAttributes: Object): T {
    externalAttributes = KeyTransformer.camelize(externalAttributes)
    let attributes = {}
    for(let externalKey in externalAttributes) {
      let key = this.deserializeKey(externalKey)
      let externalValue = externalAttributes[externalKey]
      attributes[key] = this.deserializeAttribute(key, externalValue)
    }

    return this.initializeObject(attributes) 
  }

  static deserializeAttribute(key: string, value: any): any {
    if(value === null || value === '') { return null }
    let deserializer = this.DESERIALIZERS[key]
    if(isObject(deserializer)) {
      let deserializeFn = deserializer['deserialize'] || deserializer
      deserializeFn = deserializeFn.bind(deserializer)
      if(isArray(value)) {
        let result = []
        for(let item of value) {
          result.push(deserializeFn(item))
        }
        return result
      } else {
        return deserializeFn(value)
      }
    } else {
      return value
    }
  }

  static deserializeKey(key: string): string {
    return this.renameKey(key, this.DESERIALIZE_RENAME_KEYS)
  }

  static renameKey(key: string, renameMap: Object): string {
    if(renameMap[key]) {
      return renameMap[key]
    } else {
      return key
    }
  }

  static initializeObject(atributes: Object): any {
    throw '`initializeObject` must be implemented in child class'
  }
}
