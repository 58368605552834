import { Injectable } from '@angular/core'
import { ValidatorFn, AsyncValidatorFn, FormControl } from '@angular/forms'

import { MaxLengthValidator } from './validators/max-length.validator'
import { maxValidator } from './validators/max.validator'
import { MinLengthValidator } from './validators/min-length.validator'
import { minValidator } from './validators/min.validator'
import { PatternValidator } from './validators/pattern.validator'
import { PeriodValidator } from './validators/period.validator'
import { RemoteValidator } from './validators/remote.validator'
import { RequiredValidator } from './validators/required.validator'
import { minArrayLengthValidator } from './validators/min-array-length.validator'
import { maxFormArrayLengthValidator } from './validators/max-form-array-length.validator'
import { UniquenessValidator } from './validators/uniqueness.validator'
import { ProgramRegistrationFieldValidator } from './validators/program-registration-field.validator'
import { ProgramLastSectionValidator } from './validators/program-last-section.validator'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class ValidatorsService {
  constructor(
    private appHttp: HttpClient
  ) {}

  max(max: number, message: string = ""): ValidatorFn {
    return maxValidator(max, message)
  }

  min(min: number, message: string = ""): ValidatorFn {
    return minValidator(min, message)
  }

  maxLength(maxLength: number): ValidatorFn {
    return new MaxLengthValidator(maxLength).validate
  }

  minLength(minLength: number): ValidatorFn {
    return new MinLengthValidator(minLength).validate
  }

  pattern(regexp: RegExp): ValidatorFn {
    return new PatternValidator(regexp).validate
  }

  period(from: string, to: string, format: string = null) {
    return new PeriodValidator(from, to, format).validate
  }

  remote(
    url: string,
    errorKey: string,
    requestData: Object = {},
    minValueLength: number = 1,
    debounceTime: number = 600
  ): AsyncValidatorFn {
    return new RemoteValidator(this.appHttp, url, errorKey, requestData, minValueLength, debounceTime).validate
  }

  required(message: string = ""): ValidatorFn {
    return new RequiredValidator(message).validate
  }

  maxFormArrayLength(maxLength: number, message: string = ""): ValidatorFn {
    return maxFormArrayLengthValidator(maxLength, message)
  }

  minArrayLength(minLength: number, message: string = ""): ValidatorFn {
    return minArrayLengthValidator(minLength, message)
  }

  multiSelectMinLength(minLength: number, message: string = ""): ValidatorFn {
    return (control: FormControl) => {
      const length = control.value.length
      if (length == 0 || length >= minLength) {
        return null
      } else {
        if (message.length == 0) {
          message = `Минимальная длинна: ${minLength}`
        } else {
          message = message
            .replace("{minLength}", minLength.toString())
            .replace("{length}", length.toString())
        }
        return { 
          multiSelectMinLength: { minLength, length, message }
        }
      }
    }
  }

  uniqueness(nestedControlName: string = null): ValidatorFn {
    return new UniquenessValidator(nestedControlName).validate
  }

  programRegistrationField(requiredFields: string[]): AsyncValidatorFn {
    return new ProgramRegistrationFieldValidator(requiredFields).validate
  }

  programLastSection(): ValidatorFn {
    return new ProgramLastSectionValidator().validate
  }
}
