import { BaseSerializer } from './base.serializer'
import { TimeSerializer } from './attributes'
import { AppTranslation } from 'models/app-translation.model'

export class AppTranslationSerializer extends BaseSerializer<AppTranslation> {
  static SERIALIZABLE_ATTRIBUTES = ['id', 'localeId', 'key', 'value', 'pularValue', 'forTablets', 'forWeb']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): AppTranslation {
    console.log("AppTranslationSerializer => initializeObject:", attributes)
    return new AppTranslation(attributes)
  }
}
