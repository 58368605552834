import { BaseModel } from './base.model'
import { reject, forEach } from 'lodash-es'
import { InteractiveContentItem } from './interactive-content-item.model'
import { InteractiveSkill } from './interactive-skill.model'
import { Program } from './program.model'
import { User } from './user.model'
import { Moment } from 'moment'

export class Interactive extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    contentItems: []
  }

  comment: string
  folderId: number
  id: number
  programs: Program[]
  contentItems: InteractiveContentItem[]
  name: string
  state: string
  interactiveSkills: InteractiveSkill[]
  alreadyPassed: boolean
  base: number //id of base interactive
  fallbackVideoId: number
  fallbackLessonId: number
  createdBy: User
  updatedBy: User
  createdAt: Moment
  updatedAt: Moment

  constructor(attributes: Object = {}, base: number = null) {
    super(attributes)

    if (base) {
      this.base = base
    }
  }

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  reorderContentItems(): void {
    let itemsToReorder  = reject(this.contentItems,
      { '_destroy': true }
    )
    forEach(itemsToReorder, function(contentItem, index) {
      contentItem.sortOrder = index
    })
  }

  insertContentItems(items: InteractiveContentItem[], index: number = this.contentItems.length): void {
    let contentItems = this.contentItems
    forEach(items, function(item, i){
      contentItems.splice(index, 0, item)
      index += 1
    })
    this.reorderContentItems()
  }

  get unreachableItems(): InteractiveContentItem[] {
    const items = this.contentItems

    if (!items || items.length == 0) {
      return []
    }

    // stack
    let s: InteractiveContentItem[] = []
    let explored: Set<number> = new Set()

    s.push(items[0])
    explored.add(items[0].id)

    while (s.length != 0) {
      let t = s.pop()

      t.connectedItemIds.filter(id => !explored.has(id)).forEach(id => {
        const item = items.find(i => i.id == id)
        if (item) {
          explored.add(id)
          s.push(item)
        }
      })
    }

    return items.filter(item => !explored.has(item.id))
  }
}
