import { ValidatorFn, FormArray, AbstractControl } from '@angular/forms'


export function minArrayLengthValidator(minLength: number, message?: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const value = control.value

    if (value instanceof Array) {
      const actualLength = value.length
      const isValid = actualLength >= minLength
      const params = {
        minlength: { minLength, actualLength },
        message: message
      }
      console.log(value, params)
      return isValid ? null : params
    }

    return null
  }
}
