import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { isError, isObject } from 'lodash-es'
import { AlertType } from './alert.type'

export interface AlertParams {
  title?: string
  message: string
}

@Injectable()
export class AlertService {

  constructor(
    private toastrService: ToastrService
  ) {}

  info(options: AlertParams|string|Response) {
    this.show(AlertType.info, options)
  }

  success(options: AlertParams|string|Response) {
    this.show(AlertType.success, options)
  }

  notice(options: AlertParams|string|Response) {
    this.show(AlertType.notice, options)
  }

  warning(options: AlertParams|string|Response) {
    this.show(AlertType.warning, options)
  }

  error(options: AlertParams|string|Response) {
    this.show(AlertType.error, options)
  }

  private parseInput(options: AlertParams|string|Response): AlertParams {
    let result: AlertParams

    if (options.constructor == Response) {
      let message: string
      let data = options['json']()

      if (isObject(data) && data['error_description']) {
        message = data['error_description']
      } 
      else {
        message = `${options['status']} - ${options['statusText']}`
      }
      result = { title: null, message }
    }
    else if(isError(options)) {
      result = { title: null, message: options.toString() }
    }
    else if(isObject(options)) {
      result = options as AlertParams
    } 
    else {
      result = { title: null, message: options }
    }

    return result
  }

  private show(type: AlertType, options: AlertParams|string|Response) {
    const { title, message, ...otherOptions } = this.parseInput(options)
    const typeName = AlertType[type]
    this.toastrService[typeName](message, title, otherOptions)
  }
}
