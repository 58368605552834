import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

import { PLURALIZATION_RULES } from './pluralization-rules'

@Injectable({
  providedIn: "root"
})
export class PluralizeService {
  constructor(private translateService: TranslateService) {}

  get(key: string, count: number, interpolateParams: Object = {}): Observable<string> {
    let pluralKey = this.pluralKey(count)
    interpolateParams['count'] = count
    return this.translateService.get(key + '.' + pluralKey, interpolateParams)
  }

  instant(key, count: number, interpolateParams: Object = {}): string {
    let pluralKey = this.pluralKey(count)
    interpolateParams['count'] = count
    return this.translateService.instant(key + '.' + pluralKey, interpolateParams)
  }

  private pluralKey(count: number): string {
    return PLURALIZATION_RULES[this.translateService.currentLang](count)
  }
}
