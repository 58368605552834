import { BaseModel } from './base.model'
import { InteractiveContentItemTransitionSkill } from './interactive-content-item-transition-skill.model'
import { InteractiveContentItemTransitionTarget } from './interactive-content-item-transition-target.model';

export class InteractiveContentItemTransition extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    transitionSkills: new Array<InteractiveContentItemTransitionSkill>(),
    target: new InteractiveContentItemTransitionTarget()
  }

  _destroy: boolean
  id: number
  name: string
  itemId: number
  timeout: number
  comment: string
  orderNumber: number
  gesture: string
  startTime: number
  endTime: number
  leftX: number
  leftY: number
  rightX: number
  rightY: number
  transitionSkills: InteractiveContentItemTransitionSkill[]
  target: InteractiveContentItemTransitionTarget
}
