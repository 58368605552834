import { NgModule, Injector, ErrorHandler, Injectable, InjectionToken } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'
import { CoreModule } from './core/core.module'
import { ModelsModule } from './models/models.module'
import { APP_ROUTES } from './app.routes'
import { AppComponent } from './app.component'
import * as Rollbar from 'rollbar'
import { Constants } from 'config/constants'
import { HttpErrorInterceptor } from 'core';

const isProduction = Constants.ENV.isProduction

export var APP_INJECTOR

/**
 * Rollbar
 */
const rollbarConfig = {
  accessToken: '4fa0b5555194403a98e431d3de885e6a',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: isProduction
}
@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(err: any): void {
    console.log("handleError:", err, err.message)
    const chunkFailedMessage = /Loading chunk [\d]+ failed/

    if (chunkFailedMessage.test(err.message)) {
      window.location.reload()
    }
    else {
      if (err.alreadySent) return
      err.alreadySent = true
      const rollbar = this.injector.get(RollbarService)
      rollbar.error(err.originalError || err)
      throw err
    }
  }
}
export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: false }),
    CoreModule.forRoot(),
    ModelsModule.forRoot({})
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ]
})

export class AppModule {
  constructor(injector: Injector) {
    APP_INJECTOR = injector
  }
}
