import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Exam } from './exam.model'
import { ExamSerializer } from 'serializers'

@Injectable()
export class ExamService extends BaseModelService<Exam> {
  constructor() {
    super()

    this.configure({
      basePath:  'exams',
      key:       'exam',
      pluralKey: 'exams',
      serializer: ExamSerializer
    })
  }
}
