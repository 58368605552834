import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { Title }  from '@angular/platform-browser'
import { ModalModule } from 'ngx-bootstrap/modal'
import { PaginationModule } from 'ngx-bootstrap/pagination'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { TranslateModule } from '@ngx-translate/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'

import { AlertService } from './alert'
// import { AuthenticationService, AuthorizationService } from './auth/'
import { Body } from './dom'
import { FileUploadsService } from './file-uploads'
import { ValidatorsService } from './forms'
import { PluralizeService } from './i18n'
import { RouterAuthorizer } from './router'
import { InitDataLoaderResolver } from './init-data-loader.resolver'
import { CommonModule } from '@angular/common'
import { FontFaceObserverService } from './fontfaceobserver.service'
import { AngularTokenModule } from 'angular-token'
import { AuthorizationConfig, Constants } from 'config'
import { ConfirmModule } from './confirm/confirm.module'
import { AuthModule } from './auth/auth.module'

const apiBase = Constants.API.HOST
const apiPath = Constants.API.PATH


@NgModule({
  imports: [
    HttpClientModule,
    AngularTokenModule.forRoot({
      apiBase,
      apiPath,
      signInPath: 'auth/sign_in',
      signOutPath: 'auth/sign_out',
      validateTokenPath: 'auth/validate_token'
    }),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    TranslateModule.forRoot(),
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-bottom-right',
      timeOut: 7 * 1000,
      enableHtml: true
    }),
    ConfirmModule.forRoot(),
    AuthModule.forRoot()
  ]
})

export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        Title,
        AuthorizationConfig,
        // AuthenticationService,
        // AuthorizationService,
        AlertService,
        Body,
        FileUploadsService,
        ValidatorsService,
        PluralizeService,
        RouterAuthorizer,
        InitDataLoaderResolver,
        FontFaceObserverService
      ]
    }
  }
}
