import { FormControl } from '@angular/forms'

export class RequiredValidator {

  constructor(private message: string = "") {
    this.validate = this.validate.bind(this)
  }

  validate(control: FormControl): Object {
    if (this.isBlank(control.value)) {
      return { 
        required: true,
        message: this.message || "Не может быть пустым"
      }
    } else {
      return null
    }
  }

  private isBlank(value: any): boolean {
    if (this.isString(value)) {
      return value.trim().length == 0
    } 
    else if (this.isArray(value)) {
      return value.length == 0
    } 
    else {
      return !value && value != 0
    }
  }

  private isString(value: any): boolean {
    return typeof value == 'string' || value instanceof String
  }

  private isArray(value: any): boolean {
    return value instanceof Array
  }
}
