import { FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms'

import { BaseComponent } from 'core/abstract-components'
import { APP_INJECTOR } from 'app.module'
import { ValidatorsService } from './validators.service'
import { Component } from '@angular/core'

@Component({
  template: ''
})
export abstract class BaseFormComponent extends BaseComponent {
  form: FormGroup
  validators: ValidatorsService
  loading: boolean
  wasSubmitted: boolean = false

  constructor() {
    super()

    this.validators = APP_INJECTOR.get(ValidatorsService)
  }

  ngOnInit() {
    super.ngOnInit()
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.wasSubmitted = false
  }

  submit(event?: any) {
    this.wasSubmitted = true
    if (event) { event.preventDefault() }
    if (this.loading) { return }
    
    if (!this.form.valid) {
      console.log("Form errors:", this.form.errors)
      this.handleInvalidForm()
    } else {
      this.sendForm()
    }
  }

  abstract sendForm()

  handleInvalidForm() {
    this.markFormAsTouched(this.form)
  }

  markFormAsTouched(control: AbstractControl) {
    if (control instanceof FormControl) {
      control.markAsTouched()
    }
    else if (control instanceof FormArray) {
      control.markAsTouched()
      control.controls.forEach(c => this.markFormAsTouched(c))
    }
    else if (control instanceof FormGroup) {
      control.markAsTouched()
      for (const key in control.controls) {
        this.markFormAsTouched(control.controls[key])
      }
    }
  }

  invalidControl(control: FormControl): boolean {
    return control.enabled && !control.valid && control.touched
  }

  controlRequiredError(control: FormControl): boolean {
    return this.invalidControl(control) && control.errors && control.errors.required
  }

  controlUniquenessError(control: FormControl): boolean {
    return this.invalidControl(control) && control.errors && control.errors.uniqueness
  }

  controlMinLengthError(control: FormControl): boolean {
    return this.invalidControl(control) && control.errors && control.errors.minlength
  }

  controlMinError(control: FormControl): boolean {
    return this.invalidControl(control) && control.errors && control.errors.min
  }
}
