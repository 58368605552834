import { BaseSerializer } from 'serializers'
import { KioskFormControlOptionSerializer } from './kiosk-form-control-option.serializer'
import { KioskFormControl } from './kiosk-form-control.model'

export class KioskFormControlSerializer extends BaseSerializer<KioskFormControl> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'label',
    'comment',
    'controlType',
    'state',
    'required',
    'config',
    'position',
    'controlOptions'
  ]
  static SERIALIZERS = {
    controlOptions: KioskFormControlOptionSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    controlOptions: 'kioskFormControlOptionsAttributes',
  }

  static DESERIALIZERS = {
    controlOptions: KioskFormControlOptionSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<KioskFormControl>): KioskFormControl {
    return new KioskFormControl(attributes)
  }
}
