import { BaseSerializer } from './base.serializer'
import { AbExperiment, Group } from 'models/ab-experiment.model'
import { TimeSerializer } from './attributes'
import { UserSerializer } from './user.serializer';

export class AbExperimentGroupSerializer extends BaseSerializer<Group> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id', 'name', 'hitRate', 'programId', '_destroy'
  ]

  static initializeObject(attributes: Object): Group {
    return new Group(attributes)
  }
}

export class AbExperimentSerializer extends BaseSerializer<AbExperiment> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id', 
    'name', 
    'comment', 
    'salt',
    'participantsCount',
    'participantsNeeded',
    'blackList',
    'whiteList',
    'mainGroup',
    'otherGroups'
  ]
  static SERIALIZERS = {
    mainGroup: AbExperimentGroupSerializer,
    otherGroups: AbExperimentGroupSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    mainGroup: 'mainGroupAttributes',
    otherGroups: 'otherGroupsAttributes'
  }

  static DESERIALIZERS = {
    startedAt: TimeSerializer,
    finishedAt: TimeSerializer,
    author: UserSerializer,
    mainGroup: AbExperimentGroupSerializer,
    otherGroups: AbExperimentGroupSerializer,
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): AbExperiment {
    return new AbExperiment(attributes)
  }
}