import { BaseSerializer } from './base.serializer'
import { CustomThemeModel, CustomThemeModelParams } from 'models/custom-theme.model'

export class CustomThemeSerializer extends BaseSerializer<CustomThemeModel> {
  static SERIALIZABLE_ATTRIBUTES /*: (keyof CustomThemeModelParams)[] */= [
    "mainColor",
    "failureColor",
    "btnColorPrimary",
    "btnColorSecondary",
    "btnColorDisabled",
    "btnTextColorPrimary",
    "btnTextColorSecondary",
    "btnTextColorDisabled"
  ]
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: CustomThemeModelParams): CustomThemeModel {
    return new CustomThemeModel(attributes)
  }
}