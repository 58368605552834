import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { User } from './user.model'
import { UserSerializer } from 'serializers'
import { Observable } from 'rxjs'
import { map } from "rxjs/operators"

@Injectable()
export class UserService extends BaseModelService<User> {
  constructor() {
    super()

    this.configure({
      basePath:  'users',
      key:       'user',
      pluralKey: 'users',
      serializer: UserSerializer
    })
  }

  switchRole(role: string): Observable<User> {
    const url = this.buildUrl("switch_role")
    const body = { role }

    return this.appHttp.patch(url, body).pipe(
      map(resp => UserSerializer.deserialize(resp["user"]))
    )
  }

  switchOrganization(organizationId: number): Observable<User> {
    const url = this.buildUrl("switch_organization")
    const body = { organizationId }

    return this.appHttp.patch(url, body).pipe(
      map(resp => UserSerializer.deserialize(resp["user"]))
    )
  }
}
