import { BaseSerializer } from './base.serializer'
import { InteractiveContentItemTransition } from 'models/interactive-content-item-transition.model'
import { InteractiveContentItemTransitionSkillSerializer } from './interactive-content-item-transition-skill.serializer'
import {InteractiveContentItemTransitionTarget} from "models/interactive-content-item-transition-target.model";
import {InteractiveContentItemTransitionTargetSerializer} from "serializers/interactive-content-item-transition-target.serializer";

export class InteractiveContentItemTransitionSerializer extends BaseSerializer<InteractiveContentItemTransition> {
  static SERIALIZABLE_ATTRIBUTES = ['_destroy', 'id', 'name', 'itemId', 'target', 'timeout',
    'comment', 'orderNumber', 'gesture', 'startTime', 'endTime', 'leftX', 'leftY', 'rightX', 'rightY', 'transitionSkills']
  static SERIALIZERS = {
    transitionSkills: InteractiveContentItemTransitionSkillSerializer,
    target: InteractiveContentItemTransitionTargetSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    itemId: 'interactiveContentItemId',
    transitionSkills: 'transitionSkillsAttributes',
    target: 'targetAttributes'
  }

  static DESERIALIZERS = {
    transitionSkills: InteractiveContentItemTransitionSkillSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): InteractiveContentItemTransition {
    return new InteractiveContentItemTransition(attributes)
  }
}
