export class Scroll {
  static top(to?: number): number {
    if(to || to == 0) {
      window.scrollTo(0, to)
      return to
    } else {
      return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    }
  }
}
