import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core'
import { TextSearchComponent } from 'shared/search/text-search.component'

@Component({
  selector: 'text-search-and-sort-link',
  template: `
    <div class="header__search-col" 
      [ngClass]="{ open: isTextSearchOpen }"
    >
      <div *ngIf="!isTextSearchOpen" style="position:relative">
        <i class="header__search-icon" (click)="openTextSearch()"></i>
        <sort-link
          [sort]="sort"
          [text]="text"
          [linkClass]="linkClass"
          [currentSort]="currentSort"
          [currentSortDirection]="currentSortDirection"
          (onChange)="sortChanged($event)"
        ></sort-link>
      </div>

      <div *ngIf="isTextSearchOpen" style="position:relative">
        <text-search
          [inputClass]="inputClass"
          [inputType]="inputType"
          [value]="value"
          (onChange)="textSearchChanged($event)"
          (onKeyup)="textSearchKeyup($event)"
        ></text-search>
        <i class="header__search-close" (click)="closeTextSearch()"></i>
      </div>
    </div>
  `
})
export class TextSearchAndSortLinkComponent {
  // SORT LINK PARAMS
  @Input() currentSort: string
  @Input() currentSortDirection: string
  @Input() linkClass: string = ''
  @Input() sort: string
  @Input() text: string
  @Output('onSortChange') onSortChangeEmitter = new EventEmitter<Object>()

  // TEXT SEARCH PARAMS
  @ViewChild(TextSearchComponent) protected textSearchCmp: TextSearchComponent
  @Input() value: string = ''
  @Input() inputClass: string = ''
  @Input() inputType: string = 'text'
  @Output('onTextSearchChange') onTextSearchChangeEmitter = new EventEmitter<Object>()
  @Output('onKeyup') onKeyupEmitter = new EventEmitter<Object>()

  // 
  @Output('onCloseTextSearch') onCloseTextSearchEmitter = new EventEmitter<boolean>()

  isTextSearchOpen: boolean = false

  ngOnInit() {
    if (this.value && this.value.length > 0) {
      this.isTextSearchOpen = true
    }
  }

  sortChanged(e: any) {
    this.onSortChangeEmitter.emit(e)
  }

  textSearchChanged(e: any) {
    this.onTextSearchChangeEmitter.emit(e)
  }

  textSearchKeyup(e: any) {
    this.onKeyupEmitter.emit(e)
  }

  openTextSearch() {
    this.isTextSearchOpen = true
    setTimeout(() => {
      this.textSearchCmp.setFocus()
    })
  }

  closeTextSearch() {
    this.isTextSearchOpen = false
    this.onCloseTextSearchEmitter.emit(true)
  }
}