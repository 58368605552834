<div class="modal fade" bsModal #modal="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <loader [visible]="loading"></loader>

      <div *ngIf="!!form">
        <div class="modal-header">
          <h4 class="modal-title">{{ folder.persisted() ? "Редактирование папки" : "Добавление папки" }}</h4>
        </div>

        <div class="modal-body">
          <form [formGroup]="form" (submit)="submit($event)">
            <div class="form-group" [ngClass]="{ 
              'has-error': invalidControl(form.controls.name)
            }">
              <label class="control-label">Название</label>
              <input type="text" class="form-control"
              formControlName="name" 
              [(ngModel)]="folder.name" 
              #nameInput
              >
              <show-errors [control]="form.controls.name"></show-errors>
            </div>
          </form>
        </div>
        
        <div class="modal-footer">
          <div class="pull-left buttons">
            <button type="button" class="btn btn-primary btn_width_108" (click)="submit()">Сохранить</button>
            <button type="button" class="btn btn-default btn_width_108" (click)="hide()">Отменить</button>
          </div>
          
          <div class="pull-right buttons" *ngIf="folder.persisted()">
            <div class="btn-group" dropdown>
              <button id="button-basic" class="btn btn-default-fr dropdown-toggle"
              dropdownToggle 
              type="button" 
              aria-controls="dropdown-basic"
              >
                Удалить
              </button>
              
              <ul id="dropdown-basic" class="dropdown-menu"
              role="menu" 
              aria-labelledby="button-basic"
              *dropdownMenu 
              >
                <li role="menuitem"
                *ngIf="(allowed$('Folder', 'destroy', folder) | async) && canDestroyWithoutContents()"
                >
                  <a class="dropdown-item" href="#"
                  (click)="$event.preventDefault(); destroy();">Удалить только папку</a>
                </li>

                <li role="menuitem"
                *ngIf="allowed$('Folder', 'destroy', folder) | async"
                >
                  <a class="dropdown-item" href="#"
                  (click)="$event.preventDefault(); destroyWithContents();">Удалить с содержимым</a>
                </li>
              </ul>
            </div>

            <!-- <button
              type="button"
              class="btn btn-default-fr"
              *ngIf="(allowed$('Folder', 'destroy', folder) | async) && canDestroyWithoutContents()"
              (click)="destroyConfirm()"
            >
              Удалить только папку
            </button>
            
            <button
              type="button"
              class="btn btn-default-fr"
              *ngIf="allowed$('Folder', 'destroy', folder) | async"
              (click)="destroyWithContents()"
            >
              Удалить с содержимым
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>