<div class="select" [ngClass]="{ open: isDropdownOpen, disabled: disabled }">
  <button class="select__toggle" type="button" (click)="onToggleClick($event)">
    <span>{{ caption() }}</span>
    <i class="fa select__caret" [ngClass]="isDropdownOpen ? 'fa-caret-up' : 'fa-caret-down'"></i>
  </button>
  
  <div #dropdown class="select__dropdown"
  [ngClass]="{ up: isDropdownUp }"
  perfect-scrollbar
  [perfectScrollbarOptions]="{ 
    suppressScrollX: true,
    wheelPropagation: false
  }"
  >
    <div class="drop-section-options-search" *ngIf="showSearch && isDropdownOpen">
      <input #search type="text" class="form-control" placeholder="Искать по буквам"
      (keyup)="onKeyup(search.value)"
      (change)="$event.stopPropagation()"
      [(ngModel)]="optionsSearchTerm"
      />
    </div>

    <div class="select__opts" *ngIf="isDropdownOpen">
      <single-select-option
        *ngFor="let option of filteredOptions; trackBy: trackByFn"
        [option]="option"
        [level]="0"
        [selectedValue]="selectedValue"
        [selectOptionFn]="selectOption"
      ></single-select-option>

      <div class="select__empty" *ngIf="options.length == 0">
        Нет опций
      </div>
    </div>
  </div>
</div>