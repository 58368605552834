import { Injectable } from '@angular/core'
import { FormOption } from 'core'
import { BaseModelService } from 'models/base-model.service'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DictionarySerializer } from 'serializers'
import { Dictionary } from './dictionary.model'

@Injectable({
  providedIn: "root"
})
export class DictionariesService extends BaseModelService<Dictionary> {
  private dictionaries = new BehaviorSubject<Dictionary[]>([])
  /**
   * Включает дефолтные параметры регистрации
   */
  readonly dictionaries$ = this.dictionaries.asObservable()

  constructor() {
    super()

    this.configure({
      basePath:  'dictionaries',
      key:       'dictionary',
      pluralKey: 'dictionaries',
      serializer: DictionarySerializer
    })

    this.indexWithOptions().subscribe(
      dictionaries => this.dictionaries.next(dictionaries)
    )
  }

  touch(): void {
    this.indexWithOptions().subscribe(
      dictionaries => this.dictionaries.next(dictionaries)
    )
  }

  indexWithOptions(params?: Object): Observable<Dictionary[]> {
    const url = this.buildUrl('index_with_options')
    return this.get(url, params).pipe(
      map(data => data["dictionaries"] as Dictionary[])
    )
  }

  variableTypeOptions: FormOption[] = [
    { label: "Число", value: "number" },
    { label: "Строка", value: "string" },
    { label: "Дата", value: "date" },
    { label: "Телефон", value: "phone" },
    { label: "Город", value: "city" },
    { label: "Список", value: "dictionary" },
    { label: "Email", value: "email" },
    { label: "ФИО", value: "fio", disabled: true },
    { label: "Телеграм", value: "telegram", disabled: true }

  ]

  variableTypeLabel(value: string): string {
    const option = this.variableTypeOptions.find(o => o.value == value)
    return option && option.label
  }
}
