import { BaseModel } from './base.model'
import { Program } from './program.model'
import { QuizQuestion } from './quiz-question.model'
import { Question } from './question.model'


export class Quiz extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    minCorrectAnswers:     0,
    questionsCount:        0,
    questionsLimitEnabled: false,
    shuffleQuestions:      false,
    questions: [],
    questionIds: [],
    programs: []
  }

  comment: string
  folderId: number
  id: number
  quizQuestions: QuizQuestion[]
  programs: Program[]
  minCorrectAnswers: number
  name: string
  questionsCount: number
  questionsLimit: number
  questionsLimitEnabled: boolean
  shuffleQuestions: number
  state: string
  timeLimit: number
  questions: Question[]
  questionIds: number[]


  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  get timeLimitMinutes(): number {
    if(this.timeLimit) {
      return Math.round(this.timeLimit / 60 * 100) / 100
    } else {
      return this.timeLimit
    }
  }

  set timeLimitMinutes(minutes: number) {
    this.timeLimit = minutes ? minutes * 60 : minutes
  }
}
