import { FormOption } from 'core'
import { BaseModel } from 'models/base.model'
import { KioskFormControlOption } from './kiosk-form-control-option.model'

export type KioskFormControlType = "longText"|"files"|"singleSelect"

export interface FilesControlConfig {
  multiFileSelect?: boolean
}

export type ControlConfig = Object|FilesControlConfig

export class KioskFormControl extends BaseModel {
  static controlTypeOptions: FormOption[] = [
    { value: "longText", label: "Длинный текст" },
    { value: "files", label: "Файлы" },
    { value: "singleSelect", label: "Один из списка" },
  ]

  static DEFAULT_ATTRIBUTES = {
    controlOptions: []
  }

  label: string
  comment: string
  controlType: KioskFormControlType
  state: string
  required: boolean
  config: ControlConfig
  position: number
  controlOptions: KioskFormControlOption[]

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
