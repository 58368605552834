import { BaseSerializer } from './base.serializer'
import { StaticScreen } from 'models/static-screen.model'
import { TimeSerializer } from './attributes'
import { UserSerializer } from './user.serializer'

export class StaticScreenSerializer extends BaseSerializer<StaticScreen> {
  static SERIALIZABLE_ATTRIBUTES = [
    'comment',
    'content',
    'grapesJsContent',
    'folderId',
    'name',
    'nameTranslations',
    'contentTranslations',
    'availableLocales',
    'styles',
    'editorType',
    'cssLibraires'
  ]
  static SERIALIZERS = {
    grapesJsContent: {
      serialize: (obj) => {
        return JSON.stringify(obj)
      }
    }
  }
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    grapesJsContent: {
      deserialize: (jsonString) => {
        try {
          return JSON.parse(jsonString)
        } catch (err) {
          console.log("Can not parse grapesJs data", err)
          return null
        }
      }
    },
    createdAt: TimeSerializer,
    updatedAt: TimeSerializer,
    createdBy: UserSerializer,
    updatedBy: UserSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): StaticScreen {
    return new StaticScreen(attributes)
  }
}
