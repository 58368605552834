import { BaseModel } from './base.model'

export class Folder extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  private _ancestry: string
  private _ancestorIds: number[]
  id: number
  itemsCount: number
  name: string
  state: string
  subFolders: Folder[]

  get ancestry(): string {
    return this._ancestry
  }

  set ancestry(value: string) {
    this._ancestry = value
    if(value) {
      this._ancestorIds = value.split('/').map(id => { return +id })
    } else {
      this._ancestorIds = []
    }
  }

  get ancestorIds(): number[] {
    return this._ancestorIds
  }

  get parentId(): number {
    return this.ancestorIds[this.ancestorIds.length - 1] || null
  }

  childrenAncestry(): string {
    if(!this.id) {
      throw 'Folder is not persisted'
    } else {
      return this.ancestorIds.concat(this.id).join('/')
    }
  }

  depth(): number {
    return this.ancestorIds.length
  }

  isParentOf(folder: Folder): boolean {
    return folder.parentId == this.id
  }

  isAncestorOf(folder: Folder): boolean {
    return folder.ancestorIds.indexOf(this.id) > -1
  }

  isChildOf(folder: Folder): boolean {
    return this.parentId == folder.id
  }

  isDescendantOf(folder: Folder): boolean {
    return this.ancestorIds.indexOf(folder.id) > -1
  }
}
