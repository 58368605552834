import { ViewChild, Component } from '@angular/core'
import { Location } from '@angular/common'
import { Moment } from 'moment'
import { isEmpty, isEqual, cloneDeep } from 'lodash-es'

import { APP_INJECTOR } from 'app.module'
import { BasePageComponent } from './base-page.component'
import { TextSearchComponent, LabelFilter } from 'shared/search'
import { ListMeta } from 'models'

@Component({
  template: ''
})
export abstract class BaseListPageComponent extends BasePageComponent {
  @ViewChild(TextSearchComponent, { static: true }) textSearchCmp: TextSearchComponent

  location: Location

  currentPage: number
  sort: string
  sortDirection: string
  searchParams: Object
  labelFilters: LabelFilter[] = []
  perPage: number
  listMeta: ListMeta = {}

  isTextSearchOpen: boolean = false

  constructor() {
    super()
    this.location = APP_INJECTOR.get(Location)
  }

  ngOnInit() {
    super.ngOnInit()

    this.isTextSearchOpen = !!this.getSearchParam('query')
  }

  ngOnDestroy() {
    super.ngOnDestroy()
  }

  getSearchParam(key): any {
    return this.searchParams[key]
  }

  setSearchParam(key: string, value: any) {
    let params = {}
    params[key] = value
    this.setSearchParams(params)
  }

  setSearchParams(params: Object) {
    let result = this.searchParams
    let changed = false
    for(let key in params) {
      let value = params[key]
      if(value == result[key]) { continue }
      if(value == undefined || value == null) {
        delete result[key]
      } else {
        result[key] = value
      }
      changed = true
    }
    if(changed) {
      this.searchParams = result
      this.currentPage = 1
      this.onSearchParamsChange()
    }
  }

  setTimeRangeSearchParams(fromKey: string, untilKey: string, fromValue: Moment, untilValue: Moment) {
    let params = {}
    params[fromKey] = fromValue ? fromValue.unix() : null
    params[untilKey] = untilValue ? untilValue.unix() : null
    this.setSearchParams(params)
  }

  setLocalTimeRangeSearchParams(fromKey: string, untilKey: string, fromValue: Moment, untilValue: Moment) {
    let params = {}
    params[fromKey] = fromValue ? fromValue.local().format("YYYY-MM-DD HH:mm:ss") : null
    params[untilKey] = untilValue ? untilValue.local().format("YYYY-MM-DD HH:mm:ss") : null
    this.setSearchParams(params)
  }

  setDateRangeSearchParams(fromKey: string, untilKey: string, fromValue: Moment, untilValue: Moment) {
    let params = {}
    params[fromKey] = fromValue ? fromValue.format('DD-MM-YYYY') : null
    params[untilKey] = untilValue ? untilValue.format('DD-MM-YYYY') : null
    this.setSearchParams(params)
  }

  resetSearchParams() {
    if(!isEqual(this.searchParams, {})) {
      this.searchParams = {}
      this.onSearchParamsChange()
    }
  }

  setPage(page: number) {
    if(page != this.currentPage) {
      this.currentPage = page
      this.onPageChange()
    }
  }

  setSort(sort: string, sortDirection: string) {
    if(sort != this.sort || sortDirection != this.sortDirection) {
      this.currentPage = 1
      this.sort = sort
      this.sortDirection = sortDirection
      this.onSortChange()
    }
  }

  setPerPage(perPage: number) {
    if (perPage != this.perPage) {
      this.perPage = perPage
      this.currentPage = 1
      this.onPerPageChange()
    }
  }

  abstract onSearchParamsChange()

  abstract onPageChange()

  abstract onSortChange()

  onPerPageChange() {}

  buildFetchParams(): Object {
    let result = cloneDeep(this.searchParams)
    let labelFilters = this.labelFilters.reduce((hash, filter) => {
      hash[filter.requestKey] = filter.value
      return hash
    }, {})
    Object.assign(result, labelFilters)

    if(this.currentPage)   { result['page'] = this.currentPage }
    if(this.sort)          { result['sort'] = this.sort }
    if(this.sortDirection) { result['sortDirection'] = this.sortDirection }
    if(this.perPage)       { result['perPage'] = this.perPage }

    return result
  }

  searching(): boolean {
    return !isEmpty(this.searchParams) || !isEmpty(this.labelFilters)
  }

  openTextSearch() {
    this.isTextSearchOpen = true
    setTimeout(() => {
      this.textSearchCmp.setFocus()
    })
  }

  closeTextSearch() {
    this.isTextSearchOpen = false
    this.setSearchParam('query', null)
  }

  onTextSearchKeyup(event) {
    if(event.keyCode == 27) {
      this.closeTextSearch()
    }
  }

  onRemoveLabelFilter(filter: LabelFilter) {
    let index = this.labelFilters.indexOf(filter)
    if(index > -1) {
      this.labelFilters.splice(index, 1)
      let queryParams = new URLSearchParams(window.location.search.slice(1))
      queryParams.delete(filter.key)
      let query = queryParams.toString()
      let path = query ? `${window.location.pathname}?${query}` : window.location.pathname
      this.location.replaceState(path)
      this.afterRemoveLabelFilter(filter)
    }
  }

  afterRemoveLabelFilter(filter: LabelFilter) {
    console.error("`afterRemoveLabelFilter` should be implemented in derived component")
  }
}
