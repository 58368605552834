import { camelCase, snakeCase } from 'lodash-es'

export class KeyTransformer {
  static camelize(obj: any): any {
    return this.transformKeys(obj, this.camelizeKey)
  }

  static underscore(obj: any): any {
    return this.transformKeys(obj, this.underscoreKey)
  }

  static camelizeKey(key: string): string {
    return camelCase(key)
  }

  static underscoreKey(key: string): string {
    return key.charAt(0) == '_' ? `_${ snakeCase(key) }` : snakeCase(key)
  }

  static transformKeys(obj: any, transformFn: Function): any {
    if(obj && obj.constructor === Array) {
      let result = []
      for(let value of obj) {
        result.push(this.transformKeys(value, transformFn))
      }
      return result
    }
    else if(obj === Object(obj)) {
      let result = {}
      for(let key in obj) {
        result[transformFn(key)] = this.transformKeys(obj[key], transformFn)
      }
      return result
    } else {
      return obj
    }
  }
}
