import { ViewChild, ElementRef, Component } from '@angular/core'
import { forkJoin, Subscription } from 'rxjs'
import { DragulaService } from 'ng2-dragula'
import { isEmpty, cloneDeep, without, uniq } from 'lodash-es'
import { BasePageComponent } from 'core/abstract-components'
import { TextSearchComponent, LabelFilter } from 'shared/search'
import { Folder } from 'models'
import { BaseFolderTreeService } from './base-folder-tree.service'
import { FolderFormComponent } from './folder-form.component'
import { Scroll } from 'util/scroll'
import { switchMapTo } from 'rxjs/operators'

@Component({
  template: ''
})
export abstract class BaseFolderTreePageComponent extends BasePageComponent {
  @ViewChild(FolderFormComponent, { static: true }) folderFormCmp: FolderFormComponent
  @ViewChild(TextSearchComponent, { static: true }) textSearchCmp: TextSearchComponent

  sort: string
  sortDirection: string
  searchParams: Object
  labelFilters: LabelFilter[] = []

  rootFolder: Folder
  pageFolders: Folder[] = []

  dragSubscription: Subscription
  dragendSubscription: Subscription
  dropSubscription: Subscription

  loading: boolean = false
  dragging: boolean = false
  scrollTop: number
  isTextSearchOpen: boolean = false

  constructor(
    protected elRef: ElementRef,
    protected treeService: BaseFolderTreeService,
    protected dragulaService: DragulaService
  ) {
    super()

    this.isFolderActive = this.isFolderActive.bind(this)
  }

  ngOnInit() {
    super.ngOnInit()

    this.dragulaService.createGroup('folderTree', {
      copy: true,
      mirrorContainer: this.elRef.nativeElement,
      accepts: (el, container, source, sibling) => {
        return this.folderTreeDroppable(el, container, source, sibling)
      },
      copyItem: (item: any) => ({ ...item })
    })

    this.subs.push(
      this.dragulaService.drag("folderTree").subscribe(e => this.onDrag(e)),
      this.dragulaService.dragend("folderTree").subscribe(e => this.onDragend(e)),
      this.dragulaService.drop("folderTree").subscribe(e => this.onDrop(e)),

      this.treeService.afterCreateFolder$.subscribe(f => this.afterCreateFolder(f)),
      this.treeService.afterUpdateFolder$.subscribe(e => this.afterUpdateFolder(e)),
      this.treeService.afterDestroyFolder$.subscribe(f => this.afterDestroyFolder(f))
    )

    this.isTextSearchOpen = !!this.getSearchParam('query')
  }

  ngOnDestroy() {
    super.ngOnDestroy()
    this.dragulaService.destroy('folderTree')
  }

  getSearchParam(key: string): any {
    return this.searchParams[key]
  }

  setSearchParam(key: string, value: any) {
    let params = {}
    params[key] = value
    this.setSearchParams(params)
  }

  setSearchParams(params: Object) {
    let result = this.searchParams
    let changed = false
    for (let key in params) {
      let value = params[key]
      if (value == result[key]) { continue }
      if (value == undefined || value == null) {
        delete result[key]
      } else {
        result[key] = value
      }
      changed = true
    }
    if (changed) {
      this.searchParams = result
      this.onSearchParamsChange()
    }
  }

  setSort(sort: string, sortDirection: string) {
    if (sort != this.sort || sortDirection != this.sortDirection) {
      this.sort = sort
      this.sortDirection = sortDirection
      this.onSortChange()
    }
  }

  onSearchParamsChange() {
    this.scrollTop = 0
    this.loadData()
  }

  onSortChange() {
    this.scrollTop = 0
    this.loadData()
  }

  buildFetchParams(): Object {
    let result = cloneDeep(this.searchParams)
    let labelFilters = this.labelFilters.reduce((hash, filter) => {
      hash[filter.requestKey] = filter.value
      return hash
    }, {})
    Object.assign(result, labelFilters)

    if (this.sort)          { result['sort'] = this.sort }
    if (this.sortDirection) { result['sortDirection'] = this.sortDirection }
    return result
  }

  searching(): boolean {
    return !isEmpty(this.searchParams) || !isEmpty(this.labelFilters)
  }
  
  /**
   * обновляет все дерево папок
   */
  loadData(): void {
    this.loading = true
    this.treeService.setFetchParams(this.buildFetchParams())
    this.treeService.loadData().subscribe(() => {
      setTimeout(() => {
        this.rootFolder = this.treeService.getRootFolder()
        this.touchRoot()
        Scroll.top(this.scrollTop)
        this.loading = false
      })
    },
    error => {
      this.alertService.error(error)
      this.loading = false
    })
  }

  isFolderActive(folder: Folder): boolean {
    return !this.searching() || folder.itemsCount > 0
  }

  showNewFolderForm() {
    this.folderFormCmp.showNew({ ancestry: this.rootFolder.childrenAncestry() })
  }

  touchRoot() {
    const folders = this.rootFolder.subFolders.filter((f => this.isFolderActive(f)))
    this.pageFolders = folders
  }

  afterCreateFolder(folder: Folder) {
    this.treeService.loadSubFoldersOf(folder.parentId).pipe(
      switchMapTo(this.treeService.openFolder(folder.parentId))
    ).subscribe({
      next: () => this.touchRoot()
    })
  }

  afterUpdateFolder(event: { folder: Folder, folderWas: Folder }) {
    const { folder, folderWas } = event
    const foldersToUpdate = without(uniq([folder.parentId, folderWas.parentId]), null)

    forkJoin(
      foldersToUpdate.map(folderId => this.treeService.loadSubFoldersOf(folderId))
    ).pipe(
      switchMapTo(forkJoin(
        foldersToUpdate.map(folderId => this.treeService.openFolder(folderId))
      ))
    )
    .subscribe({
      next: () => {
        this.touchRoot()
      }
    })
  }

  afterDestroyFolder(folder: Folder) {
    this.treeService.closeFolderSubtree(folder.id)
    this.treeService.loadSubFoldersOf(folder.parentId).subscribe({
      next: () => this.touchRoot()
    })
  }

  openTextSearch() {
    this.isTextSearchOpen = true
    this.textSearchCmp.setFocus()
  }

  closeTextSearch() {
    this.isTextSearchOpen = false
    this.setSearchParam('query', null)
  }

  onTextSearchKeyup(event) {
    if (event.keyCode == 27) {
      this.closeTextSearch()
    }
  }

  folderTreeDroppable(el, container, source, sibling): boolean {
    if (!el.dataset['dragFolderId'] || !container.dataset['dropFolderId']) { return false }

    return this.treeService.folderMoveable(
      +el.dataset['dragFolderId'],
      +container.dataset['dropFolderId']
    )
  }

  onDrag(e: any) {
    this.dragging = true
  }

  onDragend(e: any) {
    this.dragging = false
  }

  onDrop(event: any) {
    const { name, el, target } = event
    if (
      name != 'folderTree' ||
      !target || !target.dataset['dropFolderId'] ||
      !el.dataset['dragFolderId']
    ) { return }

    el.remove()

    const folderWas = this.treeService.findFolder(+el.dataset['dragFolderId'])
    const newParent = this.treeService.findFolder(+target.dataset['dropFolderId'])

    this.loading = true
    this.treeService.moveFolder(folderWas.id, newParent.id).subscribe({
      next: (folder) => {
        this.loading = false
        this.treeService.afterUpdateFolderSubject.next({ folder, folderWas })
      },
      error: () => {
        this.loading = false
        this.alertService.error("Не уалось переместить папку")
      }
    })
  }

  onRemoveLabelFilter(filter: LabelFilter) {
    console.log("TODO: onRemoveLabelFilter()", filter)
    // let index = this.labelFilters.indexOf(filter)
    // if (index > -1) {
    //   this.labelFilters.splice(index, 1)
    //   let queryParams = new URLSearchParams(window.location.search.slice(1))
    //   queryParams.delete(filter.key)
    //   let query = queryParams.toString()
    //   let path = query ? `${window.location.pathname}?${query}` : window.location.pathname
    //   this.location.replaceState(path)
    //   this.afterRemoveLabelFilter(filter)
    // }
  }

  afterRemoveLabelFilter(filter: LabelFilter) {
    this.loadData()
  }
}
