import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Interactive } from './interactive.model'
import { InteractiveSerializer } from 'serializers'
import { Observable } from 'rxjs'

@Injectable()
export class InteractiveService extends BaseModelService<Interactive> {
  constructor() {
    super()

    this.configure({
      basePath:  'interactives',
      key:       'interactive',
      pluralKey: 'interactives',
      serializer: InteractiveSerializer
    })
  }

  find_by_slug(slug: string, device_type: string, driver_id: null|string, lang: string = "ru"): Observable<Object> {
    let dt = `device_type=${device_type}&lang=${lang}`
    if (driver_id) {
      dt += `&driver_id=${driver_id}`
    }
    let url = [this.apiPath, this.basePath, "web", slug].join('/') + '.json?' + dt
    return this.get(url)
  }

  finish(interactive:Interactive, driver_id:string): Observable<Object> {
    let data = {
      driver_id: driver_id
    }
    let url = [this.apiPath, this.basePath, 'finish', interactive.id].join('/')
    return this.post(url, data)
  }
}
