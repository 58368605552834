import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core'
import { FormOption } from 'core'
import { isEqual, every, some, flattenDeep } from 'lodash-es'


@Component({
  selector: 'multi-select-group',
  templateUrl: './multi-select-group.component.html',
  styles: []
})

export class MultiSelectGroupComponent{
  @Input('group') group: FormOption
  @Input() level: number
  @Input('selectedValues') private selectedValues: any[] = []
  @Output('selectOption') selectGroupEmitter = new EventEmitter<FormOption>()
  @Output('deselectOption') deselectGroupEmitter = new EventEmitter<FormOption>()
  private _flatOptionsValues: any[] = []

  ngOnChanges(changes: SimpleChanges) {
    const currentFlatOpions = changes.group && 
      changes.group.currentValue && 
      this.flatOptionsValues(changes.group.currentValue.subOptions)
    
    if (currentFlatOpions && !isEqual(currentFlatOpions, this._flatOptionsValues)) {
      this._flatOptionsValues = currentFlatOpions
    }
  }

  toggleSelectGroup() {
    if (this.groupSelected()) {
      this.deselectGroupEmitter.emit(this.group)
    } else {
      this.selectGroupEmitter.emit(this.group)
    }
  }

  groupSelected() {
    const groupSelected = this.selectedValues.length && 
      every(this._flatOptionsValues, value => this.selectedValues.indexOf(value) > -1)
    return groupSelected
  }

  groupPartiallySelected() {
    const groupPartiallySelected = !this.groupSelected() && 
      some(this._flatOptionsValues, value => this.selectedValues.indexOf(value) > -1)
    return groupPartiallySelected
  }

  flatOptionsValues(options: FormOption[]): any[] {
    return flattenDeep(options.map(option => {
      if (option.group) {
        return this.flatOptionsValues(option.subOptions)
      } else {
        return option.value
      }
    }))
  }
}
