import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Organization } from './organization.model'
import { OrganizationSerializer } from 'serializers'
import { Observable } from 'rxjs'

@Injectable()
export class OrganizationService extends BaseModelService<Organization> {
  constructor() {
    super()

    this.configure({
      basePath:  'organizations',
      key:       'organization',
      pluralKey: 'organizations',
      serializer: OrganizationSerializer
    })
  }
  
  currentShortIndex(params?: Object): Observable<Object> {
    let url = this.buildUrl('current_short_index')
    return this.get(url, params)
  }
}
