import { BaseSerializer } from './base.serializer'
import { Skill } from 'models/skill.model'

export class SkillSerializer extends BaseSerializer<Skill> {
  static SERIALIZABLE_ATTRIBUTES = ['comment', 'folderId', 'name']

  static SERIALIZERS = {
  }

  static SERIALIZE_RENAME_KEYS = {
  }

  static DESERIALIZERS = {
  }

  static DESERIALIZE_RENAME_KEYS = {
  }

  static initializeObject(attributes: Object): Skill {
    return new Skill(attributes)
  }
}
