import { Injectable } from "@angular/core";
import { WebHookSerializer } from "serializers/web-hook.serializer";
import { BaseModelService } from "./base-model.service";
import { WebHook } from "./web-hook.model";

@Injectable({
  providedIn: "root"
})
export class WebHooksService extends BaseModelService<WebHook> {
  constructor() {
    super()

    this.configure({
      basePath:  'web_hooks',
      key:       'webHook',
      pluralKey: 'webHooks',
      serializer: WebHookSerializer
    })
  }
}