import { Component, Input, SimpleChanges } from '@angular/core'
import { FormOption } from 'core'
import { every } from 'lodash-es'

@Component({
  selector: 'multi-select-search-option',
  templateUrl: './multi-select-search-option.component.html'
})

export class MultiSelectSearchOptionComponent {
  @Input() option: FormOption
  @Input() level: number
  @Input() selectedValues: any
  @Input() selectOptionFn: Function
  @Input() deselectOptionFn: Function
  selected: boolean = false

  ngOnInit() {
    if(this.option['value'] == 0) {
      window['xxx'] = this
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['option'] || changes['selectedValues']) {
      this.checkSelected()
    }
  }

  toggleSelect(event) {
    if (this.selected) {
      this.deselectOptionFn(this.option)
    } else {
      this.selectOptionFn(this.option)
    }
  }

  private checkSelected() {
    this.selected = every(
      this.getSubtreeOptions(), 
      option => this.selectedValues.indexOf(option['value']) > -1
    )
  }

  private getSubtreeOptions(option: Object = this.option, result: Object[] = []): Object[] {
    if(option['value'] !== null && option['value'] !== undefined) {
      result.push(option)
    }
    if(option['subOptions']) {
      for(let subOption of option['subOptions']) {
        this.getSubtreeOptions(subOption, result)
      }
    }
    return result
  }

  trackByFn(index, item) {
    return item["group"] ? 
      item["label"] : item["value"]
  }
}
