import { BaseModel } from './base.model'
import { ProgramSection } from './program-section.model'
import { ProgramSectionTransition } from './program-section-transition.model'
import { CustomThemeModel } from "./custom-theme.model"

export interface RegistrationFormConfig {
  title?: string
  confirmButtonText?: string
  controls?: RegistrationFormConfigControl[],
  transitions?: ProgramSectionTransition[]
}

export interface RegistrationFormConfigControl {
  name: string
  type: string
  label: string
  required: boolean
}

export class Program extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    registrationFormConfig: {
      title: null,
      controls: [{
        type: '',
        name: '',
        label: '',
        required: false
      }],
      confirmButtonText: null,
    },
    sections: [],
    passLimitation: false,
    needYaIdAuth: false
  }

  uuid: string
  name: string
  comment: string
  completedSessionsCount: number
  folderId: number
  sections: ProgramSection[]
  state: string
  registrationFormConfig: RegistrationFormConfig
  webHookId: number
  redirectUrl: string
  passLimitation: boolean
  autocancelTimeout: number
  needYaIdAuth: boolean
  customTheme: CustomThemeModel

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
