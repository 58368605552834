import { BaseSerializer } from './base.serializer'
import { User } from 'models/user.model'

export class UserSerializer extends BaseSerializer<User> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'firstname', 
    'lastname',
    'organizationIds',
    'email', 
    'password', 
    'roles', 
    'permissions'
  ]
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): User {
    return new User(attributes)
  }
}
