import { Moment } from 'moment'
import { BaseModel } from './base.model'
import { User } from './user.model';

export class Group extends BaseModel {
  id: number
  name: string
  hitRate: number
  isMain: boolean
  programId: number
}

export class AbExperiment extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number
  name: string
  comment: string
  state: string
  participantsCount: number
  participantsNeeded: number
  author: User
  startedAt: Moment
  finishedAt: Moment
  salt: number
  blackList: string[]
  whiteList: string[]
  mainGroup: Group
  otherGroups: Group[]

  get authorName(): string {
    return this.author ? `${this.author.lastname} ${this.author.firstname} (${this.author.id})` : ""
  }

  get progress(): string {
    return `${this.participantsCount} / ${this.participantsNeeded}`
  }
  
  get status(): string {
    if (this.isStarted) {
      return "Запущен"
    } else if (this.isNotStarted) {
      return "Не запущен"
    } else if (this.isFinished) {
      return "Закончен"
    }
  }

  get isNotStarted(): boolean {
    return this.state == "not_started"
  }

  get isStarted(): boolean {
    return this.state == "started"
  }

  get isFinished(): boolean {
    return this.state == "finished"
  }

  get groups(): Group[] {
    return [this.mainGroup].concat(this.otherGroups)
  }
}
