import { Injectable } from '@angular/core'
import { isFunction } from 'lodash-es'
import { User } from 'models'
import { AuthenticationService } from './authentication.service'
import { Observable, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthorizationConfig } from 'config'
import { BsModalService } from 'ngx-bootstrap/modal'
import { SelectRoleModalComponent } from './select-role-modal.component'
import { SelectOrganizationModalComponent } from './select-organization-modal.component'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Router } from '@angular/router'


@Injectable({
  providedIn: "root"
})
export class AuthorizationService {
  private policies: Object = {}
  private modalRef: BsModalRef

  /**
   * Role missmatch
   */
  private roleMissMatchSubject = new Subject<{ role: string, roles: string[] }>()
  private readonly roleMissMatch$ = this.roleMissMatchSubject.asObservable()
  setRoleMissMatch(data: { role: string, roles: string[]}) {
    this.roleMissMatchSubject.next(data)
  }
  
  /**
   * Organization missmatch
   */
  private organizationMissMatchSubject = new Subject<{ id: number, name: string }>()
  private readonly organizationMissMatch$ = this.organizationMissMatchSubject.asObservable()
  setOrganizationMissMatch(data: { id: number, name: string }) {
    this.organizationMissMatchSubject.next(data)
  }

  currentUser$ = this.authentication.currentUser$
  currentUser = this.authentication.currentUser

  constructor(
    private authentication: AuthenticationService,
    private config: AuthorizationConfig,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.policies = this.config.config.policies
    this.roleMissMatch$.subscribe((data) => {
      if (!this.modalRef) {
        const { role, roles } = data
        this.modalRef = this.showModalRoleSelect(role, roles)
        this.modalRef.onHidden.subscribe(() => this.onModalHidden())
      }
    })
    this.organizationMissMatch$.subscribe((organization) => {
      if (!this.modalRef) {
        const organizationName = organization && organization.name
        this.modalRef = this.showModalOrgabizationSelect(organizationName) 
        this.modalRef.onHidden.subscribe(() => this.onModalHidden())
      }
    })
  }

  allowed(scope: string, action: string, targetObject?: any): boolean {
    const user = this.currentUser
    return this._allowed(user, scope, action, targetObject)
  }

  allowed$(scope: string, action: string, targetObject?: any): Observable<boolean> {
    return this.currentUser$.pipe(
      map(user => this._allowed(user, scope, action, targetObject))
    )
  }

  _allowed(user: User, scope: string, action: string, targetObject?: any): boolean {
    if(!user) {
      return false
    }
    else if(!this.policies[scope]) {
      console.error(`Policy scope "${ scope }" not found`)
      return false
    }
    else if(!this.policies[scope][action]) {
      console.error(`Policy "${ scope }.${ action }" not found`)
      return false
    }
    else if(!isFunction(this.policies[scope][action])) {
      console.error(`Policy "${ scope }.${ action }" is not a function`)
      return false
    } else {
      return this.policies[scope][action](user, targetObject)
    }
  }

  private touchNavigtion() {
    const currentUrl = this.router.url
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl])  
    })
  }

  private clearModalRef() { this.modalRef = null }

  private onModalHidden() {
    this.clearModalRef()
    this.touchNavigtion()
  }

  private showModalRoleSelect(role: string, roles: string[]): BsModalRef {
    const initialState = { role, roles }
    return this.modalService.show(SelectRoleModalComponent, { 
      initialState,
      class: 'modal-sm',
      keyboard: false,
      ignoreBackdropClick: true
    })
  }

  private showModalOrgabizationSelect(organizationName: string): BsModalRef {
    const initialState = { organizationName }
    return this.modalService.show(SelectOrganizationModalComponent, { 
      initialState,
      class: 'modal-sm',
      keyboard: false,
      ignoreBackdropClick: true
    })
  }
}
