import { Component, Input, Output, EventEmitter } from '@angular/core'

import { LabelFilter } from './label-filter'

@Component({
  selector: 'label-filters',
  templateUrl: './label-filters.component.html'
})

export class LabelFiltersComponent {
  @Input() filters: LabelFilter[] = []

  @Output('onRemoveFilter') onRemoveFilterEmitter = new EventEmitter<LabelFilter>()

  constructor() {}

  removeFilter(filter: LabelFilter) {
    this.onRemoveFilterEmitter.emit(filter)
  }
}
