import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  SimpleChanges,
  ElementRef,
  ViewChild
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { FormOption } from 'core'
import { cloneDeep } from 'lodash-es'

const noop = () => {}

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SingleSelectComponent),
  multi: true
}

@Component({
  selector: 'single-select',
  templateUrl: './single-select.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  }
})

export class SingleSelectComponent implements ControlValueAccessor {
  @ViewChild('dropdown', { static: true }) private dropdownElRef: ElementRef
  @Input('options') options: FormOption[] = []
  @Input('disabled') disabled: boolean = false
  @Input() private disableSearch = false
  @Output('change') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()

  private selectedOption: FormOption
  selectedValue: any
  isDropdownOpen: boolean = false
  isDropdownUp: boolean = false
  optionsSearchTerm: string = ""
  filteredOptions: FormOption[]
  showSearch: boolean = false

  constructor(
    private elRef: ElementRef
  ) {
    this.selectOption = this.selectOption.bind(this)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.filteredOptions =  this.filterOptions(this.options, "")
      this.updateSelectedOption()
      this.showSearch = !this.disableSearch && this.getFlatOptions().length >= 10
    }
  }

  // ControlValueAccessor methods

  writeValue(value: any) {
    if(value != this.selectedValue) {
      this.selectedValue = value
      this.updateSelectedOption()
    }
    this.resetSearch()
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn
  }

  private onTouchedCallback: () => void = noop

  private onChangeCallback: (_: any) => void = noop

  // END ControlValueAccessor methods

  @Input('value')

  get value(): any {
    return this.selectedValue
  }

  set value(v) {
    if (v !== this.selectedValue) {
      this.selectedValue = v
      this.onChangeCallback(v)
    }
    this.resetSearch()
  }

  onKeyup(term: string): void {
    this.filteredOptions = this.filterOptions(this.options, term)
  }


  filterOptions(options: FormOption[], term: string): FormOption[] {
    const _options = cloneDeep(options) as FormOption[]
    const _term = term.toLowerCase()

    return _options.filter(o => {
      if (o.subOptions) {
        o.subOptions = this.filterOptions(o.subOptions, _term)
        return o.subOptions.length > 0 || o.label.toLowerCase().indexOf(_term) !== -1
      } else {
        return o.label.toLowerCase().indexOf(_term) !== -1
      }
    })
  }

  private getFlatOptions(): FormOption[] {
    return this.getSubtreesOptions(this.options)
  }

  private getSubtreesOptions(options: FormOption[]): FormOption[] {
    let result = []
    for (let option of options) {
      result = result.concat(this.getSubtreeOptions(option))
    }
    return result
  }

  private getSubtreeOptions(option: FormOption, result: FormOption[] = []): FormOption[] {
    if(option.value !== null && option.value !== undefined) {
      result.push(option)
    }
    if (option.subOptions) {
      for (let subOption of option.subOptions) {
        this.getSubtreeOptions(subOption, result)
      }
    }
    return result
  }

  selectOption(option: FormOption) {
    if (option.value != this.selectedValue) {
      let previousValue = this.selectedValue
      this.selectedOption = option
      this.selectedValue = option.value
      this.onChangeCallback(option.value)
      this.onChangeEmitter.emit({ value: option.value, previousValue: previousValue })
    }
    this.closeDropdown()
  }

  onToggleClick(event) {
    if(!this.disabled) {
      this.toggleDropdown()
    }
  }

  private updateSelectedOption() {
    this.selectedOption = this.findOption(this.selectedValue)
  }

  private findOption(value: any, options: FormOption[] = this.options): FormOption {
    for (let option of options) {
      if (option.value == value && !option.group) {
        return option
      }
      else if (option.subOptions) {
        let result = this.findOption(value, option.subOptions)
        if (result) { return result }
      }
    }
    return null
  }

  private onDocumentClick(event) {
    if (this.isDropdownOpen && !this.elRef.nativeElement.contains(event.target)) {
      this.closeDropdown()
    }
  }

  private toggleDropdown() {
    if (this.isDropdownOpen) {
      this.closeDropdown()
    } else {
      this.openDropdown()
    }
  }

  private closeDropdown() {
    this.isDropdownOpen = false
    this.onTouchedCallback()
    this.resetSearch()
  }

  private openDropdown() {
    this.isDropdownOpen = true
    setTimeout(() => {
      this.updateDropdownDirection()
    })
  }

  trackByFn(index, item) {
    return item["group"] ? 
      item["label"] : item["value"]
  }

  resetSearch(): void {
    this.filteredOptions = this.filterOptions(this.options, "")
    this.optionsSearchTerm = ""
  }

  private updateDropdownDirection() {
    let wHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    let drRect = this.dropdownElRef.nativeElement.getBoundingClientRect()
    let elRect = this.elRef.nativeElement.getBoundingClientRect()
    let drHeight = drRect.bottom - drRect.top
    this.isDropdownUp = (wHeight - elRect.bottom < drHeight) && elRect.top >= drHeight
  }
  
  caption(): string {
    return this.selectedOption ? this.selectedOption['label'] : null
  }
}
