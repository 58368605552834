import { BaseModel } from './base.model'

export class InteractiveContentItemTransitionSkill extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  _destroy: boolean
  id: number
  interactiveContentItemTransitionId: number
  skillId: number
  pointsCount: number
}
