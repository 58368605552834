import { Component, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'read-more',
  styles: [`
    .read-more-wrap {
      position: relative;
    }

    .content-container {
      overflow: hidden;
    }

    .content-container--collapse-enabled {
      margin-bottom: 25px;
    }

    .collapse-mode-toggle {
      display: block;
      position: absolute;
      right: 0;
      bottom: -20px;
    }
  `],
  template: `
    <div class="read-more-wrap">
      <div class="content-container" [style.height]="styleMaxHeight" [ngClass]="{
        'content-container--collapse-enabled': enabled
      }">
        <div #contentContainer class="content">
          <ng-content></ng-content>
        </div>
      </div>

      <a class="collapse-mode-toggle" *ngIf="enabled" href="#" (click)="toggleCollapseMode($event)">{{ toggleLinkText }}</a>
    </div>
  `
})
export class ReadMoreComponent {
  @ViewChild('contentContainer', { static: true }) private contentContainerElRef: ElementRef
  @Input('maxHeight') maxHeight: number = 95
  
  isCollapsed: boolean = true
  enabled: boolean = true

  ngDoCheck() {
    this.enabled = this.contentContainerElRef.nativeElement.offsetHeight > this.maxHeight
  }

  toggleCollapseMode(e): void {
    e.preventDefault()
    this.isCollapsed = !this.isCollapsed
  }

  get styleMaxHeight(): string {
    return this.enabled && this.isCollapsed ? this.maxHeight + 'px' : 'auto'
  }

  get toggleLinkText(): string {
    return this.isCollapsed ? 'Показать' : 'Скрыть'
  }
}