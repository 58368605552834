import { Injectable } from '@angular/core'
import { isObject, isArray } from 'lodash-es'

import { APP_INJECTOR } from 'app.module'
import { KeyTransformer } from 'util/key-transformer'
import { Constants } from 'config'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'


export interface ListMeta {
  totalCount?: number
  perPage?: number
}

interface ISerializer{
  serialize<T>(obj: T|Object): Object;

  deserialize<T>(attributes: Object): T;
}

@Injectable()
export abstract class BaseModelService<T> {
  protected appHttp: HttpClient
  protected apiHost: string = Constants.API.HOST
  protected apiPath: string = Constants.API.PATH
  protected basePath: string
  protected key: string
  protected pluralKey: string
  protected serializer: ISerializer
  
  constructor() {
    this.appHttp = APP_INJECTOR.get(HttpClient)
  }

  buildUrl(...params: any[]): string {
    return [this.apiHost, this.apiPath, this.basePath, ...params].join('/') + '.json'
  }

  configure(config: Object) {
    Object.assign(this, config)
  }

  get(url: string, search: Object={}): Observable<Object> {
    const params = this.buildUrlSearchParams(search)
    return this.appHttp.get(url, { params }).pipe(
      map(response => this.processResponse(response))
    )
  }

  post(url: string, obj?: T|Object, search: Object={}): Observable<Object> {
    const body = obj && {
      [this.objectKey()]: this.serialize<T>(obj)
    }
    const params = this.buildUrlSearchParams(search)

    return this.appHttp.post(url, body, { params }).pipe(
      map(response => this.processResponse(response))
    )
  }

  patch(url: string, obj?: T|Object, search: Object={}): Observable<Object> {
    const body = obj && {
      [this.objectKey()]: this.serialize<T>(obj)
    }
    const params = this.buildUrlSearchParams(search)
    return this.appHttp.patch(url, body, { params }).pipe(
      map((response) => this.processResponse(response))
    )
  }

  delete(url: string, search: Object={}): Observable<Object> {
    const params = this.buildUrlSearchParams(search)
    return this.appHttp.delete(url, { params }).pipe(
      map((response) => this.processResponse(response))
    )
  }

  index(params?: Object): Observable<Object> {
    let url = this.buildUrl()
    return this.get(url, params)
  }

  shortIndex(params?: Object): Observable<Object> {
    let url = this.buildUrl('short_index')
    return this.get(url, params)
  }

  find(id: number|string): Observable<Object> {
    let url = this.buildUrl(id)
    return this.get(url)
  }

  save(obj: T|Object): Observable<Object> {
    if (obj['id']) {
      return this.update(obj)
    } else {
      return this.create(obj)
    }
  }

  create(obj: T|Object): Observable<Object> {
    let url = this.buildUrl()
    return this.post(url, obj)
  }

  update(obj: T|Object): Observable<Object> {
    console.log(obj)
    let url = this.buildUrl(obj['id'])
    return this.patch(url, obj)
  }

  destroy(obj: T, params?: Object): Observable<Object> {
    let url = this.buildUrl(obj['id'])
    return this.delete(url, params)
  }

  restore(obj: T): Observable<Object> {
    let url = this.buildUrl(obj['id'], 'restore')
    return this.patch(url)
  }

  disable(obj: T): Observable<Object> {
    let url = this.buildUrl(obj['id'], 'disable')
    return this.patch(url)
  }

  buildUrlSearchParams(rawParams: Object): HttpParams {
    const params = KeyTransformer.underscore(rawParams)
    const result = new URLSearchParams()
    this.appendUrlSearchParams(result, params)

    return new HttpParams({ fromString: result.toString() })
  }

  protected processResponse(rawData: Object): Object {
    const data = KeyTransformer.camelize(rawData)
    let result = {}
    for (let k in data) {
      if (k == this.key) {
        result[k] = this.deserialize(data[k])
      }
      else if (k == this.pluralKey) {
        result[k] = data[k].map((attributes) => { return this.deserialize(attributes) })
      } else {
        result[k] = data[k]
      }
    }
    return result
  }

  protected serialize<T>(obj?: T|Object): Object {
    return this.serializer.serialize<T>(obj)
  }

  protected deserialize(attributes: Object): T {
    return this.serializer.deserialize<T>(attributes)
  }

  protected appendUrlSearchParams(target: URLSearchParams, params: Object, keyPrefix: string = null) {
    for (let key in params) {
      let pKey = keyPrefix ? `${keyPrefix}[${key}]` : key
      let value = params[key]
      if (isArray(value)) {
        if (value.length > 0) {
          target.append(pKey, value.join(','))
        } else {
          target.append(`${pKey}[]`, '')
        }
      }
      else if (isObject(value)) {
        this.appendUrlSearchParams(target, value, pKey)
      } 
      else {
        target.append(pKey, value.toString())
      }
    }
  }

  protected objectKey(){
    return KeyTransformer.underscoreKey(this.key)
  }
}
