import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { ApiKey } from './api-key.model'
import { BaseSerializer } from 'serializers'
import { Observable } from 'rxjs'

export class ApiKeySerializer extends BaseSerializer<ApiKey> {
  static SERIALIZABLE_ATTRIBUTES = [
    'label', 
    'status', 
    'userFullname',
    'organizationName',
    'uuid'
  ]

  static initializeObject(attributes: Partial<ApiKey>): ApiKey {
    return new ApiKey(attributes)
  }
}

@Injectable()
export class ApiKeyService extends BaseModelService<ApiKey> {
  constructor() {
    super()
    this.configure({
      basePath:  'api_keys',
      key:       'apiKey',
      pluralKey: 'apiKeys',
      serializer: ApiKeySerializer
    })
  }

  disable(apiKey: ApiKey): Observable<Object> {
    const url = this.buildUrl(apiKey.id, 'disable')
    return this.patch(url)
  }

  restart(apiKey: ApiKey): Observable<Object> {
    const url = this.buildUrl(apiKey.id, 'restart')
    return this.patch(url)
  }

  remove(apiKey: ApiKey): Observable<Object> {
    const url = this.buildUrl(apiKey.id, 'remove')
    return this.patch(url)
  }
}
