import { TranslateService } from '@ngx-translate/core'

import { APP_INJECTOR } from 'app.module'
import { BaseDecorator } from './base.decorator'
import { User } from 'models'

export class UserDecorator extends BaseDecorator {
  protected translateService: TranslateService

  constructor(protected object: User) {
    super(object)
    this.translateService = APP_INJECTOR.get(TranslateService)
  }

  fullname(): string {
    return this.object.lastname + ' ' + this.object.firstname
  }

  role(): string {
    return this.object.role ? this.translateService.instant('users.roles.' + this.object.role) : null
  }

  roles(): string {
    return this.object.roles ? this.object.roles.map(role => {
      return this.translateService.instant('users.roles.' + role)
    }).join(", ") : null
  }

  roleDecorator(role:string): string {
    return this.translateService.instant('users.roles.' + role)
  }

  organizationName(): string {
    return this.object.organization && this.object.organization.name 
  }
}