import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { SharedModule } from "shared";
import { AuthenticationService } from "./authentication.service";
import { AuthorizationService } from "./authorization.service";
import { SelectRoleModalComponent } from "./select-role-modal.component";
import { SelectOrganizationModalComponent } from "./select-organization-modal.component";


@NgModule({
  imports: [SharedModule],
  declarations: [
    SelectRoleModalComponent,
    SelectOrganizationModalComponent
  ],
  exports: []
})
export class AuthModule {
  constructor (@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }

  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthenticationService,
        AuthorizationService
      ]
    }
  }
}