import { Directive, Input } from '@angular/core'
import { TemplateRef, ViewContainerRef } from '@angular/core'
import { AuthorizationService } from 'core/auth/authorization.service'
import { Subscription } from 'rxjs'

@Directive({ selector: '[ifAllowed]' })
export class IfAllowedDirective {
  private prevAllowed: boolean = null
  @Input() ifAllowed: any[]
  private subs: Subscription[] = []

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorization: AuthorizationService
  ) { }

  ngOnInit() {
    const [scope, action, targetObject] = this.ifAllowed
    this.subs.push(
      this.authorization.allowed$(scope, action, targetObject).subscribe(allowed => {
        if (allowed && (this.prevAllowed === null || !this.prevAllowed)) {
          this.prevAllowed = true
          this.viewContainer.createEmbeddedView(this.templateRef)
        }
        else if (!allowed && (this.prevAllowed === null || this.prevAllowed)) {
          this.prevAllowed = false
          this.viewContainer.clear()
        }
      })
    )
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}