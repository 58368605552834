import { BaseSerializer } from './base.serializer'
import { ProgramSectionTransitionCondition } from 'models'

export class ProgramSectionTransitionConditionSerializer extends BaseSerializer<ProgramSectionTransitionCondition> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'variableName',
    'variableType', 
    'conditionType', 
    'conditionValue',
    '_destroy', 
  ]
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): ProgramSectionTransitionCondition {
    return new ProgramSectionTransitionCondition(attributes)
  }
}
