import { Pipe, PipeTransform } from '@angular/core'

@Pipe({name: 'truncate', pure: false})
export class TruncatePipe implements PipeTransform {
  constructor() {}

  transform(value: string, count: number): string {
    if(!value || value.length <= count) {
      return value
    } else {
      return value.substring(0, count) + '...'
    }
  }
}