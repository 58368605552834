import { Dictionary } from "models"
import { BaseSerializer } from "serializers/base.serializer"
import { DictionaryItemSerializer } from "./dictionary-item.serializer"


export class DictionarySerializer extends BaseSerializer<Dictionary> {
  static SERIALIZABLE_ATTRIBUTES = [
    "id",
    "name",
    "label",
    "comment",
    "items",
    "variableType"
  ]
  static SERIALIZERS = {
    items: DictionaryItemSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    items: "itemsAttributes"
  }

  static DESERIALIZERS = {
    items: DictionaryItemSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<Dictionary>): Dictionary {
    return new Dictionary(attributes)
  }
}
