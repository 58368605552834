import { DictionaryItem } from "models"
import { BaseSerializer } from "serializers/base.serializer"


export class DictionaryItemSerializer extends BaseSerializer<DictionaryItem> {
  static SERIALIZABLE_ATTRIBUTES = [
    "id",
    "label",
    "state"
  ]
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): DictionaryItem {
    return new DictionaryItem(attributes)
  }
}
