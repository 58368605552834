import { Component, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'drop',
  host: {
    '(document:click)': 'onDocumentClick($event)'
  },
  styleUrls: ['drop.component.sass'],
  template: `
    <div class="{{ dropClass() }}"
      [ngClass]="{'open' : open}">
      <a class="drop__toggle" href="#" (click)="toggleDropdown($event)">
        <span class="drop__toggle-caption">{{ caption }}</span>
        <i class="fa drop__toggle-caret"
          *ngIf="hasDrop"
          [ngClass]="{
            'fa-caret-up' : open,
            'fa-caret-down': !open
          }"></i>
      </a>

      <div
        #content
        class="drop__content"
        [ngClass]="{ 'up': isDropdownUp, 'left': isDropdownLeft, 'right': !isDropdownLeft }"
      >
        <ng-content *ngIf="open"></ng-content>
      </div>
    </div>
  `
})

export class DropComponent {
  @ViewChild('content', { static: true }) private contentElRef: ElementRef
  @Input('caption') public caption: string
  @Input('modificator') public modificator: string|null = null
  @Input('hasDrop') public hasDrop: boolean = true

  @Output('open') private onOpenEmitter: EventEmitter<Object> = new EventEmitter<Object>()
  @Output('close') private onCloseEmitter: EventEmitter<Object> = new EventEmitter<Object>()

  public open: boolean = false
  public isDropdownUp: boolean = false
  public isDropdownLeft: boolean = false

  constructor(private hellRef: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      this.updateDropdownDirection()
    })
  }

  public toggleDropdown(e:Event):void {
    e.preventDefault()
    if(this.open) {
      this.closeDropdown()
    } else {
      this.openDropdown()
    }
  }

  public openDropdown():void {
    this.open = true
    setTimeout(() => {
      this.updateDropdownDirection()
    })
    this.onOpenEmitter.emit(true)
  }

  public closeDropdown():void {
    this.open = false
    this.onCloseEmitter.emit(true)
  }

  public dropClass():string {
    return ["drop", this.modificator].join(" ")
  }

  private onDocumentClick(event: Event) {
    if (this.open && !this.hellRef.nativeElement.contains(event.target)) {
      this.closeDropdown()
    }
  }

  private updateDropdownDirection() {
    let wHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    let wWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let drRect = this.contentElRef.nativeElement.getBoundingClientRect()
    let elRect = this.hellRef.nativeElement.getBoundingClientRect()
    let drHeight = drRect.bottom - drRect.top
    let drWidth = drRect.right - drRect.left
    this.isDropdownUp = (wHeight - elRect.bottom < drHeight) && elRect.top >= drHeight
    this.isDropdownLeft = (wWidth - elRect.right < drWidth) && elRect.right >= drWidth
  }
}
