import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { ReportSerializer } from 'serializers'
import { Report } from './report.model'

export const SESSIONS_PASSED_ATTEMPTS_COUNT = "Sessions::PassedAttemptsCountReport"
export const SESSIONS_PASSED_STATUS = "Sessions::PassedStatusReport"
export const ONLINE_SESSIONS_PASSED_ATTEMPTS_COUNT = "OnlineSessions::PassedAttemptsCountReport"
export const ONLINE_SESSIONS_PASSED_STATUS = "OnlineSessions::PassedStatusReport"
export const ACCESSORS_ACCESSOR_HOURS = "Accessors::AccessorHoursReport"
export const ACCESSORS_ACCESSOR_DAYS = "Accessors::AccessorDaysReport"
export const EXAMS_QUESTIONS_STATISTICS = "Exams::QuestionsStatisticReport"
export const EXAM_SESSION = "Exams::SessionsReport"
export const EXAM_SECTION = "Exams::SectionReport"
export const ACCESSORS_ACCESSOR_ERRORS = "Accessors::AccessorErrorsReport"
export const ACCESSORS_METRIC_BRIEF = "Accessors::MetricBriefReport"
export const CENTERS_CENTERS = "Centers::CentersReport"
export const HUBS_HUB_REGISTRATION = "Hubs::HubRegistrationReport"
export const MODERATORS_MODERATOR_CHECKS = "Moderators::ModeratorChecksReport"
export const ONLINE_INTERVIEWS_ONLINE_INTERVIEW = "OnlineInterviews::OnlineInterviewReport"
export const ONLINE_INTERVIEWS_ONLINE_INTERVIEW_USER = "OnlineInterviews::OnlineInterviewUserReport"
export const SESSIONS_SESSION_STATISTICS = "Sessions::SessionStatisticsReport";

@Injectable()
export class ReportService extends BaseModelService<Report> {
  constructor() {
    super()

    this.configure({
      basePath:  'reports',
      key:       'report',
      pluralKey: 'reports',
      serializer: ReportSerializer
    })
  }
}
