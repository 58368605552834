import { BaseModel } from './base.model'

export class InteractiveSkillGrade extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
  }

  id: number
  interactiveSkillId: number
  grade: number
  pointsCountTreshold: number
  minPointsCountTreshold: number
  _destroy: boolean

}
