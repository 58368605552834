import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { KeyTransformer } from 'util/key-transformer'
import { BaseModelService } from './base-model.service'

@Injectable()
export class SettingService extends BaseModelService<Object> {
  constructor() {
    super()

    this.configure({
      basePath:  'settings',
      key:       'setting',
      pluralKey: 'settings',
      serializer: null
    })
  }

  index(): Observable<Object> {
    let url = this.buildUrl()
    return this.appHttp.get(url).pipe(
      map(response => this.processResponse(response))
    )
  }

  update(settings: Object): Observable<Object> {
    let url = this.buildUrl()
    let params = {}
    params[this.pluralKey] = KeyTransformer.underscore(settings)
    let body = JSON.stringify(params)
    return this.appHttp.patch(url, body).pipe(
      map(response => this.processResponse(response))
    )
  }

  protected processResponse(response: Object): Object {
    return KeyTransformer.camelize(response)
  }
}
