import { BaseSerializer } from './base.serializer'
import { InteractiveContentItemTransitionSkill } from 'models/interactive-content-item-transition-skill.model'

export class InteractiveContentItemTransitionSkillSerializer extends BaseSerializer<InteractiveContentItemTransitionSkill> {
  static SERIALIZABLE_ATTRIBUTES = ['_destroy', 'id', 'interactiveContentItemTransitionId', 'skillId', 'pointsCount']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {
  }

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): InteractiveContentItemTransitionSkill {
    return new InteractiveContentItemTransitionSkill(attributes)
  }
}
