import { BaseSerializer } from './base.serializer'
import { AnswerOption } from 'models/answer-option.model'

export class AnswerOptionSerializer extends BaseSerializer<AnswerOption> {
  static SERIALIZABLE_ATTRIBUTES = ['_remove', 'correct', 'hint', 'id', 'name', 'position']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): AnswerOption {
    return new AnswerOption(attributes)
  }
}
