import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'sort-link',
  template: `
    <a
      [ngClass]="[linkClass, currentSortDirection, (currentSort == sort ? 'active' : '')]"
      (click)="change()"
    >
      <span>{{ text }}</span>
      <i class="fa" *ngIf="sort == currentSort" [ngClass]="{
        'fa-angle-up': 'currentSortDirection' == 'asc',
        'fa-angle-down': 'currentSortDirection' != 'asc'
      }"></i>
    </a>
  `
})

export class SortLinkComponent {
  @Input() currentSort: string
  @Input() currentSortDirection: string
  @Input() linkClass: string = ''
  @Input() sort: string
  @Input() text: string

  @Output('onChange') onChangeEmitter = new EventEmitter<Object>()

  change() {
    if(this.currentSort == this.sort) {
      let direction = this.currentSortDirection == 'asc' ? 'desc' : 'asc'
      this.onChangeEmitter.emit({
        sort: this.currentSort,
        sortDirection: direction
      })
    } else {
      this.onChangeEmitter.emit({
        sort: this.sort,
        sortDirection: this.currentSortDirection
      })
    }
  }
}
