import { BaseSerializer } from './base.serializer'
import { InteractiveContentItem } from 'models/interactive-content-item.model'
import { InteractiveContentItemTransitionSerializer } from './interactive-content-item-transition.serializer'

export class InteractiveContentItemSerializer extends BaseSerializer<InteractiveContentItem> {
  static SERIALIZABLE_ATTRIBUTES = [
    '_destroy', 'sortOrder', 'id', 'url', 'name', 'comment', 'endTransition',
    'buttonTransitions', 'repeatsCount', 'customPosterUrl'
  ]
  static SERIALIZERS = {
    endTransition: InteractiveContentItemTransitionSerializer,
    buttonTransitions: InteractiveContentItemTransitionSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    url: 'uploadFileUrl',
    customPosterUrl: 'uploadCustomPosterUrl',
    endTransition: 'endTransitionAttributes',
    buttonTransitions: 'buttonTransitionsAttributes'
  }

  static DESERIALIZERS = {
    endTransition: InteractiveContentItemTransitionSerializer,
    buttonTransitions: InteractiveContentItemTransitionSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): InteractiveContentItem {
    return new InteractiveContentItem(attributes)
  }
}
