import { TranslateService } from '@ngx-translate/core'
import { APP_INJECTOR } from 'app.module'
import { BaseDecorator } from './base.decorator'
import { Question } from 'models'

export class QuestionDecorator extends BaseDecorator {
  private translateService: TranslateService

  constructor(protected object: Question) {
    super(object)

    this.translateService = APP_INJECTOR.get(TranslateService)
  }

  type(): string {
    return this.object.type ? this.translateService.instant('questions.types.' + this.object.type) : null
  }

  answerOptionsCount(): string {
    if (!this.object.answerOptionsCount && this.object.answerOptionsCount != 0) {
      return null
    }
    else if(this.object.type == 'select' || this.object.type == 'multiselect') {
      return this.object.answerOptionsCount.toString()
    } else {
      return null
    }
  }

  correctAnswerOptionsCount(): string {
    if (this.object.type == 'select') {
      return '1'
    }
    else if(this.object.type == 'multiselect' && this.object.correctAnswerValue instanceof Array) {
      return this.object.correctAnswerValue.length.toString()
    } else {
      return null
    }
  }
}