import { WebHook, WebHookHeader } from "models"
import { TimeSerializer } from "./attributes"
import { BaseSerializer } from "./base.serializer"

export class WebHookHeaderSerializer extends BaseSerializer<WebHookHeader> {
  static SERIALIZABLE_ATTRIBUTES = ['name', 'value']

  static initializeObject(attributes: Partial<WebHookHeader>): WebHookHeader {
    const { name, value } = attributes
    return { name, value }
  }
}

export class WebHookSerializer extends BaseSerializer<WebHook> {
  static SERIALIZABLE_ATTRIBUTES = [
    'url', 
    'name', 
    'comment',
    'secret',
    'headers'
  ]
  static SERIALIZERS = {
    'headers': WebHookHeaderSerializer
  }
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    'headers': WebHookHeaderSerializer,
    'createdAt': TimeSerializer,
    'updatedAt': TimeSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<WebHook>): WebHook {
    return new WebHook(attributes)
  }
}