import { BaseSerializer } from './base.serializer'
import { Video } from 'models/video.model'
import { TimeSerializer } from './attributes'
import { UserSerializer } from './user.serializer'

export class VideoSerializer extends BaseSerializer<Video> {
  static SERIALIZABLE_ATTRIBUTES = ['comment', 'folderId', 'name', 'uploadFileUrl', 'fallbackLessonId']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    createdAt: TimeSerializer,
    updatedAt: TimeSerializer,
    createdBy: UserSerializer,
    updatedBy: UserSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Video {
    return new Video(attributes)
  }
}
