import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { BaseModelService } from './base-model.service'
import { Video } from './video.model'
import { VideoSerializer } from 'serializers'
import { FormOptionsBuilder, FormOption } from 'core/forms'
import { Folder } from './folder.model'
import { FolderService } from './folder.service'
import { map, switchMap } from 'rxjs/operators'

@Injectable()
export class VideoService extends BaseModelService<Video> {
  constructor(
    private folderService: FolderService
  ) {
    super()

    this.configure({
      basePath:  'videos',
      key:       'video',
      pluralKey: 'videos',
      serializer: VideoSerializer
    })
  }

  loadVideoOptions(attr: string = 'id'): Observable<FormOption[]> {
    return this.folderService.videosTree().pipe(
      switchMap(folderData => {
        const folder = folderData['folder'] as Folder
        return this.shortIndex({ folderSubtreeIds: folder.id }).pipe(
          map(videoData => FormOptionsBuilder.folderItemOptions(
            folder.subFolders, 
            videoData['videos'], 
            attr
          ))
        )
      })
    )
   }
}
