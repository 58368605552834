import { HttpClient, HttpParams } from '@angular/common/http'
import { FormControl } from '@angular/forms'
import { Constants } from 'config'
import { of, Observable, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, mergeMap, share, switchMap, take } from 'rxjs/operators'

export class RemoteValidator {
  private validatorInput: Subject<string>
  private validatorObservable: Observable<any>
  protected apiHost: string = Constants.API.HOST

  constructor(
    private http: HttpClient,
    private url: string,
    private errorKey: string,
    private requestData: Object|Function = {},
    private minValueLength: number = 1,
    private _debounceTime: number = 600
  ) {
    this.validate = this.validate.bind(this)

    this.validatorInput = new Subject()
    this.validatorObservable = this.validatorInput.pipe(
      debounceTime(this._debounceTime),
      distinctUntilChanged(),
      switchMap(value => {
        let data = (this.requestData instanceof Function) ? this.requestData() : this.requestData
        const params = new HttpParams({
          fromObject: { ...data, value }
        })
        return this.http.get(this.buildUrl(), { params }).pipe(
          mergeMap((response) => {
            let result = response['valid'] ? null : { [this.errorKey]: true }
            return of(result)
          }),
          catchError(() => of(null))
        )
      }),
      share(),
      take(1)
    )
  }

  validate(control: FormControl): Promise<any> {
    if(!control.value || control.value.length < this.minValueLength) {
      return null
    } else {
      setTimeout(() => this.validatorInput.next(control.value), 0)
      return this.validatorObservable.toPromise()
    }
  }

  private buildUrl(): string {
    return [this.apiHost, this.url].join("/")
  }
}
