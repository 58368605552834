import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Comment } from './comment.model'
import { CommentSerializer } from 'serializers'

@Injectable()
export class CommentService extends BaseModelService<Comment> {
  constructor() {
    super()

    this.configure({
      basePath:  'comments',
      key:       'comment',
      pluralKey: 'comments',
      serializer: CommentSerializer
    })
  }
}
