import { ViewChild, Component } from '@angular/core'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { Subscription } from 'rxjs'

import { BaseFormComponent } from './base-form.component'


@Component({
  template: ''
})
export abstract class BaseModalFormComponent extends BaseFormComponent {
  @ViewChild('modal', { static: false }) modal: ModalDirective
  // @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  modalShowSubscription: Subscription
  modalShownSubscription: Subscription
  modalHideSubscription: Subscription
  modalHiddenSubscription: Subscription

  constructor() {
    super()
  }

  ngOnInit() {
    super.ngOnInit()
  }

  ngAfterViewInit() {
    this.modalShowSubscription   = this.modal.onShow.subscribe(event => this.onModalShow())
    this.modalShownSubscription  = this.modal.onShown.subscribe(event => this.onModalShown())
    this.modalHideSubscription   = this.modal.onHide.subscribe(event => this.onModalHide())
    this.modalHiddenSubscription = this.modal.onHidden.subscribe(event => this.modalHiddenWrapper())
  }

  ngOnDestroy() {
    super.ngOnDestroy()

    this.modalShowSubscription.unsubscribe()
    this.modalShownSubscription.unsubscribe()
    this.modalHideSubscription.unsubscribe()
    this.modalHiddenSubscription.unsubscribe()
  }

  abstract showNew()

  abstract showEdit(id: number|any)

  hide() {
    this.modal.hide()
  }

  onModalShow() {}

  onModalShown() {}

  onModalHide() {}

  onModalHidden() {
    this.form = null
    this.loading = false
  }

  private modalHiddenWrapper(){
    setTimeout(() => {
      let modlaBackDrops = document.querySelectorAll("bs-modal-backdrop")
      if (modlaBackDrops.length > 0) {
        let body = document.querySelector("body")
        body.classList.add("modal-open")
      }
    }, 0)
    this.onModalHidden()
  }
}
