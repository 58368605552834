import { BaseModel } from './base.model'

export class SessionInteractiveSkillGrade extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number
  grade: number
  name: string
  pointsCount: number
  weight: number
}
