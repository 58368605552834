import { FormControl, ValidatorFn } from '@angular/forms'

export function minValidator(min: number, message: string = ""): ValidatorFn {
  return (c: FormControl): Object => {
    if (!c.value && c.value != 0) { return null }
    let value = parseFloat(c.value)
    
    if ((value || value == 0) && value < min) {
      if (message.length == 0) {
        message = `Минимальное значение: ${min}`
      } else {
        message = message
          .replace("{requiredMin}", min.toString())
          .replace("{actualValue}", value.toString())
      }
      return { 
        min: { 
          requiredMin: min, 
          actualValue: value,
          message
        } 
      }
    }
    else if(!value && value != 0) {
      return { invalid: true }
    } else {
      return null
    }
  }
}
