import { BaseSerializer } from './base.serializer'
import { SessionVersion } from 'models/session-version.model'
import { SessionSerializer } from './session.serializer'
import { TimeSerializer } from './attributes'

export class SessionVersionSerializer extends BaseSerializer<SessionVersion> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}
  static DESERIALIZERS = {
    createdAt: TimeSerializer,
    session: SessionSerializer,
    sessionBefore: SessionSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): SessionVersion {
    return new SessionVersion(attributes)
  }
}
