export const PLURALIZATION_RULES = {
  ru: function(n: number): string {
    if(n == 0) {
      return 'zero'
    }
    else if(n % 10 == 1 && n % 100 != 11) {
      return 'one'
    }
    else if([2, 3, 4].indexOf(n % 10) >= 0 && [12, 13, 14].indexOf(n % 100) < 0) {
      return 'few'
    }
    else if(n % 10 == 0 || [5, 6, 7, 8, 9].indexOf(n % 10) >= 0 || [11, 12, 13, 14].indexOf(n % 100) >= 0) {
      return 'many'
    } else {
      'other'
    }
  }
}
