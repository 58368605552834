import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { StaticScreen } from './static-screen.model'
import { StaticScreenSerializer } from 'serializers'
import { Observable } from 'rxjs'

@Injectable()
export class StaticScreenService extends BaseModelService<StaticScreen> {
  constructor() {
    super()

    this.configure({
      basePath:  'static_screens',
      key:       'staticScreen',
      pluralKey: 'staticScreens',
      serializer: StaticScreenSerializer
    })
  }

  saveTranslations(id: number, translations: Object): Observable<Object> {
    let url = this.buildUrl(id)
    return this.patch(url, translations)
  }
}
