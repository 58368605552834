import { Injectable } from '@angular/core'

@Injectable()
export class Body {
  addClass(className: string) {
    if(document.body.className.indexOf(className) < 0) {
      document.body.className += (' ' + className)
    }
  }

  removeClass(className: string) {
    let regexp = new RegExp('(?:^|\\s)'+ className + '(?:\\s|$)', 'g')
    document.body.className = document.body.className.replace(regexp, ' ')
  }
}
