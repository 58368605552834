import { BaseSerializer } from 'serializers'
import { ProgramSection } from 'models/program-section.model'
import { ExamSerializer } from "serializers"
import { StaticScreenSerializer } from "serializers"
import { VideoSerializer } from "serializers"
import { ProgramSectionTransitionSerializer } from 'serializers'
import { SystemScreenSerializer } from 'serializers'
import { QuizSerializer } from 'serializers'
import { InteractiveSerializer } from 'serializers'
import { KioskFormSerializer } from 'models/kiosk-forms/kiosk-form.serializer'

export class ProgramSectionSerializer extends BaseSerializer<ProgramSection> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'uuid',
    'position',
    'contentId', 
    'type',
    'transitions',
    'completeEducation',
    '_destroy', 
  ]
  static SERIALIZERS = {
    transitions: ProgramSectionTransitionSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    transitions: 'transitionsAttributes',
  }

  static DESERIALIZERS = {
    transitions: ProgramSectionTransitionSerializer,
    content: {
      deserialize(attributes) {
        const type = attributes["type"] as string
        if (type == "system_screen") {
          return SystemScreenSerializer.deserialize(attributes)
        } else if (type == "static_screen") {
          return StaticScreenSerializer.deserialize(attributes)
        } else if (type == "video") {
          return VideoSerializer.deserialize(attributes)
        } else if (type == "lesson_quiz") {
          return QuizSerializer.deserialize(attributes)
        } else if (type == "exam") {
          return ExamSerializer.deserialize(attributes)
        } else if (type == "interactive") {
          return InteractiveSerializer.deserialize(attributes)
        } else if (type == "form") {
          return KioskFormSerializer.deserialize(attributes)
        }
      }
    }
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): ProgramSection {
    return new ProgramSection(attributes)
  }
}
