import { APP_INJECTOR } from 'app.module'
import { AlertService } from 'core/alert'
import { Component } from "@angular/core"
import { ConfirmService } from 'core/confirm/confirm.service'
import { AuthorizationService } from 'core/auth/authorization.service'
import { AuthenticationService } from 'core/auth/authentication.service'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

@Component({
  template: ''
})
export abstract class BaseComponent {
  protected alertService: AlertService
  protected confirmService: ConfirmService
  protected authorization: AuthorizationService
  protected authentication: AuthenticationService
  protected translate: TranslateService

  protected subs: Subscription[] = []

  constructor() {
    this.alertService = APP_INJECTOR.get(AlertService)
    this.confirmService = APP_INJECTOR.get(ConfirmService)
    this.authorization = APP_INJECTOR.get(AuthorizationService)
    this.authentication = APP_INJECTOR.get(AuthenticationService)
    this.translate = APP_INJECTOR.get(TranslateService)
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  allowed$(scope: string, action: string, targetObject?: any) {
    return this.authorization.allowed$(scope, action, targetObject)
  }
}
