import { Moment } from "moment";
import { BaseModel } from "./base.model";

export interface WebHookHeader {
  name: string
  value: string
}

export class WebHook extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    headers: []
  }

  name: string
  comment: string
  headers: WebHookHeader[]
  url: string
  state: string
  secret: string
  createdAt: Moment
  updatedAt: Moment

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  static generateSecret(len: number = 16) {
    const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let randomString = ''
    for (let i = 0; i < len; i++) {
      const p = Math.floor(Math.random() * charSet.length)
      randomString += charSet.substring(p, p + 1)
    }
    return randomString
  }
}