import { BaseSerializer } from './base.serializer'
import { City } from 'models/city.model'

export class CitySerializer extends BaseSerializer<City> {
  static SERIALIZABLE_ATTRIBUTES = ['countryId', 'name', 'utcOffset']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): City {
    return new City(attributes)
  }
}
