import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Program } from './program.model'
import { ProgramSerializer } from 'serializers'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class ProgramService extends BaseModelService<Program> {
  constructor() {
    super()

    this.configure({
      basePath:  'programs',
      key:       'program',
      pluralKey: 'programs',
      serializer: ProgramSerializer
    })
  }
}
