import { FormGroup, ValidationErrors } from '@angular/forms'
import * as moment from 'moment'

export class PeriodValidator {
  constructor(private from: string, private to: string, private format: string = null) {
    this.validate = this.validate.bind(this)
  }

  validate(form: FormGroup): ValidationErrors {
    const isEmpty = !form.value[this.from] || !form.value[this.to]
    const isValid = moment(form.value[this.from], this.format).isBefore(moment(form.value[this.to], this.format))

    return  isEmpty || isValid ? null : { 'period': true }
  }
}
