import { Component, Input } from '@angular/core'
import { FormOption } from 'core'

@Component({
  selector: 'single-select-option',
  templateUrl: './single-select-option.component.html'
})

export class SingleSelectOptionComponent {
  @Input() option: FormOption
  @Input() level: number
  @Input() selectedValue: any
  @Input() selectOptionFn: Function

  select() {
    if (!this.selectable()) { return }
    this.selectOptionFn(this.option)
  }

  selectable(): boolean {
    return !this.option['group'] && !this.option['disabled']
  }

  selected(): boolean {
    return this.selectedValue == this.option['value'] && !this.option['group']
  }

  trackByFn(index, item) {
    return item["group"] ? 
      item["label"] : item["value"]
  }
}
