import { BaseModel } from './base.model'

export class AnswerOption extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    correct: false
  }

  _remove: boolean
  correct: boolean
  hint: string
  id: number
  name: string
  position: number
  state: string

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
