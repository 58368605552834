<div
  class="drop-section-checkboxes__item selectable"
  [style.padding-left.px]="level * 20 + 31"
  (click)="toggleSelect($event)"
>
  <input
    type="checkbox"
    class="drop-section-checkboxes__checkbox"
    [checked]="selected"
    [disabled]="option.disabled"
  >
  <div class="drop-section-checkboxes__label">{{ option.label }}</div>
  <div class="select__opt-sub-label" *ngIf="option.subLabel">{{ option.subLabel }}</div>
</div>

<div
  class="drop-section-checkboxes__sub-items"
  *ngIf="option.subOptions && option.subOptions.length"
>
  <multi-select-search-option
    *ngFor="let subOption of option.subOptions; trackBy: trackByFn"
    [option]="subOption"
    [level]="level + 1"
    [selectedValues]="selectedValues"
    [selectOptionFn]="selectOptionFn"
    [deselectOptionFn]="deselectOptionFn"
  ></multi-select-search-option>
</div>