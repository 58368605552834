import { BaseModel } from './base.model'
import { Moment } from 'moment'
import { User } from './user.model'

export class Report extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  name: string
  status: string
  downloadUrl: string
  completedAt: Moment
  createdAt: Moment
  owner: User
}
