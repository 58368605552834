import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { BaseModelService } from './base-model.service'
import { Question } from './question.model'
import { QuestionSerializer } from 'serializers'

@Injectable()
export class QuestionService extends BaseModelService<Question> {
  constructor() {
    super()

    this.configure({
      basePath:  'questions',
      key:       'question',
      pluralKey: 'questions',
      serializer: QuestionSerializer
    })
  }

  foldersIndex(params?: Object): Observable<Object> {
    let url = this.buildUrl('folders_index')
    return this.get(url, params)
  } 
}
