import { Pipe, PipeTransform } from '@angular/core'
import { Moment } from 'moment'

@Pipe({ name: 'moment', pure: false })
export class MomentPipe implements PipeTransform {
  constructor() {}

  transform(value: Moment, format: string = "DD.MM.YY HH:mm"): string {
    if(!value) {
      return null
    } else {
      return value.format(format)
    }
  }
}