interface IStorage {
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => void;
}

export function LocalStorage(key: string) {
  return WebStorage(key, localStorage);
}

export function SessionStorage(key: string) {
  return WebStorage(key, sessionStorage);
}

export function StatisticsStorage(key: string) {
  return WebStorage(key, sessionStorage);
}

function WebStorage(key: string, storage: IStorage) {
  return (target: Object, propertyName: string) => {
    let mappedPropertyName = '_' + propertyName + '_mapped'

    Object.defineProperty(target, propertyName, {
      enumerable: true,
      configurable: true,
      get: function() {
        return this[mappedPropertyName]
      },
      set: function(newValue) {
        // Default value is being set.
        // Important: Default value must always be defined.
        if(undefined === this[mappedPropertyName]) {
          let storedValue = (storage && storage.getItem(key)) || null
          if ("string" === typeof storedValue) {
            try {
              storedValue = JSON.parse(storedValue)
            } catch (e) {
              storedValue = null
            }
          }
          this[mappedPropertyName] = storedValue
          // Fetched value from storage. No need to set default value.
          if (null !== storedValue) {
            return
          }
        }
        
        /**
         * Пропускаем, если нет хранилища
         */
        if (storage) {
          storage.setItem(key, JSON.stringify(newValue))
        }
        
        this[mappedPropertyName] = newValue
      }
    })
  }
}
