import { Component, forwardRef } from "@angular/core"
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms'
import { startWith, map } from "rxjs/operators"
import { Observable, Subscription } from "rxjs"
import { PluralizeService } from 'core/i18n'


const noop = () => {}

@Component({
  selector: "time-amount-component",
  templateUrl: "./time-amount.component.html",
  styleUrls: ["./time-amount.component.sass"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeAmountComponent),
      multi: true
    }
  ],
})
export class TimeAmountComponent implements ControlValueAccessor {
  hoursControl = new FormControl()
  hours$: Observable<number> = this.hoursControl.valueChanges.pipe(startWith(0))
  hoursCaption$: Observable<string> = this.hours$.pipe(
    map(hours => this.pluralizeService.instant('timeAmount.hoursNoCount', hours))
  )
  // subs
  private subs: Subscription[] = []
  // mask
  isNumber(e) {
    const charCode = e.which ? e.which : e.keyCode
    return /\d/.test(String.fromCharCode(charCode))
  }

  constructor(
    private pluralizeService: PluralizeService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.hoursControl.valueChanges.subscribe(hours => {
        this.onChangeCallback(hours)
        this.onTouchedCallback()
      })
    )
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  writeValue(value: number) {
    this.hoursControl.setValue(value)
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn
  }

  private onTouchedCallback: () => void = noop

  private onChangeCallback: (_: any) => void = noop
}