import { BaseModel } from './base.model'
import { AnswerOption } from './answer-option.model'
import { QuestionDecorator } from 'decorators'

export class Question extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    answerOptions: [],
    shuffleAnswerOptions: true,
    type: 'select'
  }

  answerOptions: AnswerOption[]
  answerOptionsCount: number
  comment: string
  correctAnswerValue: string|number|number[]
  folderId: number
  hint: string
  name: string
  shuffleAnswerOptions: boolean
  state: string
  type: string

  private decorator: QuestionDecorator

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  isSelect(): boolean {
    return this.type == 'select'
  }

  isMultiselect(): boolean {
    return this.type == 'multiselect'
  }

  decorate(): QuestionDecorator {
    if (!this.decorator) { this.decorator = new QuestionDecorator(this) }
    return this.decorator
  }
  
  /**
   * Just remove all html tags
   */
  get displayName(): string {
    const tagRegex = /(<([^>]+)>)/ig
    return this.name.replace(tagRegex, "")
  }
}
