import { BaseSerializer } from './base.serializer'
import { UserSerializer } from './user.serializer'
import { TimeSerializer } from './attributes'
import { Comment } from 'models'

export class CommentSerializer extends BaseSerializer<CommentSerializer> {
  static SERIALIZABLE_ATTRIBUTES = ['commentableId', 'commentableType', 'text']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    commenter: UserSerializer,
    createdAt: TimeSerializer,
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Comment {
    return new Comment(attributes)
  }
}
