import { BaseSerializer } from './base.serializer'
import { Audio } from 'models/audio.model'

export class AudioSerializer extends BaseSerializer<Audio> {
  static SERIALIZABLE_ATTRIBUTES = ['comment', 'content', 'folderId', 'name', 'uploadFileUrl']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}

  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Audio {
    return new Audio(attributes)
  }
}
