import { BaseModel } from './base.model'
import { SessionInteractiveSkillGrade } from './session-interactive-skill-grade.model'

export class SessionInteractive extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number
  grade: number
  name: string
  grades: SessionInteractiveSkillGrade[]
}
