import { Moment } from 'moment'

import { BaseModel } from './base.model'
import { Session } from './session.model'

export class SessionVersion extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  createdAt: Moment
  event: string
  id: number
  session: Session
  sessionBefore: Session
  userId: number
  userName: string
}
