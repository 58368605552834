import { BaseSerializer } from './base.serializer'
import { TimeSerializer } from './attributes'
import { SessionExam } from 'models'
import { ExamSerializer } from './exam.serializer'
import { ExamSectionResultSerializer } from './exam-section-result.serializer'


export class SessionExamSerializer extends BaseSerializer<SessionExam> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    createdAt: TimeSerializer,
    completedAt: TimeSerializer,
    exam: ExamSerializer,
    sections: ExamSectionResultSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<SessionExam>): SessionExam {
    return new SessionExam(attributes)
  }
}
