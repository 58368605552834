import { Moment } from 'moment'
import { BaseModel } from './base.model'
import { ExamSectionResult } from './exam-section-result.model'
import { Exam } from 'models'

export type GradeBy = "total_questions"|"total_sections"

export class SessionExam extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    sections: new Array<ExamSectionResult>()
  }
  id: number
  grade: number
  passed: boolean
  sectionResults: ExamSectionResult[]
  sessionId: number
  startedAt: Moment
  completedAt: Moment
  exam: Exam

  score: number
  maxScore: number
  minScore: number
  gradeBy: GradeBy

  isCompleted(): boolean {
    return !!this.completedAt
  }

  isPassed(): boolean {
    return this.passed
  }
}
