import { Moment } from "moment"
import { BaseModel } from "./base.model"
import { WebHook } from "./web-hook.model"

export class WebHookResult extends BaseModel {
  requestBody: string
  responseBoby: string
  responseCode: number
  message: string
  success: boolean
  createdAt: Moment
  webHook: WebHook
}