import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'pagination-per-page',
  template: `
  <ul class="pagination">
    <li class="page-item" *ngFor="let perPage of perPageVariants" [ngClass]="{
      'active': currentPerPage == perPage
    }">
      <a class="page-link" href="#" (click)="setPerPage($event, perPage)">{{ perPage }}</a>
    </li>
  </ul>
  `
})

export class PaginationPerPageComponent {
  @Input('perPageVariants') perPageVariants: number[]
  @Input('currentPerPage') currentPerPage: number
  @Output('perPageChanged') private _pageChanged$: EventEmitter<Object> = new EventEmitter<Object>()

  setPerPage(e, perPage): void {
    e.preventDefault()
    this._pageChanged$.emit({perPage: perPage})
  }
}