import { Pipe, PipeTransform } from '@angular/core'

import { PluralizeService } from 'core/i18n'

@Pipe({name: 'pluralize'})
export class PluralizePipe implements PipeTransform {
  constructor(public pluralizeService: PluralizeService) {}

  transform(key: string, count: number, interpolateParams?: Object): string {
    if(!count) { count = 0 }
    return this.pluralizeService.instant(key, count, interpolateParams)
  }
}