import { FormControl } from '@angular/forms'

export class MaxLengthValidator {
  constructor(private maxLength: number) {
    this.validate = this.validate.bind(this)
  }

  validate(control: FormControl): Object {
    if(!control.value) { return null }
    let length = control.value.trim().length
    if(length <= this.maxLength) {
      return null
    } else {
      return { maxlength: { allowedLength: this.maxLength, actualLength: length } }
    }
  }
}
