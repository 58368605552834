import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from "rxjs/operators"
import { Constants } from 'config'
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http'

const apiBase = Constants.API.HOST
const apiPath = Constants.API.PATH

export type AssetType = "image"|"video"

export interface Asset {
  src: string
  type: AssetType
}

export interface IAssetsResponse {
  files: Asset[]
}

export interface IUploadAssetResponse {
  file: Asset
}

@Injectable({
  providedIn: "root"
})
export class FileUploadsService {
  constructor(
    private http: HttpClient
  ) { }

  upload(file: File): Observable<HttpEvent<any>> {
    const url = `${ apiBase }/${ apiPath }/file_uploads`
    const formData: FormData = new FormData()

    formData.append('file', file)

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    })

    return this.http.request(req)
  }

  uploadAssets(file: File, type: AssetType): Observable<HttpEvent<any>> {
    const url = `${ apiBase }/${ apiPath }/assets_uploads`
    const formData: FormData = new FormData()

    formData.append('file', file)
    formData.append('type', type)

    // return this.http.post<IUploadAssetResponse>(url, body)
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    })

    return this.http.request<Asset>(req)
  }

  getAssets(type: string): Observable<Asset[]> {
    const url = `${ apiBase }/${ apiPath }/assets_uploads`
    const params = { type }

    return this.http.get<IAssetsResponse>(url, { params }).pipe(
      map(resp => resp.files)
    )
  }
}
