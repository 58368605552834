import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { BaseModelService } from './base-model.service'
import { SessionVersion } from './session-version.model'
import { SessionVersionSerializer } from 'serializers'

@Injectable()
export class SessionVersionService extends BaseModelService<SessionVersion> {
  constructor() {
    super()

    this.configure({
      basePath:  'versions',
      key:       'version',
      pluralKey: 'versions',
      serializer: SessionVersionSerializer
    })
  }

  buildUrl(sessionId: number, ...params: any[]): string {
    return [this.apiPath, 'sessions', sessionId, this.basePath, ...params].join('/') + '.json'
  }

  index(sessionId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl(sessionId)
    return this.get(url, params)
  }

  find(...params: number[]): Observable<Object> {
    let sessionId = params[0]
    let id = params[1]
    let url = this.buildUrl(sessionId, id)
    return this.get(url)
  }
}
