import { Directive, Input } from '@angular/core'
import { TemplateRef, ViewContainerRef } from '@angular/core'
import { AuthorizationService } from 'core/auth/authorization.service'
import { Subscription } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

@Directive({ selector: '[ifAllowedAny]' })
export class IfAllowedAnyDirective {
  private prevAllowed: boolean = null
  @Input() ifAllowedAny: any[]
  private subs: Subscription[] = []

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorization: AuthorizationService
  ) { }

  ngOnInit() {
    this.subs.push(
      this.authorization.currentUser$.pipe(
        map(currentUser => this.ifAllowedAny.some(config => {
          const [scope, action, targetObject] = config
          return this.authorization._allowed(currentUser, scope, action, targetObject)
        }))
      ).subscribe(allowed => {
        if (allowed && (this.prevAllowed === null || !this.prevAllowed)) {
          this.prevAllowed = true
          this.viewContainer.createEmbeddedView(this.templateRef)
        }
        else if (!allowed && (this.prevAllowed === null || this.prevAllowed)) {
          this.prevAllowed = false
          this.viewContainer.clear()
        }
      })
    )
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}