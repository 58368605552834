import { BaseSerializer } from './base.serializer'
import { AppTranslationSerializer } from './app-translation.serializer'
import { AppLocale } from 'models/app-locale.model'

export class AppLocaleSerializer extends BaseSerializer<AppLocale> {
  static SERIALIZABLE_ATTRIBUTES = ['id', 'name', 'code', 'main', 'translations', 'localeFileUrl', 'textDirection']
  static SERIALIZERS = {
    translations: AppTranslationSerializer
  }
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    translations: AppTranslationSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): AppLocale {
    return new AppLocale(attributes)
  }
}
