import { Pipe, PipeTransform } from '@angular/core'

@Pipe({name: 'phone', pure: false})
export class PhonePipe implements PipeTransform {
  constructor() {}

  // TODO: Extract country codes and formatting rules into config file
  transform(value: string): string {
    if(!value) {
      return null
    }
    else if(value.match(/^7\d{10}$/)) {
      return `+7 (${value.substring(1, 4)}) ${value.substring(4, 7)}-${value.substring(7, 9)}-${value.substring(9)}`
    }
    else if(value.match(/^374\d{8}$/)) {
      return `+374 (${value.substring(3, 5)}) ${value.substring(5, 7)}-${value.substring(7, 9)}-${value.substring(9)}`
    } else {
      return value
    }
  }
}