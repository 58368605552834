import { BaseModel } from './base.model'
import { Program } from './program.model'
import { User } from './user.model'
import { Moment } from 'moment'

export type EditorType = "wysiwyg"|"sourceCode"|"grapesJs"

export interface TransitionButton {
  id: string
  name: string
}

export interface GrapesJsContent {
  components: [],
  styles: Object
}

export class StaticScreen extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    styles: '',
    cssLibraires: [],
    transitionButtons: [],
    editorType: "wysiwyg"
  }

  comment: string
  content: string
  grapesJsContent: GrapesJsContent
  folderId: number
  id: number
  name: string
  programs: Program[]
  state: string
  nameTranslations: Object
  contentTranslations: Object
  availableLocales: string[]
  styles: string
  editorType: EditorType
  cssLibraires: string[]
  transitionButtons: TransitionButton[]
  previewUrl: string
  showBezels: boolean // равен true у старых html/css экранов
  createdBy: User
  updatedBy: User
  createdAt: Moment
  updatedAt: Moment

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  get availableLocalesString(): string {
    return this.availableLocales.length > 0 ? this.availableLocales.join(", ") : "Нет переводов"
  }
}
