import { Injectable } from '@angular/core'
import {
  City,
  Country,
  Folder,
  Interactive,
  Question,
  Program,
  Session,
  Skill,
  StaticScreen,
  User,
  Video,
  Organization,
  Dictionary,
  Quiz,
  AppLocale,
  AppTranslation
} from 'models'
import { KioskForm } from 'models/kiosk-forms/kiosk-form.model'

@Injectable()
export class AuthorizationConfig {
  config = {
    policies: {
      Session: {
        index(user: User): boolean {
          return user.isAdmin() || user.can(['manage:sessions'])
        },
  
        statistics(u: User): boolean {
          return u.isAdmin() || u.can(['repotrs:all', 'manage:sessions'])
        },
  
        indexXlsx(u: User): boolean {
          return u.isAdmin() || u.can(['manage:sessions'])
        },
  
        show: function(user: User, session: Session): boolean {
          return user.isAdmin() || user.can(['manage:sessions'])
        },
  
        edit (user: User, session: Session): boolean {
          return user.isAdmin() || user.can(['manage:sessions'])
        },

        showSessionExamReport (user: User, session: Session): boolean {
          return user.isAdmin() || user.can(['manage:sessions'])
        },

        cancel(user: User, session: Session): boolean {
          return user.isAdmin() // TOTO: && user.organizationId == session.organizationId
        }
      },
  
      Video: {
        index: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit: (user: User, video: Video): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy: (user: User, video: Video): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable: (user: User, video: Video): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore: (user: User, video: Video): boolean => {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      Quiz: {
        index: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit: (user: User, quiz: Quiz): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy: (user: User, quiz: Quiz): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable: (user: User, quiz: Quiz): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore: (user: User, quiz: Quiz): boolean => {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      Exam: {
        index: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit: (user: User, skill: Skill): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy: (user: User, skill: Skill): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable: (user: User, skill: Skill): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore: (user: User, skill: Skill): boolean => {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      Question: {
        index: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit: (user: User, question: Question): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy: (user: User, question: Question): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable: (user: User, question: Question): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore: (user: User, question: Question): boolean => {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      Interactive: {
        index: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit: (user: User, interactive: Interactive): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy: (user: User, interactive: Interactive): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable: (user: User, interactive: Interactive): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore: (user: User, interactive: Interactive): boolean => {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      Program: {
        index: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create: (user: User): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit: (user: User, program: Program): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy: (user: User, program: Program): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable: (user: User, program: Program): boolean => {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore: (user: User, program: Program): boolean => {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      StaticScreen: {
        index: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },

        show: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit: function(user: User, staticScreen: StaticScreen): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy: function(user: User, staticScreen: StaticScreen): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable: function(user: User, staticScreen: StaticScreen): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore: function(user: User, staticScreen: StaticScreen): boolean {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      Organization: {
        index(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        create(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        edit(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        disable(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        destroy(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        restore(user: User): boolean {
          return user.isSuperAdmin()
        },
      },
  
      City: {
        index: function(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        create: function(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        edit: function(user: User, city: City): boolean {
          return user.isSuperAdmin()
        },
  
        destroy: function(user: User, city: City): boolean {
          return user.isSuperAdmin()
        },
  
        disable: function(user: User, city: City): boolean {
          return user.isSuperAdmin()
        },
  
        restore: function(user: User, city: City): boolean {
          return user.isSuperAdmin()
        }
      },
  
      Country: {
        create: function(user: User): boolean {
          return user.isSuperAdmin()
        },
  
        edit: function(user: User, country: Country): boolean {
          return user.isSuperAdmin()
        },
  
        destroy: function(user: User, country: Country): boolean {
          return user.isSuperAdmin()
        },
  
        disable: function(user: User, country: Country): boolean {
          return user.isSuperAdmin()
        },
  
        restore: function(user: User, country: Country): boolean {
          return user.isSuperAdmin()
        }
      },
  
      User: {
        index(user: User): boolean {
          return user.isAdmin() || user.isSuperAdmin() ||
            user.can(['manage:users', 'users:index'])
        },
  
        create(user: User): boolean {
          return user.isAdmin() || user.isSuperAdmin() ||
            user.can(['manage:users', 'users:create'])
        },
  
        edit(user: User, targetUser: User): boolean {
          return user.isAdmin() || user.isSuperAdmin() ||
            user.can(['manage:users', 'users:update'])
        },
  
        disable(user: User, targetUser: User): boolean {
          return user.isAdmin() || user.isSuperAdmin() ||
            user.can(['manage:users', 'users:disable'])
        },
  
        destroy(user: User, targetUser: User): boolean {
          return user.isAdmin() || user.isSuperAdmin() ||
            user.can(['manage:users', 'users:destroy'])
        },
  
        restore(user: User, targetUser: User): boolean {
          return user.isAdmin() || user.isSuperAdmin() ||
            user.can(['manage:users', 'users:restore'])
        },
  
        managePermissions(user: User, targetUser: User): boolean {
          return user.isAdmin() || user.isSuperAdmin() ||
            user.can(['manage:users', 'users:manage_permissions'])
        }
      },
  
      Dictionary: {
        index(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:show'])
        }, 
        show(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:show'])
        },
        edit(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:edit'])
        },
        create(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:create'])
        },
        update(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:update'])
        },
        disable(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:disable'])
        },
        restore(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:restore'])
        },
        destroy(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:dictionaries', 'dictionaries:destroy'])
        }
      },

      RegistrationVariable: {
        index(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:show'])
        }, 
        show(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:show'])
        },
        edit(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:edit'])
        },
        create(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:create'])
        },
        update(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:update'])
        },
        disable(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:disable'])
        },
        restore(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:restore'])
        },
        destroy(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:registration_variables', 'registration_variables:destroy'])
        }
      },
  
      BackgroundReport: {
        index(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:reports', 'reports:index'])
        },
        show(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:reports', 'reports:show'])
        },
        create(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:reports', 'reports:create'])
        },
        download(user: User): boolean {
          return user.isAdmin() || 
            user.can(['manage:reports', 'reports:download'])
        }
      },
  
      AppLocale: {
        index: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        show: function(user: User, appLocale: AppLocale): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        create: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        edit: function(user: User, appLocale: AppLocale): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        destroy: function(user: User, appLocale: AppLocale): boolean {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      AppTranslation: {
        index: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        show: function(user: User, appLocale: AppTranslation): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        create: function(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        edit: function(user: User, appLocale: AppTranslation): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
        destroy: function(user: User, appLocale: AppTranslation): boolean {
          return user.isAdmin() || user.isContentEditor()
        }
      },
  
      ABExperiment: {
        index(user: User): boolean {
          return user.isAdmin() ||
            user.can(['manage:ab_experiments', 'ab_experiments:index'])
        },
  
        update(user: User) :boolean {
          return user.isAdmin() ||
            user.can(['manage:ab_experiments', 'ab_experiments:update'])
        },
  
        create(user: User): boolean {
          return user.isAdmin() ||
            user.can(['manage:ab_experiments', 'ab_experiments:create'])
        },
  
        showResults(user: User): boolean {
          return user.isAdmin() ||
            user.can(['manage:ab_experiments', 'ab_experiments:show_results'])
        },
  
        stop(user: User): boolean {
          return user.isAdmin() ||
            user.can(['manage:ab_experiments', 'ab_experiments:update'])
        },
  
        start(user: User): boolean {
          return user.isAdmin() ||
            user.can(['manage:ab_experiments', 'ab_experiments:update'])
        }
      },
  
      Folder: {
        create(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit(user: User, folder: Folder): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy(user: User, folder: Folder): boolean {
          return user.isAdmin() || user.isContentEditor()
        }
      },

      WebHook: {
        index(user: User): boolean {
          return user.isAdmin()
        },
  
        create(user: User): boolean {
          return user.isAdmin()
        },
  
        edit(user: User): boolean {
          return user.isAdmin()
        },
  
        disable(user: User): boolean {
          return user.isAdmin()
        },
  
        destroy(user: User): boolean {
          return user.isAdmin()
        },
  
        restore(user: User): boolean {
          return user.isAdmin()
        },
      },
      
      ApiKey: {
        index(user: User): boolean {
          return user.isAdmin()
        },
  
        create(user: User): boolean {
          return user.isAdmin()
        },
  
        edit(user: User): boolean {
          return user.isAdmin()
        },
  
        change(user: User): boolean {
          return user.isAdmin()
        },
      },

      KioskForm: {
        index(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        create(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        edit(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        disable(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        destroy(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
  
        restore(user: User): boolean {
          return user.isAdmin() || user.isContentEditor()
        },
      }
    }
  }
}