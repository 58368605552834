<drop #drop 
  [caption]="caption" 
  (close)="onCloseDropDown($event)" 
  modificator="drop__search"
>
  <div class="drop__section drop-section-checkboxes-actions" *ngIf="options.length > 0">
    <span class="drop-section-checkboxes-actions__toggle link" (click)="selectAll()">
      все
    </span><!-- 
  --><span class="drop-section-checkboxes-actions__toggle link" (click)="deselectAll()">
      ничего
    </span>
  </div>

  <div class="drop-section-options-search"
  *ngIf="showSearch && isOpen()"
  >
    <input #search type="text" class="form-control" (keyup)="onKeyup(search.value)" 
    [(ngModel)]="optionsSearchTerm"
    />
  </div>

  <div
    class="drop__section drop-section-checkboxes"
    *ngIf="options.length > 0 && isOpen()"
    perfect-scrollbar
    [perfectScrollbarOptions]="{ 
      suppressScrollX: true,
      wheelPropagation: false
    }"
    >
    <multi-select-search-option
      *ngFor="let option of filteredOptions; trackBy: trackByFn"
      [option]="option"
      [level]="0"
      [selectedValues]="selectedValues"
      [selectOptionFn]="selectOption"
      [deselectOptionFn]="deselectOption"
    ></multi-select-search-option>
  </div>

  <div class="drop__section drop-section-empty" *ngIf="options.length == 0">
    Нет опций
  </div>

  <div class="drop__section drop-section-buttons">
    <div class="drop-section-buttons__row">
      <div class="drop-section-buttons__col">
        <button
          type="button"
          class="btn btn-primary btn_width_108"
          (click)="submit()"
        >Применить</button>
      </div><!--
    --><div class="drop-section-buttons__col">
        <button
          type="button"
          class="btn btn-default-fr btn_width_108"
          (click)="cancel()"
        >Отменить</button>
      </div>
    </div>
  </div>
</drop>