import { MomentPipe } from './moment.pipe'
import { PhonePipe } from './phone.pipe'
import { PluralizePipe } from './pluralize.pipe'
import { PrivateUploadUrlPipe } from './private-upload-url.pipe'
import { SearchFilterPipe } from './searchfilter.pipe'
import { TruncatePipe } from './truncate.pipe'
import { HighLightPipe } from './highlight.pipe'
import { Nl2BrPipe } from './nl2br.pipe' 
import { DownloadAttachmentPipe } from './download-attachment.pipe'

export { MomentPipe }
export { PhonePipe }
export { PluralizePipe }
export { PrivateUploadUrlPipe }
export { SearchFilterPipe }
export { TruncatePipe }
export { HighLightPipe }
export { Nl2BrPipe } 
export { DownloadAttachmentPipe }

export const COMMON_PIPES = [
  MomentPipe,
  PhonePipe,
  PluralizePipe,
  PrivateUploadUrlPipe,
  SearchFilterPipe,
  TruncatePipe,
  HighLightPipe,
  Nl2BrPipe,
  DownloadAttachmentPipe
]
