import { Injectable } from '@angular/core'
import { forkJoin, Observable, of, Subject } from 'rxjs'
import { Folder, FolderService } from 'models'
import { tap, map, mapTo } from 'rxjs/operators'

@Injectable()
export abstract class BaseFolderTreeService {
  protected fetchParams: Object = {}
  protected rootFolder: Folder
  protected openFolderIds: number[] = []
  protected loadingFolderIds: number[] = []

  readonly afterCreateFolderSubject = new Subject<Folder>()
  readonly afterUpdateFolderSubject = new Subject<{ folder: Folder, folderWas: Folder }>()
  readonly afterDestroyFolderSubject = new Subject<Folder>()
  readonly afterCreateFolder$ = this.afterCreateFolderSubject.asObservable()
  readonly afterUpdateFolder$ = this.afterUpdateFolderSubject.asObservable()
  readonly afterDestroyFolder$ = this.afterDestroyFolderSubject.asObservable()

  constructor(protected folderService: FolderService) {}

  isFolderOpen(folderId: number): boolean {
    return this.openFolderIds.indexOf(folderId) > -1
  }

  isFolderLoading(folderId: number): boolean {
    return this.loadingFolderIds.indexOf(folderId) > - 1
  }

  openFolder(folderId: number): Observable<boolean> {
    const targetFolder = this.findFolder(folderId)
    const [_, ...foldersToOpen] = targetFolder.ancestorIds.concat(targetFolder.id)
    
    if (foldersToOpen.length == 0) {
      return of(true)
    }
    return forkJoin(foldersToOpen.map(folderId => {
      if (!this.isFolderOpen(folderId)) {
        this.openFolderIds.push(folderId)
        return this.afterOpenFolder(folderId)
      }
      return of([])
    })).pipe(mapTo(true))
  }

  closeFolder(folderId: number) {
    const index = this.openFolderIds.indexOf(folderId)
    if (index == -1) { return }
    this.openFolderIds.splice(index, 1)
    this.afterCloseFolder(folderId)
  }

  closeFolderSubtree(folderId: number) {
    this.closeFolder(folderId)
    const folder = this.findFolder(folderId)
    if (folder) {
      folder.subFolders.forEach((subFolder: Folder) => {
        this.closeFolderSubtree(subFolder.id)
      })
    }
  }

  closeAllFolders() {
    if (this.rootFolder) {
      this.closeFolderSubtree(this.rootFolder.id)
    }
  }

  setFetchParams(params: Object) {
    this.fetchParams = params
  }

  getRootFolder(): Folder {
    return this.rootFolder
  }

  findFolder(folderId: number, node: Folder = this.rootFolder): Folder {
    if (node.id == folderId) {
      return node
    } else {
      let i = node.subFolders.length
      while (i--) {
        let result = this.findFolder(folderId, node.subFolders[i])
        if (result) { return result }
      }
      return null
    }
  }

  loadSubFoldersOf(folderId: number): Observable<Folder> {
    const folder = this.findFolder(folderId)
    if (folder) {
      this.markFolderLoading(folderId, true)
      return this.fetchFolderSubTree(folder.id, this.fetchParams).pipe(
        tap(sourceFolder => {
          folder.subFolders = sourceFolder.subFolders
          this.markFolderLoading(folderId, false)
        }),
        mapTo(folder)
      )
    } else {
      return of(null)
    }
  }

  folderMoveable(folderId: number, targetFolderId: number): boolean {
    if (folderId == targetFolderId) { return false }
    const folder = this.findFolder(folderId)
    const targetFolder = this.findFolder(targetFolderId)
    return !targetFolder.isDescendantOf(folder) && !targetFolder.isParentOf(folder)
  }

  moveFolder(folderId: number, targetFolderId: number): Observable<Folder> {
    const params = { id: folderId, parentId: targetFolderId }
    return this.folderService.update(params).pipe(
      map(data => data["folder"] as Folder)
    )
  }

  protected markFolderLoading(folderId: number, loading: boolean) {
    const index = this.loadingFolderIds.indexOf(folderId)
    if (loading && index == -1) {
      this.loadingFolderIds.push(folderId)
    }
    else if (!loading && index > -1) {
      this.loadingFolderIds.splice(index, 1)
    } 
  }

  protected loadFolderTree(): Observable<Folder> {
    return this.fetchFolderTree(this.fetchParams).pipe(
      tap(f => this.rootFolder = f)
    )
  }

  abstract loadData(): Observable<Object>

  protected abstract fetchFolderTree(params?: Object): Observable<Folder>

  protected abstract fetchFolderSubTree(folderId: number, params?:Object): Observable<Folder>

  protected abstract afterOpenFolder(folderId: number): Observable<any>

  protected abstract afterCloseFolder(folderId: number): void
}
