<div class="form-control">
  <drop #drop [caption]="caption" (close)="onCloseDropDown($event)" modificator="drop__search">
    <div class="drop__section drop-section-links">
      <div
        class="drop-section-links__item selectable"
        *ngFor="let preset of presets"
        (click)="selectPreset(preset)"
      >
        <div class="drop-section-links__label" >
          {{ preset.label }}
        </div>
      </div>
    </div>
  
    <div class="drop__section drop-section-date">
      <div
        class="drop-section-date__from"
        #fromInputWrapper
      >
        <input
          type="text"
          class="form-control drop-section-date__input"
          readonly
          [value]="textFieldFromValue()"
          (click)="toggleFromPicker()"
        >
        <div
          class="drop-section-date__picker-wrapper"
          (click)="onPickerClick($event)"
        >
          <datepicker
            *ngIf="isFromPickerOpen"
            [startingDay]=1
            [showWeeks]="false"
            (selectionDone)="onFromPickerSelect($event)"
          ></datepicker>
        </div>
      </div><!--
    --><div class="drop-section-date__sep">
        –
      </div><!--
    --><div
        class="drop-section-date__until"
        #untilInputWrapper
      >
        <input
          type="text"
          class="form-control drop-section-date__input"
          readonly
          [value]="textFieldUntilValue()"
          (click)="toggleUntilPicker()"
        >
        <div
          class="drop-section-date__picker-wrapper"
          (click)="onPickerClick($event)"
        >
          <datepicker
            *ngIf="isUntilPickerOpen"
            [startingDay]=1
            [showWeeks]="false"
            (selectionDone)="onUntilPickerSelect($event)"
          ></datepicker>
        </div>
      </div>
    </div>
  
    <div class="drop__section drop-section-buttons">
      <div class="drop-section-buttons__row">
        <div class="drop-section-buttons__col">
          <button
            type="button"
            class="btn btn-primary btn_width_108"
            (click)="submit()"
          >Применить</button>
        </div>
        
        <div class="drop-section-buttons__col">
          <button
            type="button"
            class="btn btn-default-fr btn_width_108"
            (click)="cancel()"
          >Отменить</button>
        </div>
      </div>
    </div>
  </drop>
</div>
