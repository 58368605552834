import { BaseSerializer } from './base.serializer'
import { InteractiveSkillGrade } from 'models/interactive-skill-grade.model'

export class InteractiveSkillGradeSerializer extends BaseSerializer<InteractiveSkillGrade> {
  static SERIALIZABLE_ATTRIBUTES = ['id', 'interactiveSkillId', 'grade', 'pointsCountTreshold', '_destroy']

  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): InteractiveSkillGrade {
    return new InteractiveSkillGrade(attributes)
  }
}
