import { BaseModel } from './base.model'
import { ExamSection } from './exam-section.model'

export class ExamSectionResult extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  examSection: ExamSection
  // depricated
  grade: number
  score: number
  maxScore: number
  minScore: number
  blocking: boolean
  passed: boolean
}
