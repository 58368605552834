import { ValidatorFn, FormArray } from '@angular/forms'

export function maxFormArrayLengthValidator(maxLength: number, message: string = ""): ValidatorFn {
  return (c: FormArray): Object => {
    const length = c.controls.length
    const isValid = length <= maxLength
    message = message.length == 0 ?
      `Максимальное значение: ${ maxLength }` :
      message
        .replace("{maxLength}", maxLength.toString())
        .replace("{currentLength}", length.toString())

    return isValid ? null : {
      maxFormArrayLength: {
        maxLength, message, currentLength: length
      }
    }
  }
}
