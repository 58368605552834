import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { UserService, User } from 'models'
import { AngularTokenService, ApiResponse, AuthData, UserData } from 'angular-token'
import { BehaviorSubject } from 'rxjs'
import { mapTo, tap, catchError } from 'rxjs/operators'

@Injectable()
export class AuthenticationService {
  private _currentUser = new BehaviorSubject<User>(null)
  readonly currentUser$ = this._currentUser.asObservable()

  constructor(
    private tokenService: AngularTokenService,
    private userService: UserService
  ) {}

  get currentUser(): User {
    return this._currentUser.getValue()
  }

  set currentUser(user: User) {
    this._currentUser.next(user)
  }

  get currentAuthData(): AuthData {
    return this.tokenService.currentAuthData
  }

  get currentUserData(): UserData {
    return this.tokenService.currentUserData
  }

  validateToken(): Observable<boolean> {
    return this.tokenService.validateToken().pipe(
      tap(resp => {
        const json = resp.data['user']
        const user = new User(json)
        this.currentUser = user
      }),
      mapTo(true),
      catchError(() => of(false))
    )
  }

  signIn(login: string, password: string): Observable<ApiResponse> {
    return this.tokenService.signIn({ login, password }).pipe(
      tap(resp => {
        const json = resp.data['user']
        const user = new User(json)
        this.currentUser = user
      })
    )
  }

  signOut(): Observable<ApiResponse> {
    return this.tokenService.signOut().pipe(
      tap(() => this.currentUser = null)
    )
  }

  switchRole(role: string) {
    return this.userService.switchRole(role).pipe(
      tap(user => this.currentUser = user),
      mapTo(true)
    )
  }

  switchOrganization(organizationId: number) {
    return this.userService.switchOrganization(organizationId).pipe(
      tap(user => this.currentUser = user),
      mapTo(true)
    )
  }
}
