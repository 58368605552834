import { BaseModel } from './base.model'
import { Interactive } from './interactive.model'

export class Skill extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
  }

  comment: string
  folderId: number
  id: number
  name: string
  state: string
  interactives: Interactive[]

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
