import { BaseSerializer } from '../../serializers/base.serializer'
import { KioskForm } from './kiosk-form.model'
import { KioskFormControlSerializer } from './kiosk-form-control.serializer'
import { TimeSerializer } from '../../serializers/attributes'
import { UserSerializer } from '../../serializers/user.serializer'

export class KioskFormSerializer extends BaseSerializer<KioskForm> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'title',
    'comment',
    'btnNextLabel',
    'kioskFormControls'
  ]
  static SERIALIZERS = {
    'kioskFormControls': KioskFormControlSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    'kioskFormControls': 'kioskFormControlsAttributes'
  }

  static DESERIALIZERS = {
    'kioskFormControls': KioskFormControlSerializer,
    createdAt: TimeSerializer,
    updatedAt: TimeSerializer,
    createdBy: UserSerializer,
    updatedBy: UserSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<KioskForm>): KioskForm {
    return new KioskForm(attributes)
  }
}
