import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core'

import { AbExperimentService } from './ab-experiment.service'
import { ApiKeyService } from './api-key.service'
import { AppLocaleService } from './app-locale.service'
import { AppTranslationService } from './app-translation.service'
import { AudioService } from './audio.service'
import { AvailableLocalesService } from './available-locales.service'
import { ExamService } from './exam.service'
import { FolderService } from './folder.service'
import { InteractiveContentItemService } from './interactive-content-item.service'
import { InteractiveService } from './interactive.service'
import { QuizService } from './quiz.service'
import { ProgramService } from './program.service'
import { QuestionService } from './question.service'
import { ReportService } from './report.service'
import { SessionService } from './session.service'
import { SessionVersionService } from './session-version.service'
import { SettingService } from './setting.service'
import { SkillService } from './skill.service'
import { StaticScreenService } from './static-screen.service'
import { StatisticsService } from './statistics.service'
import { SystemScreenService } from './system-screen.service'
import { UserPermissionService } from './user-permissions.service'
import { UserService } from './user.service'
import { VideoService } from './video.service'
import { CommentService } from './comment.service'
import { OrganizationService } from './organization.service'

@NgModule({})
export class ModelsModule {
  constructor (@Optional() @SkipSelf() parentModule: ModelsModule) {
    if (parentModule) {
      throw new Error('ModelsModule is already loaded. Import it in the AppModule only')
    }
  }

  static forRoot(params: Object): ModuleWithProviders<ModelsModule> {
    return {
      ngModule: ModelsModule,
      providers: [
        AbExperimentService,
        ApiKeyService,
        AppLocaleService,
        AppTranslationService,
        AudioService,
        AvailableLocalesService,
        CommentService,
        ExamService,
        FolderService,
        InteractiveContentItemService,
        InteractiveService,
        QuizService,
        ProgramService,
        QuestionService,
        ReportService,
        SessionService,
        SessionVersionService,
        SettingService,
        SkillService,
        StaticScreenService,
        StatisticsService,
        SystemScreenService,
        UserPermissionService,
        UserService,
        VideoService,
        OrganizationService
      ]
    }
  }
}
