import { Routes } from '@angular/router'

export const APP_ROUTES: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) 
  },
  { 
    path: '', 
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) 
  }
]