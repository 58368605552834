import { BaseSerializer } from './base.serializer'
import { Quiz } from 'models/quiz.model'
import { QuizQuestionSerializer } from './quiz-question.serializer'
import { QuestionSerializer } from './question.serializer'

export class QuizSerializer extends BaseSerializer<Quiz> {
  static SERIALIZABLE_ATTRIBUTES = [
    'comment', 'folderId', 'lessonQuizQuestions', 'minCorrectAnswers', 'name',
    'questionIds', 'questionsLimit', 'questionsLimitEnabled', 'shuffleQuestions', 'timeLimit'
  ]
  static SERIALIZERS = {
    lessonQuizQuestions: QuizQuestionSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    lessonQuizQuestions: 'lessonQuizQuestionsAttributes'
  }

  static DESERIALIZERS = {
    lessonQuizQuestions: QuizQuestionSerializer,
    questions: QuestionSerializer

  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Quiz {
    return new Quiz(attributes)
  }
}
