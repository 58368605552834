import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { AppTranslation } from './app-translation.model'
import { AppTranslationSerializer } from 'serializers'

@Injectable()
export class AppTranslationService extends BaseModelService<AppTranslation> {
  constructor() {
    super()

    this.configure({
      basePath:  'app_translations',
      key:       'appTranslation',
      pluralKey: 'appTranslations',
      serializer: AppTranslationSerializer
    })
  }
}
