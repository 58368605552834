import { BaseSerializer } from './base.serializer'
import { QuizQuestion } from 'models/quiz-question.model'
import { QuestionSerializer } from './question.serializer'

export class QuizQuestionSerializer extends BaseSerializer<QuizQuestion> {
  static SERIALIZABLE_ATTRIBUTES = ['_destroy', 'position', 'questionId']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    question: QuestionSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): QuizQuestion {
    return new QuizQuestion(attributes)
  }
}
