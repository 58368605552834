import { Input, ViewChild, ElementRef, Component } from '@angular/core'
import { Subscription } from 'rxjs'
import { DragulaService } from 'ng2-dragula'
import { BaseComponent } from 'core/abstract-components'
import { Folder } from 'models'
import { BaseFolderTreeService } from './base-folder-tree.service'
import { FolderFormComponent } from './folder-form.component'

@Component({
  template: ''
})
export abstract class BaseFolderComponent extends BaseComponent {
  @ViewChild('dropArea', { static: true }) dropArea: ElementRef
  @ViewChild('parentDropArea') parentDropArea: ElementRef

  @Input('folder') folder: Folder
  @Input('folderFormCmp') folderFormCmp: FolderFormComponent
  @Input('isFolderActive') isFolderActive: Function

  dragOverSubsription: Subscription
  dragOutSubsription: Subscription
  dropPreview: boolean = false
  parentDropPreview: boolean = false
  dragOverOpenDebounceTime: number = 1500
  dragOverOpenDebounceTimeout: any

  nestedMargin: number = 0
  nestedMarginStep: number = 32

  constructor(
    protected treeService: BaseFolderTreeService,
    protected dragulaService: DragulaService
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit()

    this.nestedMargin = (this.folder.depth() - 1) * this.nestedMarginStep

    this.dragOverSubsription = this.dragulaService.over().subscribe(event => {
      this.onDragOver(event)
    })

    this.dragOutSubsription = this.dragulaService.out().subscribe(event => {
      this.onDragOut(event)
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy()

    this.dragOverSubsription.unsubscribe()
    this.dragOutSubsription.unsubscribe()
  }

  isOpen(): boolean {
    return this.treeService.isFolderOpen(this.folder.id)
  }

  open() {
    if (this.isOpen()) { return }
    this.treeService.openFolder(this.folder.id).subscribe({
      error: error => { this.alertService.error(error) }
    })
  }

  close() {
    if (!this.isOpen()) { return }
    this.treeService.closeFolderSubtree(this.folder.id)
  }

  toggle() {
    this.isOpen() ? this.close() : this.open()
  }

  loading(): boolean {
    return this.treeService.isFolderLoading(this.folder.id)
  }

  onDragOver(event: any) {
    const { name, el, container, source } = event
    if (name != 'folderTree') {
      return
    }
    if (container == this.dropArea.nativeElement) {
      this.dropPreview = true
      this.dragOverOpenDebounceTimeout = setTimeout(() => {
        this.open()
        this.dragOverOpenDebounceTimeout = null
      }, this.dragOverOpenDebounceTime)
    }
    else if (this.parentDropArea && container == this.parentDropArea.nativeElement) {
      this.parentDropPreview = true
    }
  }

  onDragOut(event: any) {
    const { name, el, container, source } = event
    if (name != 'folderTree') {
      return
    }
    if (container == this.dropArea.nativeElement) {
      this.dropPreview = false
      if (this.dragOverOpenDebounceTimeout) {
        clearTimeout(this.dragOverOpenDebounceTimeout)
        this.dragOverOpenDebounceTimeout = null
      }
    }
    else if (this.parentDropArea && container == this.parentDropArea.nativeElement) {
      this.parentDropPreview = false
    }
  }

  showNewFolderForm() {
    this.folderFormCmp.showNew({ ancestry: this.folder.childrenAncestry() })
  }

  showEditFolderForm() {
    this.folderFormCmp.showEdit(this.folder.id)
  }
}
