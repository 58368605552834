import { BaseModel } from './base.model'
import { UserDecorator } from 'decorators'
import { intersection, flatten } from 'lodash-es'
import { Organization } from 'models/organization.model'

export class User extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    roles: [],
    organizationIds: []
  }

  firstname: string
  lastname: string
  email: string
  password: string
  role: string
  roles: string[]
  permissions: string[]
  organizationId: number
  organization: Organization
  organizationIds: number[]
  state: string

  protected decorator: UserDecorator

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  hasRole(role: string): boolean {
    return this.roles.indexOf(role) >= 0
  }

  hasAnyOfRoles(roles: string[]): boolean {
    return intersection(this.roles, flatten(roles)).length >= 0
  }

  isAdmin(): boolean {
    return this.role == 'admin'
  }

  isSuperAdmin(): boolean {
    return this.role == 'superadmin'
  }

  isContentEditor(): boolean {
    return this.role == 'content_editor'
  }

  decorate(): UserDecorator {
    if (!this.decorator) { this.decorator = new UserDecorator(this) }
    return this.decorator
  }

  can(permissions: string[]): boolean {
    permissions.push("manage:all")
    return this.permissions.some(p => permissions.indexOf(p) > -1)
  }
}
