import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'downloadAttachment' })
export class DownloadAttachmentPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    let attachmentParams = new URLSearchParams()
    let sep = value.indexOf('?') > -1 ? '&' : '?'
    attachmentParams.set('attachment', 'true')

    return `${value}${sep}${attachmentParams.toString()}`
  }
}