<div class="select multi-select" [ngClass]="{ open: isDropdownOpen, disabled: disabled }">
  <button class="select__toggle" type="button" (click)="onToggleClick($event)">
    <span>{{ caption }}</span>
    <i class="fa select__caret" [ngClass]="isDropdownOpen ? 'fa-caret-up' : 'fa-caret-down'"></i>
  </button>


  <div
    #dropdown
    class="select__dropdown"
    [ngClass]="{ up: isDropdownUp }"
    perfect-scrollbar
    [perfectScrollbarOptions]="{ 
      suppressScrollX: true,
      wheelPropagation: false
    }"
    >
    <div class="drop-section-options-search" *ngIf="showSearch && isDropdownOpen">
      <input #search type="text" class="form-control" placeholder="Искать по буквам"
      (keyup)="onKeyup(search.value)"
      (change)="$event.stopPropagation()"
      [(ngModel)]="optionsSearchTerm"
      />
    </div>

    <div class="select__opts" *ngIf="isDropdownOpen">
      <div *ngIf="options.length > 0">
        <div class="select__opt selectable" (click)="toggleSelectAll()" *ngIf="optionsSearchTerm == ''">
          <input type="checkbox" class="select__opt-checkbox" [checked]="allSelected">
          <div class="select__opt-label">Выбрать все</div>
        </div>
        
        <multi-select-option
          *ngFor="let option of filteredOptions; trackBy: trackByFn"
          [option]="option"
          [level]="0"
          [selectedValues]="selectedValues"
          (selectOption)="selectOption($event)"
          (deselectOption)="deselectOption($event)"
        ></multi-select-option>
      </div>

      <div class="select__empty" *ngIf="options.length == 0">
        Нет опций
      </div>
    </div>
  </div>
</div>