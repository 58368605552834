import { Pipe, PipeTransform } from '@angular/core'

@Pipe({name: 'highlight', pure: false})
export class HighLightPipe implements PipeTransform {
  constructor() {}

  transform(value: string, query: string): string {
    if(value.indexOf(query) > -1) {
      return 'highlight'
    }
    return ''
  }
}