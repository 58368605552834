import { BaseSerializer } from './base.serializer'
import { InteractiveContentItemTransitionTarget } from 'models/interactive-content-item-transition-target.model'

export class InteractiveContentItemTransitionTargetSerializer extends BaseSerializer<InteractiveContentItemTransitionTarget> {
  static SERIALIZABLE_ATTRIBUTES = ['_destroy', 'id', 'type', 'interactiveContentItemTransitionId', 'targetInteractiveContentItemId']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): InteractiveContentItemTransitionTarget {
    return new InteractiveContentItemTransitionTarget(attributes)
  }
}
