import { BaseSerializer } from './base.serializer'
import { Country } from 'models/country.model'
import { AppLocaleSerializer } from './app-locale.serializer';

export class CountrySerializer extends BaseSerializer<Country> {
  static SERIALIZABLE_ATTRIBUTES = [
    'name', 'licenseAgreement', 'registrationAgreement',
    "licenseAgreementTitle", "registrationAgreementTitle", "availableLocales", "availableLocaleIds",
    "showInPublicWebApi", "code"
  ]
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    availableLocales: AppLocaleSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Country {
    return new Country(attributes)
  }
}
