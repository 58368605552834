import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
  selector: 'tabs',
  styles: [
    `
    li.active > a {
      background-color: #fafafa;
    }
    `
  ],
  template:`
    <ul class="nav nav-tabs">
      <li *ngFor="let tab of tabs" [class.active]="tab.active">
        <a href="#" (click)="selectTab(tab, $event)">{{tab.title}}</a>
      </li>
    </ul>
    <ng-content></ng-content>
  `
})
export class TabsComponent implements AfterContentInit {
  
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>
  
  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter((tab) => tab.active);
    
    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first, null)
    }
  }
  
  selectTab(tab: TabComponent, event: Event){
    if (event) {
      event.preventDefault()
    }
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => tab.active = false)
    
    // activate the tab the user has clicked on.
    tab.active = true
  }

}