import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { InteractiveContentItem } from './interactive-content-item.model'
import { InteractiveContentItemSerializer } from 'serializers'
import { Observable } from 'rxjs'

@Injectable()
export class InteractiveContentItemService extends BaseModelService<InteractiveContentItem> {
  constructor() {
    super()

    this.configure({
      basePath:  'content_items',
      key:       'interactive_content_item',
      pluralKey: 'content_items',
      serializer: InteractiveContentItemSerializer
    })
  }

  buildUrl(interactiveId: number, ...params: any[]): string {
    return [this.apiHost, this.apiPath, 'interactives', interactiveId, this.basePath, ...params].join('/') + '.json'
  }

  update(item: InteractiveContentItem): Observable<Object> {
    let url = this.buildUrl(item.interactiveId, item.id)
    return this.patch(url, item)
  }

  changeFile(item: InteractiveContentItem): Observable<Object> {
    let url = this.buildUrl(item.interactiveId, item.id, 'change_file')
    return this.patch(url, item)
  }

  changePoster(item: InteractiveContentItem): Observable<Object> {
    let url = this.buildUrl(item.interactiveId, item.id, 'change_poster')
    return this.patch(url, item)
  }

  removePoster(item: InteractiveContentItem): Observable<Object> {
    let url = this.buildUrl(item.interactiveId, item.id, 'remove_poster')
    return this.delete(url)
  }
}
