import { BaseModel } from './base.model'
// import { AppTranslation } from 'models'

export class AppLocale extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    main: false
  }

  name: string
  main: boolean
  code: string
  // translations: AppTranslation[]
  translationsCount: number
  textDirection: string

}
