import { Injectable } from '@angular/core'
import { forkJoin, Observable, of, Subject } from 'rxjs'

import { BaseFolderTreeService } from 'shared/folder-tree'
import { Folder, FolderService, StaticScreen, StaticScreenService } from 'models'
import { mapTo, map, tap, catchError } from 'rxjs/operators'


@Injectable({
  providedIn: "root"
})
export class StaticScreenTreeService extends BaseFolderTreeService {
  readonly afterCreateStaticScreenSubject = new Subject<StaticScreen>()
  readonly afterUpdateStaticScreenSubject = new Subject<{ staticScreen: StaticScreen, staticScreenWas: StaticScreen }>()
  readonly afterDestroyStaticScreenSubject = new Subject<StaticScreen>()

  readonly afterCreateStaticScreen$ = this.afterCreateStaticScreenSubject.asObservable()
  readonly afterUpdateStaticScreen$ = this.afterUpdateStaticScreenSubject.asObservable()
  readonly afterDestroyStaticScreen$ = this.afterDestroyStaticScreenSubject.asObservable()
  private staticScreensIndex: { [key: number]: StaticScreen[] } = {}
  
  constructor(
    protected folderService: FolderService,
    private staticScreenService: StaticScreenService
  ) {
    super(folderService)
  }

  loadData(): Observable<boolean> {
    return forkJoin([
      this.loadFolderTree(), 
      this.loadStaticScreensIndex()
    ])
    .pipe(mapTo(true))
  }

  findStaticScreen(staticScreenId: number): StaticScreen {
    for (let folderId in this.staticScreensIndex) {
      for (let staticScreen of this.staticScreensIndex[folderId]) {
        if (staticScreen.id == staticScreenId) { return staticScreen }
      }
    }
    return null
  }

  staticScreensOf(folderId: number): StaticScreen[] {
    return this.staticScreensIndex[folderId]
  }

  loadStaticScreensOf(folderId: number): Observable<StaticScreen[]> {
    this.markFolderLoading(folderId, true)
    const params = Object.assign({}, this.fetchParams, { folderIds: folderId })
    return this.staticScreenService.index(params).pipe(
      map(data => data['staticScreens'] as StaticScreen[]),
      map(staticScreens => {
        this.staticScreensIndex[folderId] = staticScreens
        return this.staticScreensIndex[folderId]
      }),
      catchError(() => of([])),
      tap(() => this.markFolderLoading(folderId, false))
    )
  }

  staticScreenMoveable(staticScreenId: number, folderId: number): boolean {
    return folderId && folderId != this.rootFolder.id
  }

  moveStaticScreen(staticScreenId: number, targetFolderId: number): Observable<StaticScreen> {
    const params = { id: staticScreenId, folderId: targetFolderId }
    return this.staticScreenService.update(params).pipe(
      map(data => data['staticScreen'] as StaticScreen)
    )
  }

  protected fetchFolderTree(params?: Object): Observable<Folder> {
    return this.folderService.staticScreensTree(params).pipe(
      map(data => data['folder'] as Folder)
    )
  }

  protected fetchFolderSubTree(folderId: number, params?: Object): Observable<Folder> {
    return this.folderService.staticScreensSubTree(folderId, params).pipe(
      map(data => data['folder'] as Folder)
    )
  }

  protected afterOpenFolder(folderId: number): Observable<StaticScreen[]> {
    if (this.staticScreensIndex[folderId]) {
      return of(this.staticScreensIndex[folderId])
    } else {
      return this.loadStaticScreensOf(folderId)  
    }
  }

  protected afterCloseFolder(folderId: number) {
    delete this.staticScreensIndex[folderId]
  }

  private loadStaticScreensIndex(): Observable<Object> {
    if (this.openFolderIds.length > 0) {
      const params = Object.assign({}, this.fetchParams, { folderIds: this.openFolderIds.join(',') })
      return this.staticScreenService.index(params).pipe(
        map(data => data['staticScreens'] as StaticScreen[]),
        map(staticScreens => {
          this.staticScreensIndex = staticScreens.reduce((h, s) => {
            if (!h[s.folderId]) h[s.folderId] = []
            h[s.folderId].push(s)
            return h
          }, {})
          return this.staticScreensIndex
        })
      )
    } else {
      this.staticScreensIndex = {}
      return of(this.staticScreensIndex)
    }
  }
}
