import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot }  from '@angular/router'
import { Observable, of } from 'rxjs'

@Injectable()
export class InitDataLoaderResolver implements Resolve<boolean> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return of(true)
  }
}