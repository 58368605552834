import { BaseModel } from './base.model'

export class City extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    utcOffset: null
  }

  centersCount: number
  countryId: number
  id: number
  name: string
  state: string
  utcOffset: number

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
