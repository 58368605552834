import { BaseSerializer } from './base.serializer'
import { UserPermission } from 'models/user-permission.model'


export class UserPermissionsSerializer extends BaseSerializer<UserPermission> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): UserPermission {
    return new UserPermission(attributes)
  }
}
