import { BaseModel } from 'models/base.model'

export class KioskFormControlOption extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    state: 'active'
  }

  id: number
  label: string
  comment: string
  position: number
  state: string

  removed(): boolean {
    return this.state == 'removed'
  }

  active(): boolean {
    return this.state == 'active'
  }
}
