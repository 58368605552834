import { BaseModel } from 'models/base.model';

export class DictionaryItem extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  label: string
  state: string
  position: number

  isActive(): boolean {
    return this.state == "active"
  }

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  disable() {
    this.state = 'disabled'
  }

  remove() {
    this.state = "removed"
  }

  restore() {
    this.state = "active"
  }
}
