import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  SimpleChanges,
  ElementRef,
  ViewChild
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { cloneDeep, isEqual, every } from 'lodash-es'

import { PluralizeService } from 'core/i18n'
import { FormOption } from 'core'

const noop = () => {}

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true
}

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  host: {
    '(document:click)': 'onDocumentClick($event)'
  },
  styles: [`
    .drop-section-options-search {
      padding: 0 4px;
    }
  `]

})

export class MultiSelectComponent implements ControlValueAccessor {
  @ViewChild('dropdown', { static: true }) private dropdownElRef: ElementRef

  @Input('options') options: FormOption[] = []
  @Input('disabled') disabled: boolean = false

  @Output('change') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()
  @Output('touched') private onTouchedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>()

  private flatOptions: FormOption[] = []
  selectedOptions: FormOption[] = []
  selectedValues: any[] = []
  isDropdownOpen: boolean = false
  isDropdownUp: boolean = false
  allSelected: boolean = false
  optionsSearchTerm: string = ""
  filteredOptions: FormOption[] = []
  showSearch: boolean = false


  constructor(
    private elRef: ElementRef,
    private pluralizeService: PluralizeService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['options']) {
      if (!changes['options'].currentValue) this.options = []
      this.filteredOptions =  this.filterOptions(this.options, "")
      this.updateFlatOptions()
      this.updateSelectedOptions()
      this.checkAllSelected()
      this.showSearch = this.flatOptions.length >= 10
    }
  }

  // ControlValueAccessor methods

  writeValue(values: any[]) {
    if(values && !isEqual(values, this.selectedValues)) {
      this.selectedValues = values
      this.updateSelectedOptions()
      this.checkAllSelected()
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn
  }

  private onTouchedCallback: () => void = noop

  private onChangeCallback: (_: any) => void = noop

  // END ControlValueAccessor methods

  @Input('value')

  get value(): any[] {
    return this.selectedValues
  }

  set value(values) {
    if (!isEqual(values, this.selectedValues)) {
      this.selectedValues = values
      this.onChangeCallback(values)
    }
    this.resetSearch()
  }

  onKeyup(term: string): void {
    this.filteredOptions = this.filterOptions(this.options, term)
  }
  
  filterOptions(options: FormOption[], term: string): FormOption[] {
    let _options: FormOption[] = cloneDeep(options)
    let _term = term.toLowerCase()

    return _options.filter(o => {
      if (o.group) {
        o.subOptions = this.filterOptions(o.subOptions, _term)
        return o.subOptions.length > 0
      } else {
        return o.label.toLowerCase().indexOf(_term) !== -1
      }
    })
  }

  resetSearch(): void {
    this.filteredOptions = this.filterOptions(this.options, "")
    this.optionsSearchTerm = ""
  }

  trackByFn(index, item) {
    return item["group"] ? 
      item["label"] : item["value"]
  }


  selectOption(option: FormOption) {
    if (this.selectedValues.indexOf(option.value) == -1) {
      let previousValue = this.selectedValues.slice()
      this.selectedValues.push(option.value)
      this.updateSelectedOptions()
      this.checkAllSelected()
      this.onChangeCallback(this.selectedValues)
      this.onChangeEmitter.emit({ value: this.selectedValues, previousValue: previousValue })
    }
  }

  deselectOption(option: FormOption) {
    let index = this.selectedValues.indexOf(option.value)
    if(index > -1) {
      let previousValue = this.selectedValues.slice()
      this.selectedValues.splice(index, 1)
      this.updateSelectedOptions()
      this.checkAllSelected()
      this.onChangeCallback(this.selectedValues)
      this.onChangeEmitter.emit({ value: this.selectedValues, previousValue: previousValue })
    }
  }

  private selectAll() {
    let previousValue = this.selectedValues.slice()
    this.allSelected = true
    this.selectedValues = this.flatOptions.map(o => o.value)
    this.selectedOptions = this.flatOptions.slice()
    this.onChangeCallback(this.selectedValues)
    this.onChangeEmitter.emit({ value: this.selectedValues, previousValue: previousValue })
  }

  private deselectAll() {
    let previousValue = this.selectedValues.slice()
    this.allSelected = false
    this.selectedValues = []
    this.selectedOptions = []
    this.onChangeCallback(this.selectedValues)
    this.onChangeEmitter.emit({ value: this.selectedValues, previousValue: previousValue })
  }

  toggleSelectAll() {
    this.allSelected ? this.deselectAll() : this.selectAll()
  }

  onToggleClick(event) {
    if(!this.disabled) {
      this.toggleDropdown()
    }
  }

  private onDocumentClick(event) {
    if(this.isDropdownOpen && !this.elRef.nativeElement.contains(event.target)) {
      this.closeDropdown()
    }
  }

  private toggleDropdown() {
    if(this.isDropdownOpen) {
      this.closeDropdown()
    } else {
      this.openDropdown()
    }
  }

  private closeDropdown() {
    this.resetSearch()
    this.isDropdownOpen = false
    this.onTouchedCallback()
    this.onTouchedEmitter.next(true)
  }

  private openDropdown() {
    this.isDropdownOpen = true
    setTimeout(() => {
      this.updateDropdownDirection()
    })
  }

  private updateDropdownDirection() {
    let wHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    let drRect = this.dropdownElRef.nativeElement.getBoundingClientRect()
    let elRect = this.elRef.nativeElement.getBoundingClientRect()
    let drHeight = drRect.bottom - drRect.top
    this.isDropdownUp = (wHeight - elRect.bottom < drHeight) && elRect.top >= drHeight
  }
  
  get caption(): string {
    if (this.selectedOptions.length == 0) {
      return ''
    }
    else if(this.selectedOptions.length == 1) {
      return this.selectedOptions[0].label
    } else {
      return this.pluralizeService.instant('xOfTotalSelected', this.selectedOptions.length, { total: this.flatOptions.length })
    }
  }

  private updateFlatOptions() {
    this.flatOptions = this.getOptionsRecursive(this.options)
  }

  private getOptionsRecursive(options: FormOption[], result: FormOption[] = []): FormOption[] {
    for (let option of options) {
      if(!option.group && !option.disabled) {
        result.push(option)
      }
      if(option.subOptions) {
        this.getOptionsRecursive(option.subOptions, result)
      }
    }
    return result
  }

  private updateSelectedOptions() {
    this.selectedOptions = this.flatOptions.filter((option: FormOption) => {
      return this.selectedValues && this.selectedValues.indexOf(option.value) > -1
    })
  }

  private checkAllSelected() {
    this.allSelected = every(this.flatOptions, (option: FormOption) => {
      return this.selectedValues && this.selectedValues.indexOf(option.value) > -1
    })
  }
}
