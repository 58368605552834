import { cloneDeep } from 'lodash-es'

export abstract class BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  id: number

  constructor(attributes: Object = {}) {
    if (attributes['id']) {
      Object.assign(this, attributes)
    } else {
      let defaultAttributes = this.constructor['DEFAULT_ATTRIBUTES'] ? cloneDeep(this.constructor['DEFAULT_ATTRIBUTES']) : {}
      Object.assign(this, defaultAttributes, attributes)
    }
  }

  persisted(): boolean {
    return !!this.id
  }
}