import { Component, Input, Output, EventEmitter } from '@angular/core'
import { FormOption } from "core";

@Component({
  selector: 'multi-select-option',
  templateUrl: './multi-select-option.component.html'
})

export class MultiSelectOptionComponent {
  @Input() option: FormOption
  @Input() level: number
  @Input() selectedValues: any
  @Output('selectOption') selectOptionEmitter = new EventEmitter<FormOption>()
  @Output('deselectOption') deselectOptionEmitter = new EventEmitter<FormOption>()


  selectGroup(option: FormOption): void {
    option.subOptions.forEach(o => {
      if (o.group) {
        this.selectGroup(o)
      } else {
        this.selectSubOption(o)
      }
    })
  }

  deselectGroup(option: FormOption): void {
    option.subOptions.forEach(o => {
      if (o.group) {
        this.deselectGroup(o)
      } else {
        this.deselectSubOption(o)
      }
    })
  }

  selectSubOption(option: FormOption) {
    this.selectOptionEmitter.emit(option)
  }

  deselectSubOption(option: FormOption) {
    this.deselectOptionEmitter.emit(option)
  }

  toggleSelect() {
    if (!this.selectable()) { return }

    if (this.selected()) {
      this.deselectOptionEmitter.emit(this.option)
    } else {
      this.selectOptionEmitter.emit(this.option)
    }
  }

  selectable(): boolean {
    return !this.option['group'] && !this.option['disabled']
  }

  selected(): boolean {
    return this.selectedValues.indexOf(this.option['value']) > -1
  }

  trackByFn(index, item) {
    return item["group"] ? 
      item["label"] : item["value"]
  }

}
