import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core'

@Component({
  selector: 'text-search',
  template: `
    <div class="text-search-wrap">
      <input
        #input
        [type]="inputType"
        [ngClass]="inputClass"
        [(ngModel)]="value"
        (keyup)="onKeyup($event)"
      />

      <i *ngIf="showClearBtn && !isEmpty()" class="fa fa-times-circle text-search--clear" (click)="clearSearch()"></i>
    </div>
  `,
  styleUrls: ['./text-search.component.sass']
})

export class TextSearchComponent {
  @ViewChild('input', { static: true }) inputElRef: ElementRef

  @Input() value: string = ''
  @Input() inputClass: string = ''
  @Input() inputType: string = 'text'
  @Input() inputMin: number = 2
  @Input() inputDelay: number = 500
  @Input() showClearBtn = false

  @Output('onChange') onChangeEmitter = new EventEmitter<Object>()
  @Output('onClear') onClearEmitter = new EventEmitter<Object>()
  @Output('onKeyup') onKeyupEmitter = new EventEmitter<Object>()

  private debounceTimeout: any = null

  setFocus() {
    this.inputElRef.nativeElement.focus()
  }

  onKeyup(event) {
    if(this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }
    this.debounceTimeout = setTimeout(() => {
      if (this.value == null || this.value.length == 0) {
        this.onClearEmitter.emit(event)
        this.onChangeEmitter.emit({ value: null })
      } else if (this.value.length >= this.inputMin) {
        this.onChangeEmitter.emit({ value: this.value })
      }
      this.debounceTimeout = null
    }, this.inputDelay)
    this.onKeyupEmitter.emit(event)
  }

  clearSearch() {
    this.onClearEmitter.emit()
    this.onChangeEmitter.emit({ value: null })
  }

  isEmpty() {
    return this.inputElRef.nativeElement.value.length == 0
  }
}
