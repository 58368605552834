import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { UserPermission } from './user-permission.model'
import { UserPermissionsSerializer } from 'serializers'

@Injectable()
export class UserPermissionService extends BaseModelService<UserPermission> {
  constructor() {
    super()

    this.configure({
      basePath:  'user_permissions',
      key:       'userPermission',
      pluralKey: 'userPermissions',
      serializer: UserPermissionsSerializer
    })
  }
}