import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  ElementRef,
  NgZone
} from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { Constants } from "config"

import * as tinymce from "tinymce/tinymce"
import { AuthenticationService } from 'core/auth/authentication.service'


@Component({
  selector: 'tinymce',
  styleUrls: ['./tinymce.component.sass'],
  template: '<textarea #textarea></textarea>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TynyMceComponent),
      multi: true
    }
  ]
})
// Это больше не CKEditor
// Но ради обратной совместимости оставил это название
export class TynyMceComponent {
  @ViewChild('textarea', { static: true }) private textareaElRef: ElementRef
  @Input('config') private _config: Object

  private editor: any
  private _value: string = ''
  private debounceTime: number = 300
  private debounceTimeout: any = null
  editorLoaded: boolean = false

  private _defaultConfig: Object = {
    menubar: false,
    relative_urls : false,
    remove_script_host : false,
    convert_urls : true,
    height : 300,
    image_advtab: true,
    resize: true,
    statusbar: true,
    branding: false,
    elementpath: false,
    valid_children: "+audio[source],+p[div],+div[audio]",
    media_live_embeds: true,
    media_dimensions: false,
    extended_valid_elements : "video[controls|preload|width|height|data-setup],source[src|type],audio[controls]",
    // plugins: 'image table hr fullscreen code textcolor lists',
    external_plugins: {
      image: '/assets/tinymce/plugins/image/plugin.js',
      media: '/assets/tinymce/plugins/media/plugin.js',
      table: '/assets/tinymce/plugins/table/plugin.js',
      hr: '/assets/tinymce/plugins/hr/plugin.js',
      fullscreen: '/assets/tinymce/plugins/fullscreen/plugin.js',
      code: '/assets/tinymce/plugins/code/plugin.js',
      textcolor: '/assets/tinymce/plugins/textcolor/plugin.js',
      lists: '/assets/tinymce/plugins/lists/plugin.js',
      directionality: '/assets/tinymce/plugins/directionality/plugin.js',
      link: '/assets/tinymce/plugins/link/plugin.js',
    },
    content_css: ['/assets/tinymce/contents.css'],
    // theme
    theme_url: '/assets/tinymce/themes/modern/theme.min.js',
    // skin
    skin_url: '/assets/tinymce/skins/lightgray',
    toolbar: [
      'undo redo | table image media hr link | fullscreen code | styleselect fontsizeselect | ltr rtl',
      'bold italic underline strikethrough | removeformat | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent'
    ],
    style_formats: [
      { title: 'Обычное',     format: 'p' },
      { title: 'Заголовок 1', format: 'h1' },
      { title: 'Заголовок 2', format: 'h2' },
      { title: 'Заголовок 3', format: 'h3' },
      { title: 'Заголовок 4', format: 'h4' },
      { 
        title: 'Важное',      
        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
        classes: 'notice'
      },
      {
        title: "Во всю ширину",
        selector: 'img',
        styles: {
          width: '100%',
          height: 'auto'
        }
      },
      {
        title: 'Image Left',
        selector: 'img',
        styles: {
          'float': 'left',
          'margin': '0 10px 0 10px'
        }
      },
      {
        title: 'Image Right',
        selector: 'img',
        styles: {
          'float': 'right',
          'margin': '0 0 10px 10px'
        }
      },
      { title: 'Маркер', inline: 'span', classes: 'marker' },
      {
        title: "Невидимая таблица",
        selector: 'table',
        styles: {
          'border-color': 'transparent'
        }
      }
    ],

    images_upload_handler: (blobInfo, success, failure) => {
      let xhr = new XMLHttpRequest();

      xhr.open('POST', `${Constants.API.HOST}/${Constants.API.PATH}/assets_uploads`);

      function setHeaders(headers){
        for(let key in headers){
          xhr.setRequestHeader(key, headers[key])
        }
      }

      setHeaders({
        'access-token': this.authentication.currentAuthData.accessToken,
        'client':       this.authentication.currentAuthData.client,
        'expiry':       this.authentication.currentAuthData.expiry,
        'token-type':   this.authentication.currentAuthData.tokenType,
        'uid':          this.authentication.currentAuthData.uid
      })

      xhr.onload = function() {
        if (xhr.status !== 200) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }

        let json = JSON.parse(xhr.responseText);

        success(json.file.src);
      };

      let formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      formData.append('type', 'image');

      xhr.send(formData);
    },

    file_picker_types: 'media',

    file_picker_callback: (cb, value, meta) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'video/*')
      
      input.onchange = () => {
        const file = input.files[0]
        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${Constants.API.HOST}/${Constants.API.PATH}/assets_uploads`);

        function setHeaders(headers){
          for(let key in headers){
            xhr.setRequestHeader(key, headers[key])
          }
        }
    
        setHeaders({
          'access-token': this.authentication.currentAuthData.accessToken,
          'client':       this.authentication.currentAuthData.client,
          'expiry':       this.authentication.currentAuthData.expiry,
          'token-type':   this.authentication.currentAuthData.tokenType,
          'uid':          this.authentication.currentAuthData.uid
        })

        xhr.onload = function() {
          if (xhr.status !== 200) {
            return
          }
  
          let json = JSON.parse(xhr.responseText)
  
          cb(json.file.src)
        }
    
        let formData = new FormData()
        formData.append('file', file, file.name)
        formData.append('type', 'video')
  
        xhr.send(formData)
      }
  
      input.click()
    },

    video_template_callback: (data) => {
      return `
        <video controls="controls">
          <source src="${data.source1}" type="${data.source1mime}"/>
        </video>
      `
    },

    audio_template_callback: (data) => {
      return `
        <audio controls="controls" source1="${data.source1}" source1mime="${data.source1mime}">
          <source src="${data.source1}" type="${data.source1mime}"/>
        </audio>
      `
    }
  }
  
  constructor(private zone: NgZone, private authentication: AuthenticationService) {
    window['tinymce'] = tinymce
  }

  ngAfterViewInit() {
    this.initEditor()
  }

  ngOnDestroy() {
    this.destroyEditor()
  }

  // ControlValueAccessor methods

  writeValue(value) {
    this._value = value 
    if (this.editorLoaded) {
      this.editor.setContent(this.value, { no_events: true })
    }
  }

  onChange(_) {}

  onTouched() {}

  registerOnChange(fn: any) { this.onChange = fn }

  registerOnTouched(fn: any) { this.onTouched = fn }

  // END ControlValueAccessor methods

  get value(): string {
    return this._value || ''
  }

  @Input() set value(v) {
    if (v !== this._value) {
      this._value = v
      this.onChange(v)
    }
  }

  initEditor() {
    if (this.editorLoaded) {
      console.error("CKEditor already initialized")
      return
    }
    const content_css = [
      ...this._defaultConfig["content_css"], 
      ...(this._config["content_css"] || [])
    ]
    let editorConfig = Object.assign({
      target: this.textareaElRef.nativeElement
    }, this._defaultConfig, this._config, { content_css })

    tinymce.init(editorConfig)
      .then(editors => {
        let editor = editors[0]

        this.editor = editors[0]

        editor.on('Focus', () => {
          this.onTouched()
        })

        editor.on('Paste KeyPress input Undo Redo NodeChange', () => {
          this.onTouched()
          let value = this.editor.getContent()
          this.value = value
        })

        editor.setContent(this.value, { no_events: true })

        this.editor = editor
        this.editorLoaded = true
      })
  }

  destroyEditor() {
    if (this.editor && this.editorLoaded) {
      this.editor.destroy()
      this.editor = null
      this.editorLoaded = false
    }
  }

  filebrowserBrowseUrl(): string {
    const authData = this.authentication.currentAuthData
    const params = new URLSearchParams()
    params.set('uid', authData.uid)
    params.set('client', authData.client)
    params.set('access-token', authData.accessToken)
    return '/web_api/file_manager?' + params.toString()
  }
}
