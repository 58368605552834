import { Component, Input, Output, EventEmitter, forwardRef, SimpleChanges } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { FormOption } from 'core'

const noop = () => {}

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FlatSelectComponent),
  multi: true
}

//
// Multi select had not tested!!!
//
@Component({
  selector: 'flat-select',
  template: `
<div class="flat-select">
  <div class="flat-select__option" *ngFor="let option of options;"
    [ngClass]="{ 
      'flat-select__option_selected': selected(option), 
      'flat-select__option_untagged': untagged(option) 
    }"
    (click)="select(option)">
    {{ option.label }}
  </div>
</div>
  `,
  styles: [`
    .flat-select {
      display: flex;
      flex-wrap: wrap;
    }
    .flat-select .flat-select__option {
      padding: 10px 17px;
      margin: 10px;
      background: #EAEAEA;
      border: 2px solid #D6D6D6;
      box-sizing: border-box;
      border-radius: 4px;
      min-width: 60px;
      text-align: center;
      cursor: default;
    }
    .flat-select .flat-select__option.flat-select__option_selected {
      border: 2px solid #FCB000;
    }
    .flat-select .flat-select__option_untagged {
      border: 2px solid #F5F5F5;
      background: #F5F5F5;
    }
  `],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class FlatSelectComponent implements ControlValueAccessor {
  @Input('options') options: FormOption[] = []
  @Input('disabled') private disabled: boolean = false
  @Input('multi') private multi: boolean = false
  @Input('value')

  @Output('change') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()

  private itemWidth: String = null
  private selectedOption: Object
  private selectedValue: any
  private selectedOptionList: Object[]
  private selectedValueList: any[]

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.updateSelection()
    }
  }

  // BEGIN ControlValueAccessor methods

  writeValue(value: any) {
    if(value === this.selectedValue) return
    this.selectedValue = value
    this.updateSelection()
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn
  }

  private onTouchedCallback: () => void = noop

  private onChangeCallback: (_: any) => void = noop

  // END ControlValueAccessor methods


  get value(): any {
    if (this.multi) {
      return this.selectedValueList
    } else {
      return this.selectedValue
    }
  }

  set value(v) {
    if (this.multi) {
      if (v === this.selectedValueList) return
      if (v instanceof Array) {
        this.selectedValueList = v
      } else {
        if (!this.selectedValueList) this.selectedValueList = []
        this.selectedValueList.push(v)
      }
    } else {
      if (v === this.selectedValue) return
      this.selectedValue = v
    }
    this.onChangeCallback(v)
  }

  select(option: Object) {    
    if (this.multi) {
      this.selectMulti(option)
    } else {
      this.selectSingle(option)
    }
  }

  selectSingle(option: Object) {
    if(option['value'] == this.selectedValue) return
    let previousValue = this.selectedValue
    this.selectedOption = option
    this.selectedValue = option['value']
    this.onChangeCallback(option['value'])
    this.onChangeEmitter.emit({ value: option['value'], previousValue: previousValue })
  }

  selectMulti(option: Object) {
    let index = this.selectedValueList.indexOf(option['value'])
    if(index != -1) {
      delete this.selectedValueList[index]
      return
    }
    let previousValue = this.selectedValueList.slice()
    this.selectedValueList.push(option['value'])
    this.updateSelection()
    this.onChangeCallback(this.selectedValueList)
    this.onChangeEmitter.emit({ value: this.selectedValueList, previousValue: previousValue })
  }

  selected(option: Object) {
    if (this.multi) {
      return this.selectedOptionList.includes(option)
    } else {
      return this.selectedOption == option
    }
  }

  untagged(option: any) {
    return Object.keys(option).includes('tagged') && option['tagged'] == false
  }

  private updateSelection() {
    if (this.multi) {
      if (!this.selectedValueList) this.selectedValueList = []
      this.selectedOptionList = this.options.filter(o => this.selectedValueList.indexOf(o['value']) > -1)
    } else {
      this.selectedOption = this.options.find(o => o['value'] === this.selectedValue)
    }
  }

  private caption(option: any): string {
    return option['label'] || option['value']
  }
}
