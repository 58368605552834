import { FormControl } from '@angular/forms'

export class PatternValidator {
  constructor(private regexp: RegExp) {
    this.validate = this.validate.bind(this)
  }

  validate(control: FormControl): Object {
    if(!control.value || this.regexp.test(control.value)) {
      return null
    } else {
      return { pattern: true }
    }
  }
}
