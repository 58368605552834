import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { SharedModule } from "shared";
import { ConfirmComponent } from "./confirm.component";
import { ConfirmService } from "./confirm.service";


@NgModule({
  imports: [SharedModule],
  declarations: [ConfirmComponent],
  exports: [ConfirmComponent]
})
export class ConfirmModule {
  constructor (@Optional() @SkipSelf() parentModule: ConfirmModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }

  static forRoot(): ModuleWithProviders<ConfirmModule> {
    return {
      ngModule: ConfirmModule,
      providers: [
        ConfirmService
      ]
    }
  }
}