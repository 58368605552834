import { BaseSerializer } from './base.serializer'
import { ProgramSectionTransition } from 'models'
import { ProgramSectionTransitionConditionSerializer } from './program-section-transition-condition.serializer'

export class ProgramSectionTransitionSerializer extends BaseSerializer<ProgramSectionTransition> {
  static SERIALIZABLE_ATTRIBUTES = [
    'id',
    'uuid',
    'name', 
    'kind', 
    'position', 
    'color', 
    'sectionUuid', 
    'nextUuid',
    'conditions',
    '_destroy', 
  ]
  static SERIALIZERS = {
    conditions: ProgramSectionTransitionConditionSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    'sectionUuid': 'programSectionUuid', 
    'nextUuid': 'nextProgramSectionUuid',
    'kind': 'type',
    'conditions': 'conditionsAttributes'
  }

  static DESERIALIZERS = {
    conditions: ProgramSectionTransitionConditionSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): ProgramSectionTransition {
    return new ProgramSectionTransition(attributes)
  }
}
