import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BaseModelService } from './base-model.service'
import { Folder } from './folder.model'
import { FolderSerializer } from 'serializers'

@Injectable()
export class FolderService extends BaseModelService<Folder> {
  constructor() {
    super()

    this.configure({
      basePath:  'folders',
      key:       'folder',
      pluralKey: 'folders',
      serializer: FolderSerializer
    })
  }

  destroyWithContents(obj: Folder): Observable<Object> {
    return this.destroy(obj, { destroyContents: true })
  }

  interactivesTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('interactives')
    return this.get(url, params)
  }

  interactivesSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('interactives', ancestorId)
    return this.get(url, params)
  }

  lessonQuizzesTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('lesson_quizzes')
    return this.get(url, params)
  }

  lessonQuizzesSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('lesson_quizzes', ancestorId)
    return this.get(url, params)
  }

  lessonQuestionsTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('lesson_questions')
    return this.get(url, params)
  }

  lessonQuestionsSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('lesson_questions', ancestorId)
    return this.get(url, params)
  }

  videosTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('lesson_videos')
    return this.get(url, params)
  }

  videosSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('lesson_videos', ancestorId)
    return this.get(url, params)
  }

  audiosTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('audios')
    return this.get(url, params)
  }

  audiosSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('audios', ancestorId)
    return this.get(url, params)
  }

  speechesTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('speeches')
    return this.get(url, params)
  }

  speechesSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('speeches', ancestorId)
    return this.get(url, params)
  }

  programsTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('programs')
    return this.get(url, params)
  }

  programsSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('programs', ancestorId)
    return this.get(url, params)
  }

  skillsTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('skills')
    return this.get(url, params)
  }

  examsTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('exams')
    return this.get(url, params)
  }

  sectionsTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('exams_sections')
    return this.get(url, params)
  }

  skillsSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('skills', ancestorId)
    return this.get(url, params)
  }

  examsSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('exams', ancestorId)
    return this.get(url, params)
  }

  sectionsSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('exams_sections', ancestorId)
    return this.get(url, params)
  }

  staticScreensTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('static_screens')
    return this.get(url, params)
  }

  staticScreensSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('static_screens', ancestorId)
    return this.get(url, params)
  }
  
  /**
   * Interviews
   */
  interviewsTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('interviews')
    return this.get(url, params)
  }

  interviewsSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('interviews', ancestorId)
    return this.get(url, params)
  }

  programResultScreensTree(params?: Object): Observable<Object> {
    let url = this.buildUrl('program_result_screens')
    return this.get(url, params)
  }

  programResultScreensSubTree(ancestorId: number, params?: Object): Observable<Object> {
    let url = this.buildUrl('program_result_screens', ancestorId)
    return this.get(url, params)
  }
}
