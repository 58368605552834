import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core'
import { DropComponent } from 'shared/components/drop.component'
import { find, isNumber, isString } from 'lodash-es'
import { Moment } from 'moment'
import * as moment from 'moment'

export interface Preset {
  label: string
  fromValue?: Moment
  untilValue?: Moment
}

@Component({
  selector: 'month-search',
  templateUrl: './month-search.component.html'
})
export class MonthSearchComponent {
  @ViewChild('drop', { static: true }) dropElRef: DropComponent
  @Input('disabled') private disabled: boolean = false
  @Input('defaultCaption') private defaultCaption: string = 'Текущий месяц'
  @Input('presets') public presets: Preset[]

  @Output('change') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()

  constructor() { }

  private selectedValue: Moment
  private submittedValue: Moment

  private defaultPresets: Preset[] = [
    { label: 'Текущий месяц', fromValue: moment().startOf('month')}
  ]

  public caption: string
  public isPickerOpen: boolean = false

  ngOnInit() {
    if(!this.presets) {
      this.presets = this.defaultPresets
    }
    if (this.presets[0] && !this.submittedValue){
      this.submittedValue = this.presets[0]['fromValue']
      this.updateSelectedValues()
      this.updateCaption()
      this.submit()
    }
    this.updateSelectedValues()
    this.updateCaption()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['fromValue']) {
      this.updateSelectedValues()
      this.updateCaption()
    }
  }

  @Input('fromValue')
  set fromValue(_value) {
    let value = this.parseInputValue(_value)
    this.submittedValue = this.selectedValue = value
    this.updateCaption()
  }

  selectPreset(preset: Object) {
    this.selectedValue = preset['fromValue']
    this.submit()
  }

  onPickerClick(event: Event) {
    event.stopPropagation()
  }

  onPickerSelect(date) {
    this.selectedValue = date ? moment(date).startOf('month') : null
    this.closePicker()
  }

  togglePicker() {
    this.isPickerOpen ? this.closePicker() : this.openPicker()
  }

  private openPicker() {
    this.isPickerOpen = true
  }

  private closePicker() {
    this.isPickerOpen = false
  }

  private updateCaption() {
    let value  = this.submittedValue
    let preset = find(this.presets, (p) => value == p['value'])
    if(preset) {
      this.caption = preset['label']
    }
    else if(value) {
      this.caption = `${value.format('MM.YY')}`
    }
    else {
      this.caption = this.defaultCaption
    }
  }

  private updateSelectedValues() {
    this.selectedValue = this.submittedValue
  }

  public submit(): void {
    this.onChangeEmitter.emit({ fromValue: this.selectedValue })
    this.dropElRef.closeDropdown()
  }

  public cancel(): void {
    this.dropElRef.closeDropdown()
  }

  public onCloseDropDown(e: Event): void {
    this.closePicker()
    this.updateSelectedValues()
    this.updateCaption()
  }

  private parseInputValue(value: any): Moment {
    if(!value) {
      return null
    }
    else if(value instanceof moment) {
      return value as Moment
    }
    else if(value instanceof Date) {
      return moment(value)
    }
    else if(isNumber(value)) {
      return moment.unix(value)
    }
    else if(isString(value)) {
      return moment(value, 'DD-MM-YYYY')
    } else {
      return null
    }
  }

  pickerValue(): Date {
    return this.selectedValue ? this.selectedValue.toDate() : null
  }


  textFieldValue(): string {
    return this.selectedValue ? this.selectedValue.format('MM.YY') : null
  }
}
