import { BaseModel } from './base.model'

export class Organization extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    name: ''
  }

  id: number
  name: string
  state: string

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
