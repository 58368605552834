import { APP_INJECTOR } from 'app.module'
import { Title } from '@angular/platform-browser'
import { AlertService } from 'core/alert'
import { Constants } from 'config'
import { Component } from '@angular/core'
import { ConfirmService } from 'core/confirm/confirm.service'

@Component({
  template: ''
})
export abstract class BaseWebviewPageComponent {
  protected alertService: AlertService
  protected confirmService: ConfirmService
  protected titleService: Title
  protected baseTitle: string = Constants.APP.NAME

  protected loading: boolean
  protected scrollTop: number = 0

  constructor() {
    this.titleService = APP_INJECTOR.get(Title)
    this.alertService   = APP_INJECTOR.get(AlertService)
    this.confirmService = APP_INJECTOR.get(ConfirmService)
  }

  ngOnInit() {}

  ngOnDestroy() {}

  protected setTitle(title: string) {
    this.titleService.setTitle(title ? `${ title } - ${ this.baseTitle }` : this.baseTitle)
  }
}
