import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ModalModule } from 'ngx-bootstrap/modal'
import { DatepickerModule } from 'ngx-bootstrap/datepicker'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { PaginationModule } from 'ngx-bootstrap/pagination'
import { ProgressbarModule } from 'ngx-bootstrap/progressbar'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { DragulaModule } from 'ng2-dragula'
import { TranslateModule } from '@ngx-translate/core'
import { ClipboardModule } from 'ngx-clipboard'
import { COMMON_COMPONENTS } from './components'
import { COMMON_DIRECTIVES } from './directives'
import { FOLDER_TREE_COMPONENTS } from './folder-tree'
import { COMMON_PIPES } from './pipes'
import { FORM_COMPONENTS } from './forms'
import { SEARCH_COMPONENTS } from './search'
import { ColorPickerModule } from 'ngx-color-picker'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    PaginationModule,
    TooltipModule,
    DragulaModule.forRoot(),
    TranslateModule,
    ClipboardModule,
    ColorPickerModule,
    BsDropdownModule.forRoot(),
    DatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    AccordionModule.forRoot()
  ],
  declarations: [
    ...COMMON_COMPONENTS,
    ...COMMON_DIRECTIVES,
    ...FOLDER_TREE_COMPONENTS,
    ...COMMON_PIPES,
    ...FORM_COMPONENTS,
    ...SEARCH_COMPONENTS
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    PaginationModule,
    TooltipModule,
    DragulaModule,
    TranslateModule,
    ClipboardModule,
    BsDropdownModule,
    ProgressbarModule,
    PopoverModule,
    AccordionModule,
    ColorPickerModule,

    ...COMMON_COMPONENTS,
    ...COMMON_DIRECTIVES,
    ...FOLDER_TREE_COMPONENTS,
    ...COMMON_PIPES,
    ...FORM_COMPONENTS,
    ...SEARCH_COMPONENTS
  ],
  providers: []
})

export class SharedModule { }
