import { Directive, ElementRef } from '@angular/core'

@Directive({
  selector: '[mainContent]',
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class MainContentDirective {
  constructor(private elRef: ElementRef) {}

  ngAfterViewChecked() {
    this.adjustPosition()
  }

  private onResize(event) {
    this.adjustPosition()
  }

  private adjustPosition() {
    let headerEl = document.querySelector('[mainHeader]')
    let footerEl = document.querySelector('[mainFooter]')
    let marginTop = headerEl ? headerEl.clientHeight : 0
    let marginBottom = footerEl ? footerEl.clientHeight : 0
    this.elRef.nativeElement.style.paddingTop = `${marginTop}px`
    this.elRef.nativeElement.style.paddingBottom = `${marginBottom}px`
  }
}