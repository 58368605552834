import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { Country } from './country.model'
import { CountrySerializer } from 'serializers'

@Injectable({
  providedIn: "root"
})
export class CountryService extends BaseModelService<Country> {
  constructor() {
    super()

    this.configure({
      basePath:  'countries',
      key:       'country',
      pluralKey: 'countries',
      serializer: CountrySerializer
    })
  }
}
