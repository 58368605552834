import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'

import { AbExperimentSerializer } from 'serializers'
import { Observable } from 'rxjs'
import { AbExperiment } from './ab-experiment.model'

@Injectable()
export class AbExperimentService extends BaseModelService<AbExperiment> {
  constructor() {
    super()
    this.configure({
      basePath:  'ab_experiments',
      key:       'abExperiment',
      pluralKey: 'abExperiments',
      serializer: AbExperimentSerializer
    })
  }

  start(id: number): Observable<Object> {
    let url = this.buildUrl(id, 'start')
    return this.patch(url)
  }

  finish(id: number): Observable<Object> {
    let url = this.buildUrl(id, 'finish')
    return this.patch(url)
  }

  generalStatistic(id: number): Observable<Object> {
    const url = this.buildUrl(id, 'general_statistic')
    return this.get(url)
  }

  centerStatistic(id: number, params: Object): Observable<Object> {
    let url = this.buildUrl(id, 'center_statistic')
    return this.get(url, params)
  }
}