import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthorizationService } from 'core/auth/authorization.service'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

@Injectable()
export class RouterAuthorizer implements CanActivate {
  constructor(
    private router: Router,
    private authorization: AuthorizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const authData = route.data['authorization']

    return this.authorization.currentUser$.pipe(
      take(1),
      map(currentUser => {
        const [scope, action, targetObject] = authData
        const allowed = this.authorization._allowed(currentUser, scope, action, targetObject)
        if (!allowed) {
          this.router.navigateByUrl("/")
        }
        return allowed
      })
    )
  }
}