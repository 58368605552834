import { Component, Input } from "@angular/core"
import { AbstractControlDirective, AbstractControl } from '@angular/forms'

export interface ValidationMessageParams {
  message: string
}

@Component({
  selector: "show-errors",
  template: `
    <ng-container *ngIf="shouldShowErrors">
      <div class="validation-msg" *ngFor="let error of listOfErrors">{{ error }}</div>
    </ng-container>
  `
})
export class ShowErrorsComponent {
  @Input() control: AbstractControlDirective | AbstractControl

  get shouldShowErrors(): boolean {
    return this.control && this.control.errors && this.control.touched
  }

  get listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]))
  }

  private getMessage(type: string, params: any) {
    if (type in ShowErrorsComponent.errorMessages) {
      return ShowErrorsComponent.errorMessages[type](params)
    }
    else if (params instanceof Object && "message" in params) {
      return params.message
    }
    else {
      return params
    }
  }

  private static readonly errorMessages = {
    'required': (params) => params.message,
    'max': params => params.message,
    'minlength': (params) => params.message,
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'pattern': (params) => 'Не верный формат',
    'period': (params) => 'Период задан неверно',
    'years': (params) => params.message,
    'countryCity': (params) => params.message,
    'uniqueName': (params) => params.message,
    'telephoneNumbers': (params) => params.message,
    'telephoneNumber': (params) => params.message,
    minArrayLength: (params: ValidationMessageParams): string => params.message,
    multiSelectMinLength: (params: ValidationMessageParams): string => params.message,
    min: (params: ValidationMessageParams): string => params.message,
    maxFormArrayLength: (params: ValidationMessageParams): string => params.message
  }
}