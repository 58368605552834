import { BaseSerializer } from './base.serializer'
import { SessionKioskForm } from 'models/session-kiosk-form.component'
import { KioskFormSerializer } from 'models/kiosk-forms/kiosk-form.serializer'
import { KioskFormControlAnswerSerializer } from './kiosk-form-control-answer.serializer'


export class SessionKioskFormSerializer extends BaseSerializer<SessionKioskForm> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    form: KioskFormSerializer,
    answers: KioskFormControlAnswerSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<SessionKioskForm>): SessionKioskForm {
    return new SessionKioskForm(attributes)
  }
}
