import { BaseModel } from './base.model'

export class Attachment extends BaseModel {
    static DEFAULT_ATTRIBUTES = {
    _destroy: false
  }

  _destroy: boolean

  originalFilename: string
  url: string
  attachment: Object
  fileType: string
}
