import { Injectable } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import { Observable } from 'rxjs'
import { ConfirmComponent } from './confirm.component'

export interface ConfirmOptions {
  title: string
  text?: string
  html?: string
  confirmBtnText: string
  cancelBtnText?: string
}


@Injectable()
export class ConfirmService {

  constructor(
    private modalService: BsModalService
  ) {}

  show(options: ConfirmOptions): Observable<boolean> {
    let defaultOptions: Partial<ConfirmOptions> = {
      title: 'Подтвердите',
      confirmBtnText: 'Подтвердить',
      cancelBtnText: 'Отменить'
    }

    const confirmOptions = { ...defaultOptions, ...options }
    const modalRef = this.modalService.show(ConfirmComponent, { 
      initialState: confirmOptions,
      class: 'modal-sm'
    })
    const cmp: ConfirmComponent = modalRef.content

    return cmp.resolve$
  }
}
