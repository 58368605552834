import { FormArray, FormGroup, ValidationErrors } from '@angular/forms'
import { Observable, of } from 'rxjs'

export class ProgramRegistrationFieldValidator {

  constructor(private requiredFields: string[] = []) {
    this.validate = this.validate.bind(this)
  }

  validate(control: FormArray): Observable<ValidationErrors | null> {
    let requiredFields = control.controls.filter(
      (control: FormGroup) => {
        return this.requiredFields.indexOf(control.controls['name'].value) > -1 && control.controls['required'].value
      }
    )

    const isValid = requiredFields.length > 0

    return of(isValid ? null : { 'requiredFields': true })
  }
}
