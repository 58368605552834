import { BaseModel } from './base.model'

export class Audio extends BaseModel{
  static DEFAULT_ATTRIBUTES = {}

  comment: string
  content: string
  file: File
  folderId: number
  id: number
  name: string
  state: string
  uploadFileUrl: string
  url: string

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  filename(): string {
    if(!this.url) {
      return null
    } else {
      return this.url.split('/').pop().split('?')[0]
    }
  }
}
