import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
 } from '@angular/common/http'
 import { Observable, throwError, of } from 'rxjs'
 import { catchError } from 'rxjs/operators'
 import { AuthorizationService } from './auth/authorization.service'
 import { Router } from '@angular/router'
 import { Constants } from 'config'
 
 @Injectable()
 export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((resp: HttpErrorResponse) => {
          if (resp instanceof HttpErrorResponse && resp.status === 401) {
            this.router.navigateByUrl(Constants.PAGE_PATHS.SIGN_IN)
            return throwError(resp)
          }

          if (resp instanceof HttpErrorResponse && resp.status === 403) {
            const error = resp.error
            
            if (error.error == "organization_missmatch") {
              const { organization } = resp.error.error_description
              this.authorizationService.setOrganizationMissMatch(organization)
            }
            else if (error.error == "role_missmatch") {
              const { role, roles } = resp.error.error_description
              this.authorizationService.setRoleMissMatch({ role, roles })
            }
            return of(null)
          }

          return throwError(resp)
        })
      )
  }
 }