import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { AppLocale } from './app-locale.model'
import { AppLocaleSerializer } from 'serializers'

@Injectable()
export class AppLocaleService extends BaseModelService<AppLocale> {
  constructor() {
    super()

    this.configure({
      basePath:  'app_locales',
      key:       'appLocale',
      pluralKey: 'appLocales',
      serializer: AppLocaleSerializer
    })
  }
}
