import { BaseModel } from './base.model'
import { Question } from './question.model'

export class QuizQuestion extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  _destroy: boolean
  id: number
  position: number
  questionId: number
  question: Question
}
