import { BaseSerializer } from 'serializers/base.serializer'
import { Program } from 'models/program.model'
import { ProgramSectionSerializer } from 'serializers/program-section.serializer'
import { CustomThemeSerializer } from "./custom-theme.serializer"

export class ProgramSerializer extends BaseSerializer<Program> {
  static SERIALIZABLE_ATTRIBUTES = [
    'name',
    'comment',
    'folderId',
    'sections',
    'registrationFormConfig',
    'webHookId',
    'redirectUrl',
    'passLimitation',
    'autocancelTimeout',
    'needYaIdAuth',
    'customTheme'
  ]
  static SERIALIZERS = {
    sections: ProgramSectionSerializer,
    customTheme: CustomThemeSerializer
  }
  static SERIALIZE_RENAME_KEYS = {
    sections: 'sectionsAttributes',
  }

  static DESERIALIZERS = {
    sections: ProgramSectionSerializer,
    customTheme: CustomThemeSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<Program>): Program {
    return new Program(attributes)
  }
}
