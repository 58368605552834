import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { BaseModelService } from './base-model.service'

@Injectable()
export class StatisticsService extends BaseModelService<Object> {
  constructor() {
    super()

    this.configure({
      basePath:  'statistics',
      key:       'statistic',
      pluralKey: 'statistics'
    })
  }

  getTodayMetrics(): Observable<Object> {
    let url = this.buildUrl('today_metrics_statistics')
    return this.get(url)
  }

  getHubRegistrationStatistics(params?: Object):Observable<Object> {
    let url = this.buildUrl('hub_registration_statistics')
    return this.get(url, params)
  }

  getInterviewAllStatistics(params?: Object):Observable<Object> {
    let url = this.buildUrl('interview_all_statistics')
    return this.get(url, params)
  }

  getInterviewUserStatistics(params?: Object):Observable<Object> {
    let url = this.buildUrl('interview_user_statistics')
    return this.get(url, params)
  }

  getHourAverageForAccessors(params?: Object):Observable<Object> {
    let url = this.buildUrl('hour_average_accessor_statistics')
    return this.get(url, params)
  }

  getHourAverageForVideoAccessors(params?: Object):Observable<Object> {
    let url = this.buildUrl('hour_average_videoaccessor_statistics')
    return this.get(url, params)
  }

  getDaysStatisticsForAccessors(params?: Object):Observable<Object> {
    let url = this.buildUrl('days_accessor_statistics')
    return this.get(url, params)
  }

  getModeratorStatistics(params?: Object):Observable<Object> {
    let url = this.buildUrl('moderator_statistics')
    return this.get(url, params)
  }

  getSessionStatisticsForAccessors(params?: Object):Observable<Object> {
    let url = this.buildUrl('session_statistics')
    return this.get(url, params)
  }

  getDaysStatisticsForVideoAccessors(params?: Object):Observable<Object> {
    let url = this.buildUrl('days_videoaccessor_statistics')
    return this.get(url, params)
  }

  getCenterStatistics(params?: Object):Observable<Object> {
    let url = this.buildUrl('center_statistics')
    return this.get(url, params)
  }

  getErrorsStatistics(params?: Object):Observable<Object> {
    let url = this.buildUrl('errors_statistics')
    return this.get(url, params)
  }
}
