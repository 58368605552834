import { FormControl, ValidatorFn } from '@angular/forms'

export function maxValidator(max: number, message: string = ""): ValidatorFn {
  return (c: FormControl): Object => {
    if (!c.value && c.value != 0) { return null }
    let value = parseFloat(c.value)

    if ((value || value == 0) && value > max) {
      if (message.length == 0) {
        message = `Максимальное значение: ${max}`
      } else {
        message = message
          .replace("{requiredMax}", max.toString())
          .replace("{actualValue}", value.toString())
      }
      return { 
        max: { 
          requiredMax: max, 
          actualValue: value,
          message
        } 
      }
    }
    else if(!value && value != 0) {
      return { invalid: true }
    } else {
      return null
    }
  }
}