import { FormArray, ValidationErrors } from '@angular/forms'

export class UniquenessValidator {
  constructor(private nestedControlName: string = null) {
    this.validate = this.validate.bind(this)
  }

  validate(control: FormArray): ValidationErrors {
    let values;
    if (this.nestedControlName) {
      values = control.controls.map(control => control['controls'][this.nestedControlName].value)
    } else {
      values = control.controls.map(control => control.value)
    }
    const isValid = new Set(values).size === values.length

    return isValid ? null : { 'uniqueness': true }
  }
}
