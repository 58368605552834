import { BaseModel } from 'models/base.model';
import { DictionaryItem } from './dictionary-item.model';

export type VariableType = 
  "phone" |
  "email" |
  "telegram" |
  "city" |
  "fio" |
  "date" |
  "string" |
  "number" |
  "dictionary" |
  "boolean"

export class Dictionary extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    items: []
  }

  name: string
  label: string
  comment: string
  state: string
  items: DictionaryItem[]
  variableType: VariableType

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
