import { BaseModel } from './base.model'

export class ApiKey extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  uuid: string
  id: number
  label: string
  status: string

  get disabled() {
    return this.status == 'disabled'
  }

  get activated() {
    return this.status == 'activated'
  }

  get removed() {
    return this.status == 'removed'
  }

  get isAwaiting(): boolean {
    return this.status == 'awaiting'
  }
}
