import { Component, Input, ViewChild, ElementRef } from '@angular/core'

@Component({
  selector: 'sub-main-content-loader',
  host: {
    '(window:resize)': 'onResize($event)'
  },
  template: `
    <div class="loader content__loader" [hidden]="!visible" #loader>
      <div class="loader__overlay"></div>
      <div class="loader__spinner">
        <div class="loader__spinner-i"></div>
      </div>
    </div>
  `
})

export class SubMainContentLoaderComponent {
  @ViewChild('loader', { static: true }) private loaderElRef: ElementRef

  @Input() visible: boolean = false
  @Input() wrapperClass: string = ''

  constructor() {}

  ngAfterViewChecked() {
    this.adjustPosition()
  }

  private onResize(event) {
    this.adjustPosition()
  }

  private adjustPosition() {
    let headerEl = document.querySelector('[subMainHeader]')
    let footerEl = document.querySelector('[subMainFooter]')
    let top = headerEl ? headerEl.clientHeight : 0
    let bottom = footerEl ? footerEl.clientHeight : 0
    this.loaderElRef.nativeElement.style.top = `${top}px`
    this.loaderElRef.nativeElement.style.bottom = `${bottom}px`
  }
}
