import { Injectable } from '@angular/core'
import { BaseModelService } from './base-model.service'
import { City } from './city.model'
import { CitySerializer } from 'serializers'
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs'
import { FormOption, FormOptionsBuilder } from 'core'
import { map } from 'rxjs/operators'
import { CountryService } from './country.service'
import { Country } from './country.model'

@Injectable({
  providedIn: "root"
})
export class CityService extends BaseModelService<City> {
  private citiesOptions = new BehaviorSubject<FormOption[]>([])
  readonly citiesOptions$ = this.citiesOptions.asObservable()

  constructor(
    private countryService: CountryService
  ) {
    super()

    this.configure({
      basePath:  'cities',
      key:       'city',
      pluralKey: 'cities',
      serializer: CitySerializer
    })

    forkJoin([
      this.shortIndex().pipe(map(data => data["cities"] as City[])),
      this.countryService.index().pipe(map(data => data["countries"] as Country[]))
    ]).pipe(
      map((data: [City[], Country[]]) => {
        const [cities, countries] = data
        return FormOptionsBuilder.cityOptions(countries, cities)
      })
    ).subscribe(options => this.citiesOptions.next(options))
  }

  assumeTimezone(countryId: number, city: string): Observable<Object> {
    let url = this.buildUrl('get_timezone_offset')
    return this.get(url, { country: countryId, city: city } )
  }
}
