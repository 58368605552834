import { BaseModel } from 'models/base.model'
import { KioskFormControl } from './kiosk-form-control.model'
import { User } from '../user.model'
import { Moment } from 'moment'

export class KioskForm extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    btnNextLabel: "Далее",
    kioskFormControls: []
  }

  uuid: string
  title: string
  comment: string
  btnNextLabel: string
  state: string
  organizationId: number
  kioskFormControls: KioskFormControl[]
  createdBy: User
  updatedBy: User
  createdAt: Moment
  updatedAt: Moment

  get name(): string {
    return this.title
  }

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
