import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

@Component({
  selector: 'toggle-switch',
  templateUrl: './toggle-switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true
    }
  ]
})

export class ToggleSwitchComponent implements ControlValueAccessor {
  @Input('positiveValue') private positiveValue: any = true
  @Input('positiveLabel') positiveLabel: string = 'Да'
  @Input('negativeValue') private negativeValue: any = false
  @Input('negativeLabel') negativeLabel: string = 'Нет'
  @Input('wrapperClass') private wrapperClass: string = null

  @Output('change') private onChangeEmitter = new EventEmitter<Object>()

  private _value: any = undefined

  // ControlValueAccessor methods

  writeValue(value) {
    this._value = value
  }

  registerOnChange(fn: any) { this.onChangeCallback = fn }

  registerOnTouched(fn: any) { this.onTouchedCallback = fn }

  private onChangeCallback(_) {}

  private onTouchedCallback() {}

  // END ControlValueAccessor methods

  get value(): string {
    return this._value
  }

  @Input('value') set value(v) {
    if (v !== this._value) {
      this._value = v
      this.onChangeCallback(v)
    }
  }

  isSelectedAny(): boolean {
    return this.value !== undefined
  }

  isSelectedPositive(): boolean {
    return this.value === this.positiveValue
  }

  isSelectedNegative(): boolean {
    return this.value === this.negativeValue
  }

  selectPositive() {
    if(this.value === this.positiveValue) {
      return
    }
    this.value = this.positiveValue
    this.onChangeEmitter.emit({ value: this.value })
  }

  selectNegative() {
    if(this.value === this.negativeValue) {
      return
    }
    this.value = this.negativeValue
    this.onChangeEmitter.emit({ value: this.value })
  }

  cssClass(): string {
    let result = []
    if(this.isSelectedNegative()) {
      result.push('negative')
    }
    else if(this.isSelectedPositive()) {
      result.push('positive')
    }
    if(this.wrapperClass) {
      result.push(this.wrapperClass)
    }
    return result.join(' ')
  }
}
