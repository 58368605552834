<div class="modal-header">
  <h4 *ngIf="organizationName" class="modal-title">Внимание</h4>
  <h4 *ngIf="!organizationName" class="modal-title">Организация</h4>
</div>

<div class="modal-body">
  <div *ngIf="organizationName">
    У вас пропал доступ к организации <strong *ngIf="organizationName">{{ organizationName }}</strong>
  </div>

  <loader *ngIf="loading" [visible]="loading"></loader>

  <div class="form-group" *ngIf="!loading && organizationOptions.length > 0">
    <label *ngIf="organizationName" class="control-label">Выберите другую организацию</label>
    <label *ngIf="!organizationName" class="control-label">Выберите организацию</label>
    <single-select 
    [options]="organizationOptions"
    (change)="onChange($event)"
    ></single-select>
  </div>
</div>

<div class="modal-footer" *ngIf="!loading && organizationOptions.length == 0">
  <div class="pull-left buttons">
    <button 
    type="button" 
    class="btn btn-primary" 
    (click)="signOut()"
    >Выйти</button> 
  </div>
</div>