import { cloneDeep } from 'lodash-es'

export interface CustomThemeModelParams {
  mainColor: string
  failureColor: string
  // button colors
  btnColorPrimary: string
  btnColorSecondary: string
  btnColorDisabled: string
  // button text colors
  btnTextColorPrimary: string
  btnTextColorSecondary: string
  btnTextColorDisabled: string
}

export class CustomThemeModel implements CustomThemeModelParams {
  static DEFAULT_ATTRIBUTES: CustomThemeModelParams = {
    mainColor: null,
    failureColor: null,
    btnColorPrimary: null,
    btnColorSecondary: null,
    btnColorDisabled: null,
    btnTextColorPrimary: null,
    btnTextColorSecondary: null,
    btnTextColorDisabled: null
  }

  mainColor: string
  failureColor: string
  btnColorPrimary: string
  btnColorSecondary: string
  btnColorDisabled: string
  btnTextColorPrimary: string
  btnTextColorSecondary: string
  btnTextColorDisabled: string
  
  constructor(attributes: Partial<CustomThemeModelParams> = {}) {
    const defaultAttributes = this.constructor['DEFAULT_ATTRIBUTES'] ? cloneDeep(this.constructor['DEFAULT_ATTRIBUTES']) : {}
    Object.assign(this, defaultAttributes, attributes)
  }
}

