import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild
} from '@angular/core'

import { DropComponent } from 'shared/components/drop.component'
import { cloneDeep } from 'lodash-es'
import { FormOption } from 'core'

@Component({
  selector: 'single-select-search',
  templateUrl: './single-select-search.component.html'
})

export class SingleSelectSearchComponent {
  @ViewChild('drop', { static: true }) private dropElRef: DropComponent
  @Input('options') options: FormOption[] = []
  @Input('disabled') disabled: boolean = false
  @Input('width') width: String = '200px'
  @Output('onChange') private onChangeEmitter: EventEmitter<Object> = new EventEmitter<Object>()

  private selectedOption: FormOption
  selectedValue: any
  isDropdownOpen: boolean = false
  isDropdownUp: boolean = false
  optionsSearchTerm: string = ""
  filteredOptions: Object[]
  showSearch: boolean = false


  constructor() {
    this.selectOption = this.selectOption.bind(this)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.filteredOptions =  this.filterOptions(this.options, "")
      this.updateSelectedOption()
      this.showSearch = this.getFlatOptions().length >= 3
    }
  }

  @Input('value')

  get value(): any {
    return this.selectedValue
  }

  set value(v) {
    if (v !== this.selectedValue) {
      this.selectedValue = v
    }
    this.resetSearch()
  }

  onKeyup(term: string): void {
    this.filteredOptions = this.filterOptions(this.options, term)
  }


  filterOptions(options: Object[], term: string): Object[] {
    let _options = cloneDeep(options)
    let _term = term.toLowerCase()

    return _options.filter(o => {
      if (o["group"]) {
        o["subOptions"] = this.filterOptions(o["subOptions"], _term)
        return o["subOptions"].length > 0
      } else {
        return o["label"].toLowerCase().indexOf(_term) !== -1
      }
    })
  }

  private getFlatOptions(): Object[] {
    return this.getSubtreesOptions(this.options)
  }

  private getSubtreesOptions(options: Object[]): Object[] {
    let result = []
    for(let option of options) {
      result = result.concat(this.getSubtreeOptions(option))
    }
    return result
  }

  private getSubtreeOptions(option: Object, result: Object[] = []): Object[] {
    if(option['value'] !== null && option['value'] !== undefined) {
      result.push(option)
    }
    if(option['subOptions']) {
      for(let subOption of option['subOptions']) {
        this.getSubtreeOptions(subOption, result)
      }
    }
    return result
  }

  selectOption(option: FormOption) {
    if(option['value'] != this.selectedValue) {
      let previousValue = this.selectedValue
      this.selectedOption = option
      this.selectedValue = option['value']
      this.onChangeEmitter.emit({ value: option['value'], previousValue: previousValue })
    }
    this.closeDropdown()
  }

  onToggleClick(event) {
    if(!this.disabled) {
      this.toggleDropdown()
    }
  }

  private updateSelectedOption() {
    this.selectedOption = this.findOption(this.selectedValue)
  }

  private findOption(value: any, options: FormOption[] = this.options): FormOption {
    for(let option of options) {
      if(option['value'] == value && !option['group']) {
        return option
      }
      else if(option['subOptions']) {
        let result = this.findOption(value, option['subOptions'])
        if(result) { return result }
      }
    }
    return null
  }

  private toggleDropdown() {
    if(this.isDropdownOpen) {
      this.closeDropdown()
    } else {
      this.openDropdown()
    }
  }

  private closeDropdown() {
    this.isDropdownOpen = false
  }

  private openDropdown() {
    this.isDropdownOpen = true
  }

  trackByFn(index, item) {
    return item["group"] ? 
      item["label"] : item["value"]
  }

  resetSearch(): void {
    this.filteredOptions = this.filterOptions(this.options, "")
    this.optionsSearchTerm = ""
  }

  caption(): string {
    return this.selectedOption ? this.selectedOption['label'] : "Выберите опцию"
  }
}
