import { Pipe, PipeTransform } from '@angular/core'
import { AuthenticationService } from 'core/auth/authentication.service'

@Pipe({ name: 'privateUploadUrl' })
export class PrivateUploadUrlPipe implements PipeTransform {
  constructor(
    private authentication: AuthenticationService
  ) {}

  transform(value: string): string {
    let authParams = new URLSearchParams()
    let authData = this.authentication.currentAuthData
    let sep = value.indexOf('?') > -1 ? '&' : '?'
    authParams.set('uid', authData.uid)
    authParams.set('client', authData.client)
    authParams.set('access-token', authData.accessToken)

    return `${value}${sep}${authParams.toString()}`
  }
}