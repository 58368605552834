import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BaseModelService } from './base-model.service'
import { Audio } from './audio.model'
import { AudioSerializer } from 'serializers'
import { FormOptionsBuilder } from 'core/forms'
import { Folder } from './folder.model'
import { FolderService } from './folder.service'

@Injectable()
export class AudioService extends BaseModelService<Audio> {
  constructor(private folderService: FolderService) {
    super()

    this.configure({
      basePath:  'audios',
      key:       'audio',
      pluralKey: 'audios',
      serializer: AudioSerializer
    })
  }

  loadAudioOptions(): Observable<Object[]> {
    return new Observable(observer => {
      this.folderService.audiosTree().subscribe(
        folderData => {
          let folder = folderData['folder'] as Folder
          this.shortIndex({ folderSubtreeIds: folder.id }).subscribe(
            audioData => {
              observer.next(
                  FormOptionsBuilder.folderItemOptions(
                      folder.subFolders, audioData['audios']
                  )
              )
            },
            error => { console.error(error) }
          )
        }
      )
    })
  }
}
