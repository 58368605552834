import { BaseModel } from './base.model'
import { ExamSection } from './exam-section.model'
import { Program } from './program.model'

export type DisplayResults = "points"|"percent"

export class Exam extends BaseModel {
  static DEFAULT_ATTRIBUTES: Partial<Exam> = {
    examSections: [],
    onlySectionsGrade: false,
    onlyQuestionsGrade: true,
    timeLimit: 0,
    state: "active",
    showResults: false,
    displayResults: "points"
  }

  id: number
  name: string
  state: string
  comment: string
  folderId: number
  examSections: ExamSection[]
  timeLimit: number
  onlySectionsGrade: boolean
  onlyQuestionsGrade: boolean
  programs: Program[]
  minCorrectAnswers: number
  minCorrectSections: number
  showResults: boolean
  displayResults: DisplayResults
  

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  active(): boolean {
    return this.state == 'active'
  }
}
