import { BaseModel } from './base.model'
import { Program } from './program.model'
import { User } from './user.model'
import { Moment } from 'moment'

export class Video extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  comment: string
  file: File
  folderId: number
  id: number
  programs: Program[]
  name: string
  state: string
  thumbUrl: string
  uploadFileUrl: string
  url: string
  fallbackLessonId: number
  createdBy: User
  updatedBy: User
  createdAt: Moment
  updatedAt: Moment

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }

  filename(): string {
    if(!this.url) {
      return null
    } else {
      return this.url.split('/').pop().split('?')[0]
    }
  }
}
