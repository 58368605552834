import { BaseSerializer } from './base.serializer'
import { Report } from 'models'
import { UserSerializer } from './user.serializer'
import { TimeSerializer } from './attributes'

export class ReportSerializer extends BaseSerializer<Report> {
  static SERIALIZABLE_ATTRIBUTES = [
    "reportType",
    "reportParams",
    "reportOptions"
  ]
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    owner: UserSerializer,
    createdAt: TimeSerializer,
    completedAt: TimeSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Report {
    return new Report(attributes)
  }
}
