import { WebHookResult } from 'models'
import { BaseSerializer } from 'serializers'
import { TimeSerializer } from './attributes'
import { WebHookSerializer } from './web-hook.serializer'



export class WebHookResultSerializer extends BaseSerializer<WebHookResult> {
  static SERIALIZABLE_ATTRIBUTES = []
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {
    'webHook': WebHookSerializer,
    'createdAt': TimeSerializer
  }
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Partial<WebHookResult>): WebHookResult {
    return new WebHookResult(attributes)
  }
}
