import { BaseSerializer } from './base.serializer'
import { Organization } from 'models/organization.model'

export class OrganizationSerializer extends BaseSerializer<Organization> {
  static SERIALIZABLE_ATTRIBUTES = ['name']
  static SERIALIZERS = {}
  static SERIALIZE_RENAME_KEYS = {}

  static DESERIALIZERS = {}
  static DESERIALIZE_RENAME_KEYS = {}

  static initializeObject(attributes: Object): Organization {
    return new Organization(attributes)
  }
}
