import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BaseModelService } from './base-model.service'
import { Session } from './session.model'
import { SessionSerializer } from 'serializers'
import { map } from 'rxjs/operators'

@Injectable()
export class SessionService extends BaseModelService<Session> {
  constructor() {
    super()

    this.configure({
      basePath:  'sessions',
      key:       'session',
      pluralKey: 'sessions',
      serializer: SessionSerializer
    })
  }

  updateAccessorComment(obj: Session|Object): Observable<Object> {
    let url = this.buildUrl(obj['id'], 'accessor_comment')
    return this.patch(url, obj)
  }

  accessorAssign(): Observable<Object> {
    let url = this.buildUrl('accessor_assign')
    return this.patch(url)
  }

  accessorStart(obj: Session|Object): Observable<Object> {
    let url = this.buildUrl(obj['id'], 'accessor_start')
    return this.patch(url)
  }

  assessorComplete(obj: Session|Object): Observable<Object> {
    let url = this.buildUrl(obj['id'], 'accessor_complete')
    return this.patch(url, obj)
  }

  adminComplete(obj: Session|Object): Observable<Object> {
    let url = this.buildUrl(obj['id'], 'admin_complete')
    return this.patch(url, obj)
  }

  cancel(uuid: string): Observable<Session> {
    const url = this.buildUrl(uuid, 'cancel')
    return this.post(url, {}).pipe(
      map(data => data["session"] as Session)
    )
  }
}
