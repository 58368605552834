import { BaseModel } from './base.model'
import { AppLocale } from './app-locale.model';

export class Country extends BaseModel {
  static DEFAULT_ATTRIBUTES = {
    availableLocaleIds: [],
    showInPublicWebApi: true
  }

  id: number
  name: string
  state: string
  licenseAgreement: string
  registrationAgreement: string
  licenseAgreementTitle: string
  registrationAgreementTitle: string
  availableLocales: AppLocale[]
  availableLocaleIds: number[]
  showInPublicWebApi: boolean
  code: string
  
  constructor(attributes?: Object) {
    super(attributes)

    this.availableLocaleIds = this.availableLocales ? 
      this.availableLocales.map(l => l.id) : []
  }

  removed(): boolean {
    return this.state == 'removed'
  }

  disabled(): boolean {
    return this.state == 'disabled'
  }
}
