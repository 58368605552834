<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>

<!-- TEXT content -->
<div *ngIf="text" class="modal-body" [innerText]="text"></div>
<!-- HTML content -->
<div *ngIf="html" class="modal-body" [innerHTML]="html"></div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary pull-left btn_width_108" 
  (click)="confirm()">
    {{ confirmBtnText }}
  </button>

  <button type="button" class="btn btn-default-fr pull-right btn_width_108" 
  (click)="cancel()">
    {{ cancelBtnText }}
  </button>
</div>