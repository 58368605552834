import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { BaseModelService } from './base-model.service'
import { EditorType } from './static-screen.model'

export interface BuildPreviewParams {
  content: string
  extended_styles?: string
  css_libraires?: string[],
  editor: EditorType
}

export interface PreviewResponseJson {
  html: string
}

@Injectable({
  providedIn: "root"
})
export class PreviewBuilderService extends BaseModelService<{html: string}> {

  constructor() {
    super()

    this.configure({
      basePath:  'preview',
      key:       'html',
      serializer: null
    })
  }

  build(params: BuildPreviewParams): Observable<string> {
    const url = this.buildUrl()
    return this.appHttp.post<PreviewResponseJson>(url, params).pipe(map(resp => resp.html))
  }
}