import { BaseModel } from './base.model'
import { ProgramSectionTransitionCondition } from './program-section-transition-condition.model'

export class ProgramSectionTransition extends BaseModel {
  static DEFAULT_ATTRIBUTES = {}

  uuid: string
  name: string
  sectionUuid: string
  position: number
  nextUuid: string
  kind: "ProgramSectionTransition::DefaultTransition"|"ProgramSectionTransition::ConditionalTransition"
  color: string
  conditions: Partial<ProgramSectionTransitionCondition>[]

  _destroy?: boolean
}
