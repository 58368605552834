<div class="modal-header">
  <h4 *ngIf="role" class="modal-title">Внимание</h4>
  <h4 *ngIf="!role" class="modal-title">Роль</h4>
</div>

<div class="modal-body">
  <div *ngIf="role">
    У вас пропал доступ к роли <strong>{{ roleName }}</strong>
  </div>

  <div class="form-group">
    <label *ngIf="role" class="control-label">Выберите другую роль</label>
    <label *ngIf="!role" class="control-label">Выберите роль</label>
    <single-select 
    [options]="roleOptions"
    (change)="onChange($event)"
    ></single-select>
  </div>
</div>