import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { KeyTransformer } from 'util/key-transformer'
import { BaseModelService } from './base-model.service'

@Injectable()
export class AvailableLocalesService extends BaseModelService<Object> {
  constructor() {
    super()

    this.configure({
      basePath:  'available_locales',
      key:       'available_locale',
      pluralKey: 'available_locales',
      serializer: null
    })
  }

  index(includeDefault:boolean=false): Observable<Object> {
    let _params = {
      include_default: includeDefault ? "1" : "0"
    }
    let params = this.buildUrlSearchParams(_params)
    let url = this.buildUrl()
    return this.appHttp.get(url, { params }).pipe(
      map((response) => this.processResponse(response))
    )
  }

  protected processResponse(response: Object): Object {
    return KeyTransformer.camelize(response)
  }
}
