import { BaseSerializer } from './base.serializer'
import { Interactive } from 'models/interactive.model'
import { InteractiveContentItemSerializer } from './interactive-content-item.serializer'
import { InteractiveSkillSerializer } from './interactive-skill.serializer'
import { TimeSerializer } from './attributes'
import { UserSerializer } from './user.serializer'

export class InteractiveSerializer extends BaseSerializer<Interactive> {

  static SERIALIZABLE_ATTRIBUTES = [
    'id', 'comment', 'folderId', 'name', 'contentItems', 'interactiveSkills', 'base',
    'alredyPassed', 'driver_id', 'fallbackVideoId', 'fallbackLessonId'
  ]

  static SERIALIZERS = {
    contentItems: InteractiveContentItemSerializer,
    interactiveSkills: InteractiveSkillSerializer
  }

  static SERIALIZE_RENAME_KEYS = {
    contentItems: 'contentItemsAttributes',
    interactiveSkills: 'interactiveSkillsAttributes'
  }

  static DESERIALIZERS = {
    createdAt: TimeSerializer,
    updatedAt: TimeSerializer,
    createdBy: UserSerializer,
    updatedBy: UserSerializer,
    contentItems: InteractiveContentItemSerializer,
    interactiveSkills: InteractiveSkillSerializer
  }

  static DESERIALIZE_RENAME_KEYS = {
  }

  static initializeObject(attributes: Object): Interactive {
    return new Interactive(attributes)
  }
}
